import { FC, useState, useEffect } from "react";
import { Button } from "common/components/Button/Button";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES, ORDER_STATUS } from "common/utils/consts";
import { getHours } from "common/utils/helpers";
import { SvgPhone } from "assets/icons/SvgPhone";
import { IOrder } from "common/models/order/IOrder";
import { Alert } from "../Alert/Alert";

export const OrderGoing: FC<{
  onPointAt: Date;
  order: IOrder;
}> = ({ order, onPointAt }) => {
  const [timePassed, setTimePassed] = useState<number>(
    (+new Date() - +onPointAt) / 1000
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimePassed((+new Date() - +onPointAt) / 1000);
    }, 800);
    return () => clearInterval(interval);
  }, []);

  const { showModal } = useGlobalModalContext();

  const showOrderCancelationModal = () => {
    showModal(MODAL_TYPES.ORDER_CANCELATION_MODAL, {
      orderId: order.id,
    });
  };

  // Если завершить, то показывать выборку рейтинга для заказа

  const isOrderWaiting = order.status.id === ORDER_STATUS.WAITING;
  const isVehicleWorking = order.status.id === ORDER_STATUS.GOING;
  const isDriverConfirmed = order.confirmed_by_driver_at;
  const isOnPoint = order.on_point_at;
  const vehicleDeliveryTime = order.vehicle_delivery_time;

  return (
    <div>
      <div className="mb-[10px] font-semibold">
        {isVehicleWorking && isOnPoint && "Машина работает на объекте"}
        {isOrderWaiting && "Ожидание начала выполнения работ"}
        {vehicleDeliveryTime && !isOnPoint && (
          <>
            Через{" "}
            <span className="text-primary">{vehicleDeliveryTime} минут</span>{" "}
            автомобиль подъедет на объект
          </>
        )}
      </div>
      <div className="mb-[25px] grid grid-cols-[40px,1fr,45px] gap-[15px]">
        <img
          className="my-auto block"
          src={order.vehicle_category.icon.path}
          alt={"manipulator"}
        />
        <div className="grid grid-cols-2 ">
          <div className="mr-auto flex flex-col justify-around">
            <div className="text-sm text-lightGray">
              {order.vehicle_type.name}
            </div>
            <div className="mr-[10px] w-fit rounded border border-black py-[4px] px-[7px] text-xs font-medium uppercase leading-[12px] text-black">
              {order.vehicle?.vehicle_number}
            </div>
          </div>
          <div className="ml-auto flex flex-col justify-between text-primary">
            <div className="text-sm">
              {order.driver?.last_name} {order.driver?.first_name}
            </div>
            <div className="text-md font-semibold">{`${order.price} руб.`}</div>
          </div>
        </div>
        <a
          href={`tel:${order.driver?.phone}`}
          className="flex h-full w-[45px] items-center justify-center rounded bg-lightWhiteGray transition hover:text-primary focus:text-primary active:text-black"
        >
          <SvgPhone className="h-[25px] w-[25px]" />
        </a>
      </div>
      {isDriverConfirmed ? (
        <Alert className="mb-[25px] w-full text-sm">Водитель подтвердил</Alert>
      ) : (
        <Alert className="mb-[25px] w-full text-sm" mode="error">
          Водитель ещё не подтвердил
        </Alert>
      )}
      {isOnPoint && (
        <>
          <i className="block h-[1px] w-full bg-[#F6F2EC]" />
          <div className="my-[25px] grid gap-[20px] text-center font-semibold">
            <div className="flex justify-center">
              <div className="mr-[10px] text-primary">
                {getHours(timePassed)}
              </div>
              + 1000 руб.
            </div>
            <div className="flex justify-center">
              <div className="mr-[10px] text-primary">
                Общая стоимость заказа:
              </div>
              {`${order.price} руб.`}
            </div>
          </div>
        </>
      )}

      <Button
        onClick={isOnPoint ? undefined : showOrderCancelationModal}
        className="mb-[15px] w-full"
        mode="red"
      >
        {isOnPoint ? "Завершить" : "Отменить"}
      </Button>
    </div>
  );
};
