import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RadioButton } from "common/components/RadioButton/RadioButton";
import {
  addAddress,
  changeAddress,
  changeAddressPosition,
  nextOrderStep,
  removeAddress,
  setVehicleDeliveryDay,
  setVehicleDeliveryTime,
} from "features/ordering/orderingSlice";
import { List } from "common/components/List/List";
import { SvgTrash } from "assets/icons/SvgTrash";
import { Button } from "common/components/Button/Button";
import { Title } from "common/components/Title/Title";
import { useSearchAddressesMutation } from "services/orderApi";
import { CustomSelect } from "common/components/Select/CustomSelect";
import { IOrderAddressesChooseProps } from "common/models/ordering/IOrderAddressesChooseProps";
import { getNoOptionsMessage } from "common/utils/helpers";
import { SvgCaretUp } from "assets/icons/SvgCaretUp";

export const AddressesChoose: FC<IOrderAddressesChooseProps> = ({
  setIsWorkTime,
  isWorkTime,
}) => {
  const currentAddresses = useAppSelector((state) => state.ordering.address);
  const price = useAppSelector((state) => state.ordering.price);
  const vehicleDeliveryDay = useAppSelector(
    (state) => state.ordering.vehicleDeliveryDay
  );
  const vehicleDeliveryTime = useAppSelector(
    (state) => state.ordering.vehicleDeliveryTime
  );
  const dispatch = useAppDispatch();

  const [addressesArr, setAddressesArr] = useState<string[]>(
    currentAddresses.map((address) => address.id) || ["start"]
  );

  useEffect(() => {
    if (vehicleDeliveryTime || vehicleDeliveryDay) {
      setIsWorkTime(true);
    }
    return () => {
      setIsWorkTime(false);
    };
  }, []);

  useEffect(() => {
    if (currentAddresses) {
      setAddressesArr(currentAddresses.map((address) => address.id));
    }
  }, [currentAddresses]);

  const handleAddressesRemove = (id: string) => {
    dispatch(removeAddress(id));
  };

  const handleChangeAddressPosition = (positions: {
    currentIndex: number;
    newPositionIndex: number;
  }) => {
    dispatch(changeAddressPosition(positions));
  };

  const handleNextOrderState = () => {
    dispatch(nextOrderStep());
  };

  return (
    <>
      <AddressInput id="start" index={0} />
      <p className="my-[10px] text-xs text-lightGray">
        В случае если у техники более одного адреса на маршруте, необходимо
        добавить все адреса для расчета конечно стоимости
      </p>
      {addressesArr?.length > 1 && (
        <p className="mb-[12px] block text-sm font-semibold text-gray">
          Промежуточные адреса
        </p>
      )}
      <List className="mb-[10px]">
        {addressesArr?.map(
          (field, index) =>
            index !== 0 && (
              <div
                key={field}
                className={`grid ${
                  currentAddresses.length < 3
                    ? "grid-cols-[1fr,30px]"
                    : "grid-cols-[1fr,15px,15px]"
                } gap-[15px]`}
              >
                <AddressInput id={field} index={index} />
                {currentAddresses.length > 2 && currentAddresses[index] && (
                  <div className="flex h-[50px] flex-col justify-center">
                    {index > 1 && (
                      <button
                        className="py-[13px] outline-none transition   hover:text-primary focus:text-primary active:text-black"
                        onClick={() =>
                          handleChangeAddressPosition({
                            newPositionIndex: index - 1,
                            currentIndex: index,
                          })
                        }
                      >
                        <SvgCaretUp className="h-[5px] w-[10px]" />
                      </button>
                    )}
                    {index !== currentAddresses.length - 1 && (
                      <button
                        className="py-[13px] outline-none transition hover:text-primary focus:text-primary active:text-black"
                        onClick={() =>
                          handleChangeAddressPosition({
                            newPositionIndex: index + 1,
                            currentIndex: index,
                          })
                        }
                      >
                        <SvgCaretUp className="h-[5px] w-[10px] rotate-180" />
                      </button>
                    )}
                  </div>
                )}

                <button
                  className="flex items-center justify-center outline-none transition hover:text-red focus:text-red active:text-black"
                  type="button"
                  onClick={() => {
                    handleAddressesRemove(field);
                  }}
                >
                  <SvgTrash className="w-[20px]" />
                </button>
              </div>
            )
        )}
      </List>

      {currentAddresses.length > 0 && (
        <button
          onClick={() =>
            setAddressesArr((prev) => [...prev, prev.length + 1 + ""])
          }
          className="active ml-auto mb-[10px] block text-md font-semibold text-primary transition hover:text-black focus:text-black active:text-primary"
        >
          + Добавить адрес
        </button>
      )}

      <div className="mb-[25px] grid gap-[15px]">
        <label className="flex items-center justify-between font-bold">
          На ближайшее время
          <RadioButton
            name="time"
            onChange={() => {
              setIsWorkTime(false);
              dispatch(setVehicleDeliveryDay(undefined));
              dispatch(setVehicleDeliveryTime(undefined));
            }}
            checked={!isWorkTime}
          />
        </label>
        <label className="flex items-center justify-between font-bold">
          Время проведения работ
          <RadioButton
            name="time"
            onChange={() => setIsWorkTime(true)}
            checked={isWorkTime}
          />
        </label>
      </div>
      <div className="grid grid-cols-2 items-center gap-[15px] ">
        {price && <Title className="text-xl">{`${price} руб.`}</Title>}
        <Button
          className="w-full"
          onClick={handleNextOrderState}
          disabled={
            !currentAddresses?.length ||
            (isWorkTime ? !vehicleDeliveryDay : false)
          }
        >
          Продолжить
        </Button>
      </div>
    </>
  );
};

const AddressInput: FC<{
  id: string;
  index: number;
}> = ({ id, index }) => {
  const currentAddresses = useAppSelector((state) => state.ordering.address);

  const [search, { data: addresses, isLoading: isAddressesLoading }] =
    useSearchAddressesMutation();

  const [searchText, setSearchText] = useState<string>(
    currentAddresses[index]?.name
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchText?.length >= 5) {
        search({ query: searchText });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText]);

  const handleAddressChange = (
    latitude: number,
    longitude: number,
    name: string,
    id: string
  ) => {
    if (currentAddresses.find((address) => address.id === id)) {
      dispatch(
        changeAddress({
          latitude: latitude,
          longitude: longitude,
          name: name,
          id: id,
        })
      );
    } else {
      dispatch(
        addAddress({
          latitude: latitude,
          longitude: longitude,
          name: name,
          id: id,
        })
      );
    }
  };

  const addressesOptions = addresses?.map((address) => ({
    value: { ...address },
    label: address.name,
  }));

  const currentAddress = currentAddresses?.find((address) => address.id === id);

  return (
    <CustomSelect
      options={addressesOptions}
      label={index === 0 ? "Адрес подачи" : undefined}
      placeholder="Введите адрес..."
      noOptionsMessage={() =>
        getNoOptionsMessage({
          minLength: 5,
          string: searchText || "",
          isLoading: isAddressesLoading,
        })
      }
      onChange={(val) => {
        handleAddressChange(
          val.value.latitude,
          val.value.longitude,
          val.value.name,
          id
        );
        setSearchText(val.value.name);
      }}
      filterOption={() => true}
      onInputChange={(inputStr, action) => {
        if (action.action === "input-change") {
          setSearchText(inputStr);
        }
      }}
      onFocus={() => setSearchText(currentAddress?.name || "")}
      onBlur={() => setSearchText(currentAddress?.name || "")}
      inputValue={searchText}
      value={currentAddress}
    />
  );
};
