import { FC, useState, useEffect } from "react";
import { PhotoInput } from "../Input/PhotoInput";
import { Button } from "../Button/Button";
import {
  useDeleteImageMutation,
  useUploadSignleImageMutation,
} from "../../../services/fileApi";
import { useUploadOrderFilesMutation } from "../../../services/orderApi";
import { useGlobalModalContext } from "../Modal/GlobalModal";
import { FILE_TYPE, MODAL_TYPES } from "../../utils/consts";
import { getErrorMessage } from "../../utils/helpers";
import { IDocument } from "../../models/dictionaries/IDocument";

export const Waybill: FC<{ orderId: number; defaultPhotos: IDocument[] }> = ({
  orderId,
  defaultPhotos,
}) => {
  const [
    uploadImage,
    { isLoading: isImagesUploadLoading, error: imagesError },
  ] = useUploadSignleImageMutation();
  const [
    deleteImage,
    { isLoading: isDeleteLoading, error: imagesDeleteError },
  ] = useDeleteImageMutation();
  const [
    updateOrderFiles,
    { isLoading: isUpdatingLoading, error: updateError },
  ] = useUploadOrderFilesMutation();

  const [photos, setPhotos] = useState<FileList | null>(null);

  const handleImageDelete = async (id: number) => {
    await deleteImage(id);
    const updateResult = await updateOrderFiles({
      order_id: orderId,
      photos: [
        ...defaultPhotos
          .filter((order) => order.id !== id)
          .map((photo) => photo.id),
      ],
    }).unwrap();
    if (updateResult?.id) {
      openSuccessModal();
    }
  };

  const handlePhotoSubmit = async () => {
    if (photos) {
      const uploadedPhotosPromisesArr = [
        ...Array.from(photos).map(async (photo) => {
          const formData = new FormData();
          formData.append("file_type_id", "9");
          formData.append("file", photo);
          return (await uploadImage(formData).unwrap()).id;
        }),
      ];
      Promise.all(uploadedPhotosPromisesArr).then(async (data) => {
        const updateResult = await updateOrderFiles({
          order_id: orderId,
          photos: data,
        }).unwrap();
        if (updateResult?.id) {
          openSuccessModal();
        }
      });
    }
  };

  const { showModal } = useGlobalModalContext();

  const openErrorModal = (message?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Ошибка при обновлении",
      subTitle:
        message ||
        "При обновлении путевого листа возникла ошибка, повторите попытку позже",
    });
  };

  const openSuccessModal = () => {
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: "Путевой лист успешно обновлён",
    });
  };

  useEffect(() => {
    if (updateError || imagesError || imagesDeleteError) {
      openErrorModal(
        getErrorMessage(updateError || imagesError || imagesDeleteError)?.data
          .message
      );
    }
  }, [updateError, imagesError, imagesDeleteError]);

  useEffect(() => {
    setPhotos(null);
  }, [orderId]);

  const orderPathPhotos = defaultPhotos.filter(
    (photo) => photo.type.id === FILE_TYPE.WAYBILL
  );

  return (
    <div className="grid h-full overflow-hidden">
      <PhotoInput
        key={orderId}
        // label="Перетяните фото для загрузки"
        multiple
        disabled={isImagesUploadLoading || isUpdatingLoading || isDeleteLoading}
        onChange={(e) => {
          setPhotos(e.target.files);
        }}
        onDelete={handleImageDelete}
        defaultImages={orderPathPhotos}
      />
      <div>
        <Button
          className="mt-[25px] w-full"
          disabled={
            !photos ||
            isImagesUploadLoading ||
            isUpdatingLoading ||
            isDeleteLoading
          }
          onClick={handlePhotoSubmit}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};
