import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgAppStore: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3604 0.628906C14.4456 3.72183 12.248 5.86756 10.0529 5.73299C9.69118 3.26473 12.2477 0.628906 14.3604 0.628906ZM0.578125 13.2044C0.578125 16.8036 3.42341 23.8709 6.86314 23.8908H6.88562C7.74074 23.8908 8.49467 23.6076 9.16099 23.3573L9.16683 23.3551C9.65548 23.1707 10.117 22.9971 10.482 22.9971C11.2021 22.9971 11.6379 23.1834 12.1431 23.3997L12.1433 23.3998C12.6425 23.6134 13.2085 23.8556 14.0091 23.8556C17.2815 23.8556 19.535 17.7534 19.535 17.4692C19.535 17.0347 19.2063 16.8356 18.8881 16.643L18.8864 16.6419L18.8854 16.6414C18.163 16.2039 16.6613 15.2945 16.6613 12.9316C16.6613 10.9359 17.9881 9.9283 18.7155 9.37587L18.7158 9.37566L19.2249 8.98897L18.8711 8.4539C17.3609 6.17115 14.8488 5.98814 14.0203 5.98814H14.0137C12.9042 5.98814 11.9514 6.36946 11.1856 6.67628L11.1702 6.68243C10.815 6.82398 10.4175 6.98241 10.2474 6.98241C9.95905 6.98241 9.44065 6.79072 8.93942 6.60504C8.22263 6.33884 7.40997 6.03803 6.64891 6.03803C4.96012 6.03803 0.578125 7.32516 0.578125 13.2044Z"
        fill="black"
      />
    </svg>
  );
};
