import { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import {
  getQueryDateParam,
  timeFormatter,
} from "../../../../common/utils/helpers";
import {
  useGetAerialPlatformsQuery,
  useGetCransQuery,
  useGetManipulatorsQuery,
} from "services/admin/adminVehicleApi";
import { ITableVehicle } from "common/models/vehicles/ITableVehicle";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { VEHICLE_CATEGORY } from "common/utils/consts";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const VehiclesTable: FC<{
  vehicleTypeId?: number[];
  categoryId: number;
  searchText?: string;
  dateFrom?: string;
  dateTo?: string;
}> = ({ vehicleTypeId, categoryId, searchText, dateFrom, dateTo }) => {
  const {
    data: crans,
    isLoading: isCarnsLoading,
    isFetching: isCransFetching,
    error: cranError,
  } = useGetCransQuery(
    {
      vehicle_type_id: vehicleTypeId,
      query: searchText,
      date_from: getQueryDateParam(dateFrom),
      date_to: getQueryDateParam(dateTo),
    },
    {
      skip: categoryId !== VEHICLE_CATEGORY.CRAN,
    }
  );
  const {
    data: aerialPlatform,
    isLoading: isAerialPlatformLoading,
    isFetching: isAerialPlatformFetching,
    error: aerialPlatformError,
  } = useGetAerialPlatformsQuery(
    {
      vehicle_type_id: vehicleTypeId,
      query: searchText,
      date_from: getQueryDateParam(dateFrom),
      date_to: getQueryDateParam(dateTo),
    },
    {
      skip: categoryId !== VEHICLE_CATEGORY.AERIAL_PLATFORM,
    }
  );
  const {
    data: manipulators,
    isLoading: isManipulatorsLoading,
    isFetching: isManipulatorsFetching,
    error: manipulatorsError,
  } = useGetManipulatorsQuery(
    {
      vehicle_type_id: vehicleTypeId,
      query: searchText,
      date_from: getQueryDateParam(dateFrom),
      date_to: getQueryDateParam(dateTo),
    },
    {
      skip: categoryId !== VEHICLE_CATEGORY.MANIPULATOR,
    }
  );

  return (
    <QueryLayout
      isLoading={
        isCarnsLoading || isAerialPlatformLoading || isManipulatorsLoading
      }
      error={manipulatorsError || cranError || aerialPlatformError}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {crans &&
        categoryId === VEHICLE_CATEGORY.CRAN &&
        (crans.length ? (
          <Table data={crans} columns={columns} isLoading={isCransFetching} />
        ) : (
          <NotFoundTitle />
        ))}
      {manipulators &&
        categoryId === VEHICLE_CATEGORY.MANIPULATOR &&
        (manipulators.length ? (
          <Table
            data={manipulators}
            columns={columns}
            isLoading={isManipulatorsFetching}
          />
        ) : (
          <NotFoundTitle />
        ))}
      {aerialPlatform &&
        categoryId === VEHICLE_CATEGORY.AERIAL_PLATFORM &&
        (aerialPlatform.length ? (
          <Table
            data={aerialPlatform}
            columns={columns}
            isLoading={isAerialPlatformFetching}
          />
        ) : (
          <NotFoundTitle />
        ))}
    </QueryLayout>
  );
};

const columns: Column<ITableVehicle>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Вид",
    accessor: "category",
    Cell: ({ row }: any) => <span>{row.original.category?.name || "Нет"}</span>,
  },
  {
    Header: "Номер ТС",
    accessor: "vehicle_number",
  },
  {
    Header: "Завершённые заказы",
    accessor: "finished_orders_count",
  },
  {
    Header: "Тариф",
    accessor: "type",
    Cell: ({ row }: any) => <span>{row.original.type?.name || "Нет"}</span>,
  },
  {
    Header: "Сумма, руб.",
    accessor: "orders_sum",
  },

  {
    Header: "Дата добавления",
    accessor: "created_at",
    Cell: ({ row }: any) => (
      <>
        {row.original.created_at &&
          timeFormatter({
            time: new Date(row.original.created_at),
            mode: "dd-MM-yyyy",
          })}
      </>
    ),
  },
  {
    Header: "География",
    accessor: "location_address",
  },
  {
    Header: "Статус",
    accessor: "status",
    Cell: ({ row }: any) => {
      const status = row.values.status;
      switch (status) {
        case "Активен":
          return <span className="max-w-[50px] justify-center px-[5px] flex min-h-[14px] rounded bg-blue text-white w-full">Активен</span>;
        case "В работе":
          return <span className="max-w-[50px] justify-center px-[5px] flex min-h-[14px] rounded bg-green text-white w-full">В работе</span>;
        case "Удалён":
          return <span className="max-w-[50px] justify-center px-[5px] flex min-h-[14px] rounded bg-red text-white w-full">Удалён</span>;
        default:
          return <span className="text-lightGray">Статус не известен</span>;
      }
    },
  },

  {
    Header: "Споры",
    accessor: "disputes_count",
  },
  {
    Header: "Владелец",
    accessor: "company",
    Cell: ({ row }: any) => <span>{row.original.company?.name || "Нет"}</span>,
  },
];
