import { FC, useCallback, useMemo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgArrowInCircle } from "../../../assets/icons/SvgArrowInCircle";
import { Button } from "../Button/Button";
import { VehicleInput } from "../Vehicle/VehicleInput";
import { VehicleListItem } from "../Vehicle/VehicleListItem";
import { RadioButton } from "../RadioButton/RadioButton";
import { useGetVehicleCategoriesQuery } from "../../../services/vehicle/vehiclesApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { DatePicker } from "../DatePicker/DatePicker";
import { IOrderFilterProps } from "common/models/components/order/IOrderFilterProps";
import { ORDER_STATUS } from "common/utils/consts";

export const OrdersFilter: FC<IOrderFilterProps> = ({
  setIsFilterOpen,
  handleFilterReset,
  handlePeriodSet,
  handleFilterVehiclesChange,
  handleTimeAtSet,
  handleTimeToSet,
  stateSelector,
  initialOrderStatuses,
  isWide = false,
}) => {
  const dispatch = useAppDispatch();

  const {
    data: categories,
    isLoading,
    isFetching,
    error,
  } = useGetVehicleCategoriesQuery();
  const filters = useAppSelector((state) => stateSelector(state));

  const periodStatuses = useMemo(
    () => [
      { label: "Все", value: initialOrderStatuses },
      { label: "Здесь и сейчас", value: [ORDER_STATUS.GOING] },
      { label: "Время проведения работ", value: [ORDER_STATUS.WAITING] },
    ],
    [initialOrderStatuses]
  );

  useEffect(() => {
    if (!filters.period) {
      periodSet(initialOrderStatuses);
    }
  }, []);

  const filterVehiclesChange = useCallback((category: number) => {
    dispatch(handleFilterVehiclesChange(category));
  }, []);

  const filterReset = useCallback(() => {
    dispatch(handleFilterReset());
  }, []);

  const handleFilterClose = useCallback(() => {
    setIsFilterOpen(false);
  }, []);

  const periodSet = useCallback((period: Undefinable<number[]>) => {
    dispatch(handlePeriodSet(period));
  }, []);

  const setTimeAt = useCallback((period: Date | [Date | null, Date | null]) => {
    if (!Array.isArray(period)) {
      dispatch(handleTimeAtSet(period));
    }
  }, []);

  const setTimeTo = useCallback((period: Date | [Date | null, Date | null]) => {
    if (!Array.isArray(period)) {
      dispatch(handleTimeToSet(period));
    }
  }, []);

  return (
    <div
      className={`pointer-events-auto absolute top-[10px] right-[5px] z-20 h-full overflow-hidden overflow-y-scroll rounded p-[5px]`}
    >
      <div
        className={`h-fit overflow-y-scroll rounded bg-white p-[15px] shadow-lg ${
          isWide ? "top-[65px] w-[720px]" : "top-[15px] w-[340px]"
        }`}
      >
        <button
          className="absolute top-[10px] right-[10px] z-10 h-[32px] w-[32px] text-black
        outline-none transition hover:text-primary focus:text-primary active:text-black"
          onClick={handleFilterClose}
        >
          <SvgArrowInCircle className="text-inherit" />
        </button>
        <h3 className="mb-[25px] text-center text-lg font-semibold">Фильтры</h3>
        <div className={`${isWide && "grid grid-cols-2 gap-[15px]"}`}>
          <div>
            <div className="mb-[25px]">
              <h4 className="mb-[15px] text-center text-base font-semibold">
                Тип Техники
              </h4>
              <QueryLayout
                isLoading={isLoading}
                isFetching={isFetching}
                error={error}
              >
                {categories && (
                  <ul className="grid gap-[15px]">
                    {categories.map((category) => (
                      <VehicleInput
                        type="checkbox"
                        key={category.id}
                        title={category.name}
                        checked={filters.vehicles.includes(category.id)}
                        onChange={() => filterVehiclesChange(category.id)}
                        innerelement={
                          <VehicleListItem
                            name={category.name}
                            img={category.icon.path}
                            mode="short"
                          />
                        }
                      />
                    ))}
                  </ul>
                )}
              </QueryLayout>
            </div>
          </div>
          <div>
            <div className="mb-[25px]">
              <h4 className="mb-[15px] text-sm font-semibold">Период</h4>
              <div className="grid gap-[15px]">
                {periodStatuses.map((period) => (
                  <label
                    key={period.label}
                    className={`flex cursor-pointer items-center justify-between text-left text-sm font-semibold transition hover:text-primary 
                        ${
                          filters.period?.join() === period.value?.join()
                            ? "text-primary"
                            : "text-lightGray"
                        }`}
                  >
                    {period.label}
                    <RadioButton
                      className="appearance-none"
                      name="period"
                      value={period.label}
                      type="radio"
                      checked={filters.period?.join() === period.value?.join()}
                      onChange={() => periodSet(period.value)}
                    />
                  </label>
                ))}
              </div>
            </div>
            <div className="mb-[25px]">
              <h4 className="mb-[15px] text-sm font-semibold">Период</h4>
              <div className="grid gap-[15px]">
                <label>
                  <span
                    className={`mb-[12px] block text-sm font-semibold text-gray`}
                  >
                    Время от
                  </span>
                  <DatePicker
                    selectsRange={undefined}
                    placeholderText="Время от"
                    selected={
                      filters.timeAt ? new Date(filters.timeAt) : undefined
                    }
                    onChange={setTimeAt}
                  />
                </label>
                <label>
                  <span
                    className={`mb-[12px] block text-sm font-semibold text-gray`}
                  >
                    Время до
                  </span>
                  <DatePicker
                    selectsRange={undefined}
                    placeholderText="Время до"
                    selected={
                      filters.timeTo ? new Date(filters.timeTo) : undefined
                    }
                    onChange={setTimeTo}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-[15px]">
          <Button
            className="text-base"
            mode="darkBorder"
            onClick={filterReset}
          >
            Сбросить
          </Button>
          <Button className="text-base" onClick={handleFilterClose}>
            Продолжить
          </Button>
        </div>
      </div>
    </div>
  );
};
