import { FC, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Container } from "../../../common/components/Container/Container";
import { Title } from "../../../common/components/Title/Title";
import { setActiveBankCardId } from "./bankCardsSlice";
import { BankCardsList } from "common/components/BankCard/BankCardsList";
import { InformationBlock } from "common/components/InformationBlock/InformationBlock";
import { Button } from "common/components/Button/Button";
import {
  useAddBankCardMutation,
  useDeleteBankCardMutation,
  useUpdateUserMutation,
} from "services/userApi";
import { SvgPlus } from "assets/icons/SvgPlus";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { getErrorMessage } from "common/utils/helpers";
import Pusher from "pusher-js";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const BankCardsPage: FC = withErrorBoundary(() => {
  const [isAddingCard, setIsAddingCard] = useState<boolean>(false);

  const activeCardId = useAppSelector(
    (state) => state.individual.bankCards.activeCardlId
  );
  const defaultCardId = useAppSelector(
    (state) => state.user.current?.default_bank_card?.id
  );
  const userId = useAppSelector((state) => state.user.current?.id);

  const [updateUser, { isLoading: isUserUpdateLoading }] =
    useUpdateUserMutation();

  const [
    deleteCard,
    { isLoading: isDeleteBankCardLoading, error: deleteError },
  ] = useDeleteBankCardMutation();

  const [
    createBankCard,
    {
      isLoading: isCreateBankCardLoading,
      reset: resetCreating,
      data: createdCardData,
      error: createError,
      isSuccess: isCreateBankCardSuccess,
    },
  ] = useAddBankCardMutation();

  const dispatch = useAppDispatch();

  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    if (deleteError) {
      openErrorModal(getErrorMessage(deleteError)?.data.message);
    }
  }, [deleteError]);

  useEffect(() => {
    if (createError) {
      openErrorModal(getErrorMessage(createError)?.data.message);
    }
  }, [createError]);

  const showConfirmationModal = (cardId: number) => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      subTitle: "Вы уверены что хотите удалить эту карту?",
      confirmText: "Компания успешно удалена",
      onConfirm: async () => {
        await deleteCard(cardId);
        handleSetActiveBankCardId(undefined);
      },
    });
  };

  const openErrorModal = (message?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Не удалось удалить карту",
      subTitle:
        message ||
        "При удалении карты произошла ошибка, повторите попытку позже",
    });
  };

  const handleSetActiveBankCardId = (id?: number) => {
    dispatch(setActiveBankCardId(id));
    setIsAddingCard(false);
  };

  const handleSetIsAddingCard = () => {
    setIsAddingCard(true);
    dispatch(setActiveBankCardId(undefined));
  };

  useEffect(() => {
    if (defaultCardId) {
      handleSetActiveBankCardId(defaultCardId);
    }
  }, [defaultCardId]);

  const isDefaultCard = activeCardId === (defaultCardId || -1);

  return (
    <Container>
      <div className="grid h-full grid-rows-[40px,1fr] items-baseline gap-[10px] overflow-hidden">
        <div className="flex items-center justify-between">
          <Title>Карты</Title>
          <button
            className="text-primary outline-none transition hover:text-black focus:text-black active:text-primary"
            onClick={handleSetIsAddingCard}
          >
            <SvgPlus className="h-[20px] w-[20px]" />
          </button>
        </div>

        <div className="h-full overflow-y-scroll">
          {userId && (
            <BankCardsList
              userId={userId}
              activeCardId={activeCardId}
              handleSetActiveCardId={handleSetActiveBankCardId}
            />
          )}
        </div>
      </div>
      <div className="max-w-[800px]">
        {activeCardId ? (
          <div>
            <Title className="mb-[25px] flex items-center">Действия</Title>
            <p className="mb-[15px] text-sm text-lightGray">
              Выбирая каждое действие вы подтверждаете и соглашаетесь с
              последствиями о безвозвратном изменении
            </p>
            <div className="grid h-[45px] w-full grid-cols-3 gap-[15px]">
              <Button
                onClick={() => handleSetActiveBankCardId(activeCardId)}
                mode="darkBorder"
              >
                Назад
              </Button>
              <Button
                disabled={isDeleteBankCardLoading}
                loader={isDeleteBankCardLoading}
                mode="red"
                onClick={() => showConfirmationModal(activeCardId)}
              >
                Удалить
              </Button>
              <Button
                disabled={isUserUpdateLoading}
                onClick={() => {
                  if (userId) {
                    if (isDefaultCard) {
                      updateUser({
                        default_bank_card_id: null,
                        user_id: userId,
                      });
                    } else {
                      updateUser({
                        default_bank_card_id: activeCardId,
                        user_id: userId,
                      });
                    }
                  }
                }}
              >
                {isDefaultCard
                  ? "Убрать карту по умолчанию"
                  : "Сделать картой по умолчанию"}
              </Button>
            </div>
          </div>
        ) : isAddingCard ? (
          isCreateBankCardSuccess && createdCardData ? (
            <div className="grid h-fit gap-[15px]">
              <p className="text-sm text-lightGray">
                Для того чтобы добавить новую карту, вам необходимо подтвердить
                что это ваша карта. Для этого нужно перейти по ссылке и произвести
                маленькую оплату.
              </p>
              <a
                href={createdCardData.redirect_link}
                target="_blank"
                onClick={resetCreating}
                rel="noreferrer"
                className="w-full cursor-pointer rounded border tracking-wider border-black font-semibold max-w-[492px]  bg-black px-[23px] py-[10px] text-center text-base text-white outline-none transition hover:bg-white hover:bg-blackHover focus:bg-blackHover active:bg-black active:text-white"
              >
                Перейти
              </a>
            </div>
          ) : (
            <Button
              disabled={isCreateBankCardLoading}
              loader={isCreateBankCardLoading}
              onClick={() => createBankCard()}
              className="h-fit"
            >
              Добавить новую карту
            </Button>
          )
        ) : (
          <InformationBlock />
        )}
      </div>
    </Container>
  );
});
