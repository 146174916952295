import { FC } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetOrdering } from "features/ordering/orderingSlice";
import { Title } from "common/components/Title/Title";
import { Button } from "common/components/Button/Button";
import { SvgSuccess } from "assets/icons/SvgSuccess";

export const OrderCreated: FC<{
  onOrderShow?: (id: number) => void;
  createdOrderId: number;
}> = ({ onOrderShow, createdOrderId }) => {
  const dispatch = useAppDispatch();

  const orderingMode = useAppSelector((state) => state.ordering.orderingMode);

  const handleResetOrdering = () => {
    dispatch(resetOrdering());
  };

  return (
    <div className="grid grid-rows-[1fr,100px]">
      <div className="flex h-[400px] flex-col items-center justify-center text-primary">
        <SvgSuccess className="mb-[15px] h-[50px] w-[50px]" />
        <Title className="text-center">
          {orderingMode === "manager" ? (
            <>
              Ваш заяка принята
              <br />
              Ожидайте с вами свяжется менеджер
            </>
          ) : (
            "Заказ успешно создан!"
          )}
        </Title>
      </div>
      <div className="grid gap-[15px]">
        <Button onClick={() => onOrderShow && onOrderShow(createdOrderId)}>
          Открыть созданный заказ
        </Button>
        <Button onClick={handleResetOrdering}>Сделать новый заказ</Button>
      </div>
    </div>
  );
};
