import { DetailedHTMLProps, FC, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SvgLogo } from "../../../assets/logo/SvgLogo";
import { ISidebarMenuItem } from "../../../common/models/commonModels/ISidebarMenuItem";

export interface IMenuDropdownProps
  extends DetailedHTMLProps<React.HTMLProps<HTMLDivElement>, HTMLDivElement>,
    React.AriaAttributes {
  label?: string;
  open?: boolean;
}

const MenuDropdown: FC<IMenuDropdownProps> = ({ label, open, ...props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!open);

  return (
    <div {...props} className={`w-full overflow-hidden py-[2px] pl-[15px]`}>
      <button
        className="text-left text-sm font-medium text-gray outline-none transition hover:text-primary focus:text-primary active:text-gray"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {label}
      </button>
      {isOpen && <ul className='list-["-"]'>{props.children}</ul>}
    </div>
  );
};

function renderSidebarMenu(
  menu: ISidebarMenuItem[],
  path: string = "",
  breadCrumbs: string = "",
  currentLocation: string = ""
) {
  const finalArr: JSX.Element[] & JSX.Element[][] = [];
  for (let i = 0; i < menu.length; i++) {
    const currentMenuItem = menu[i];
    const currentPath = `${path}${currentMenuItem.value}`;
    const currentBreadCrumbs =
      breadCrumbs !== ""
        ? breadCrumbs + " / " + currentMenuItem.label
        : currentMenuItem.label;

    const isOpen = currentLocation.includes(currentMenuItem.value);

    if (currentMenuItem.subMenu) {
      const subMenuArr = (
        <MenuDropdown
          key={currentPath}
          label={currentMenuItem.label}
          open={isOpen}
        >
          {renderSidebarMenu(
            currentMenuItem.subMenu,
            currentPath,
            currentBreadCrumbs,
            currentLocation
          )}
        </MenuDropdown>
      );
      finalArr.push(subMenuArr);
    } else {
      if (path === "") {
        finalArr.push(
          <NavLink
            key={currentPath}
            to={currentPath}
            state={currentBreadCrumbs}
            className={({ isActive }) =>
              `flex w-full flex-col items-start justify-end py-[8px] px-[15px] outline-none
                  ${
                    isActive ? "text-primary" : "text-gray"
                  } transition hover:text-primary focus:text-primary active:text-gray`
            }
          >
            <span className="text-left text-sm font-medium">
              {currentMenuItem.label}
            </span>
          </NavLink>
        );
      } else {
        finalArr.push(
          <li key={currentPath} className="ml-[10px]">
            <NavLink
              to={currentPath}
              state={currentBreadCrumbs}
              className={({ isActive }) =>
                `flex w-full flex-col items-start justify-end py-[8px] px-[10px] outline-none
                  ${
                    isActive ? "text-primary" : "text-gray"
                  } transition hover:text-primary focus:text-primary active:text-gray`
              }
            >
              <span className="text-left text-sm font-medium">
                {currentMenuItem.label}
              </span>
            </NavLink>
          </li>
        );
      }
    }
  }
  return finalArr;
}

export const AdministratorSidebar: FC = () => {
  const location = useLocation();

  return (
    <aside className="z-10 flex h-[100vh] w-full max-w-[185px] flex-col items-center overflow-y-auto bg-lightWhiteGray">
      <NavLink
        to="/"
        className="flex w-full items-center justify-center pt-[15px] pb-[18px] text-primary hover:text-black focus:text-black active:text-primary"
      >
        <SvgLogo className="h-[34px] w-[34px] transition " />
      </NavLink>
      <nav className="w-full">
        {renderSidebarMenu(
          sidebarMenuNav,
          undefined,
          undefined,
          location.pathname
        )}
      </nav>
    </aside>
  );
};

const sidebarMenuNav = [
  {
    label: "Заказы",
    value: "/orders",
    subMenu: [
      { label: "Завершённые", value: "/completed" },
      { label: "Отменённые", value: "/canceled" },
      { label: "Визор заказов", value: "/visor" },
    ],
  },
  {
    label: "Пользователи",
    value: "/users",
    subMenu: [
      {
        label: "Заказчики",
        value: "/customers",
        subMenu: [
          { label: "Физические лица", value: "/individual" },
          { label: "Компании", value: "/companies" },
        ],
      },
      {
        label: "Исполнители",
        value: "/performers",
        subMenu: [
          { label: "Компании", value: "/companies" },
          { label: "Сам на себя", value: "/self-employeed" },
          { label: "Менеджеры", value: "/managers" },
          { label: "Водители", value: "/drivers" },
        ],
      },
    ],
  },
  {
    label: "Техника",
    value: "/vehicles",
    subMenu: [
      { label: "Манипуляторы", value: "/manipulators" },
      { label: "Краны", value: "/crans" },
      { label: "Автовышки", value: "/aerial-platforms" },
    ],
  },
  {
    label: "Финансы",
    value: "/finance",
    subMenu: [
      { label: "Выручка", value: "/revenue" },
      { label: "Расходы", value: "/expenses" },
      { label: "Прибыль", value: "/profit" },
      { label: "Нам должны", value: "/credit" },
      { label: "Мы должны", value: "/payouts" },
    ],
  },
  {
    label: "Работы админов",
    value: "/admin-work",
    subMenu: [
      { label: "Бухгалтерия", value: "/accounting" },
      { label: "Техподдержка", value: "/tech-support" },
      { label: "Помощь в подоре", value: "/selection-support" },
      { label: "Споры", value: "/disputes" },
    ],
  },
  {
    label: "Посещаемость",
    value: "/attendance",
    subMenu: [
      { label: "Веб", value: "/web" },
      { label: "Приложение", value: "/app" },
    ],
  },
  {
    label: "Справочник",
    value: "/directory",
    subMenu: [
      {
        label: "Техника",
        value: "/vehicles",
        subMenu: [
          {
            label: "Вся техника",
            value: "/all",
            subMenu: [
              {
                label: "Манипуляторы",
                value: "/manipulators",
                subMenu: [
                  { label: "Модели КМУ", value: "/kmu-models" },
                  { label: "Доп обрудование", value: "/options" },
                ],
              },
              {
                label: "Краны",
                value: "/crans",
                subMenu: [
                  { label: "Модели КМУ", value: "/kmu-models" },
                  { label: "Доп обрудование", value: "/options" },
                ],
              },
              {
                label: "Вышки",
                value: "/aerial-platforms",
                subMenu: [
                  { label: "Модели КМУ", value: "/kmu-models" },
                  { label: "Доп обрудование", value: "/options" },
                ],
              },
            ],
          },
          { label: "Марки КМУ", value: "/kmu-marks" },
          { label: "Марки ТС", value: "/ts-marks" },
          { label: "Модели ТС", value: "/ts-models" },
        ],
      },
      {
        label: "Тарифы",
        value: "/tariffs",
      },
    ],
  },
] as ISidebarMenuItem[];
