import { ITransaction } from "../common/models/transactions/ITransaction";
import { api } from "./api";

const transactionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTransactions: build.query<ITransaction[], { userId?: number }>({
      query({ userId }) {
        return {
          url: `/transactions`,
          params: {
            user_id: userId,
          },
        };
      },
      providesTags: () => [{ type: "Order", id: "LIST" }],
      transformResponse: (result: { transactions: ITransaction[] }) =>
        result.transactions,
    }),
    getTransactionById: build.query<ITransaction, number>({
      query(id) {
        return {
          url: `/transactions/${id}`,
        };
      },
      transformResponse: (result: { transaction: ITransaction }) =>
        result.transaction,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllTransactionsQuery, useGetTransactionByIdQuery } =
  transactionApi;
