import { FC, useCallback } from "react";
import { SvgArrowInCircle } from "../../../assets/icons/SvgArrowInCircle";
import { Button } from "../Button/Button";
import { VehicleInput } from "../Vehicle/VehicleInput";
import { VehicleListItem } from "../Vehicle/VehicleListItem";
import { useGetVehicleTypesQuery } from "../../../services/vehicle/vehicleTypesApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { IAdminVehicleFilter } from "common/models/admin/IAdminVehicleFilter";

export const AdminVehicleFilter: FC<IAdminVehicleFilter> = ({
  setIsFilterOpen,
  handleFilterReset,
  handleFilterVehicleTypeChange,
  vehicleCategory,
  vehicleTypeId,
}) => {
  const {
    data: types,
    isLoading: isTypesLoading,
    isFetching: isTypesFetching,
    error: typesError,
  } = useGetVehicleTypesQuery([vehicleCategory || -1], {
    skip: !vehicleCategory,
  });

  const handleFilterClose = useCallback(() => {
    setIsFilterOpen(false);
  }, []);

  return (
    <div
      className={`pointer-events-auto absolute top-[160px] right-[15px] z-20 h-fit overflow-hidden overflow-y-scroll rounded p-[5px]`}
    >
      <div
        className={`top-[65px] h-fit min-w-[350px] overflow-y-scroll rounded bg-white p-[15px] shadow-lg
        `}
      >
        <button
          className="absolute top-[10px] right-[10px] z-10 h-[32px] w-[32px] text-black
        outline-none transition hover:text-primary focus:text-primary active:text-black"
          onClick={handleFilterClose}
        >
          <SvgArrowInCircle className="text-inherit" />
        </button>
        <h3 className="mb-[25px] text-center text-lg font-semibold">Фильтры</h3>
        <div className="grid gap-[15px]">
          <div>
            <div className="mb-[25px]">
              <h4 className="mb-[15px] text-center text-base font-semibold">
                Тариф
              </h4>
              <QueryLayout
                isLoading={isTypesLoading}
                isFetching={isTypesFetching}
                error={typesError}
              >
                {types && vehicleTypeId ? (
                  <ul className="grid gap-[15px]">
                    {types.map((type) => (
                      <VehicleInput
                        type="checkbox"
                        key={type.id}
                        title={type.name}
                        checked={vehicleTypeId.includes(type.id)}
                        onChange={() => handleFilterVehicleTypeChange(type.id)}
                        innerelement={
                          <VehicleListItem
                            name={type.name}
                            mode="short"
                            img={type.category.icon.path}
                          />
                        }
                      />
                    ))}
                  </ul>
                ) : (
                  <div className="text-center">Выберите тип техники</div>
                )}
              </QueryLayout>
            </div>
          </div>
        </div>
        <div className="grid gap-[15px]">
          <Button
            className="text-base"
            mode="darkBorder"
            onClick={handleFilterReset}
          >
            Сбросить
          </Button>
          <Button className="text-base" onClick={handleFilterClose}>
            Продолжить
          </Button>
        </div>
      </div>
    </div>
  );
};
