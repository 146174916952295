import { FC, useState } from "react";
import { SvgCaretUp } from "../../../assets/icons/SvgCaretUp";
import { Button } from "../../../common/components/Button/Button";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { CustomInput } from "../../../common/components/Input/CustomInput";
import { EmployeeListItem } from "../../../common/components/Employee/EmployeeListItem";
import { List } from "../../../common/components/List/List";
import { Title } from "../../../common/components/Title/Title";
import { IVehicleCurrentDriversProps } from "common/models/components/vehicle/IVehicleCurrentDriversProps";

export const VehicleCurrentDrivers: FC<IVehicleCurrentDriversProps> = ({
  drivers,
  setEditDrivers,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  return (
    <>
      <ShadowContainer className="mb-[10px] overflow-hidden">
        <button
          className="flex w-full items-center justify-between"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          <Title>Водители</Title>
          <SvgCaretUp className="h-[18px] w-[13px] rotate-180" />
        </button>
        {isDropdownOpen && (
          <div className="mt-[25px]">
            <List className="mb-[15px] block p-[1px]">
              {drivers.map((driver) => (
                <li key={driver.id}>
                  <CustomInput
                    type="checkbox"
                    readOnly
                    tabIndex={-1}
                    className="cursor-default py-[10px]"
                    checked
                    innerelement={
                      <EmployeeListItem
                        img={driver && driver.avatar?.path}
                        name={
                          driver.last_name
                            ? `${driver.last_name} ${driver.first_name}`
                            : "Пользователь не подтвердивший учётку"
                        }
                        rating={driver.rate}
                        role="Водитель"
                      />
                    }
                  />
                </li>
              ))}
            </List>
            <div className="grid grid-cols-2 gap-[15px]">
              <Button mode="darkBorder" onClick={setEditDrivers}>
                Изменить список
              </Button>
            </div>
          </div>
        )}
      </ShadowContainer>
    </>
  );
};
