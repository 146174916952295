import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { GuestHeader } from "../../features/guest/header/GuestHeader";
import { OrderingPage } from "../../features/ordering/OrderingPage";
import { AboutServicePage } from "pages/faq/aboutService/AboutServicePage";
import { FaqPage } from "pages/faq/faq/FaqPage";
import { UseRulesPage } from "pages/faq/useRules/UseRulesPage";
import { PoliticsPage } from "pages/faq/politics/PoliticsPage";
import { APPLICATIONS } from "common/utils/consts";
import { Map } from "common/components/Map/Map";

export const GuestPage: FC = () => {
  return (
    <div className="h-full w-full">
      <GuestHeader />
      <main className="relative h-full min-h-[calc(100vh-65px)]">
        <Routes>
          <Route path="" element={<OrderingPage />} />
          <Route path="/about" element={<AboutServicePage />} />
          <Route
            path="/faq"
            element={<FaqPage applicationId={APPLICATIONS.CUSTOMER} />}
          />
          <Route
            path="/use-rules"
            element={<UseRulesPage applicationId={APPLICATIONS.CUSTOMER} />}
          />
          <Route
            path="/privacy-policy"
            element={<PoliticsPage applicationId={APPLICATIONS.CUSTOMER} />}
          />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
        <Map />
      </main>
    </div>
  );
};
