import { FC } from "react";
import { SubTitle } from "common/components/Title/SubTitle";
import { SvgHeadset } from "assets/icons/SvgHeadset";
import { Button } from "common/components/Button/Button";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { useConfirmOrderMutation } from "services/orderApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";

export const ManagerSelection: FC<{
  vehicleTypeName: string;
  price: number;
  orderId: number;
  vehicleCategoryIconPath: string;
}> = ({ vehicleTypeName, price, vehicleCategoryIconPath, orderId }) => {
  const [confirmOrder, { isLoading, error }] = useConfirmOrderMutation();

  const { showModal } = useGlobalModalContext();

  const showOrderCancelationModal = () => {
    showModal(MODAL_TYPES.ORDER_CANCELATION_MODAL, { orderId });
  };

  return (
    <div>
      <SubTitle className="mb-[10px] text-center">
        Менеджер подобрал для вас тип техники
      </SubTitle>
      <QueryLayout isLoading={isLoading} error={error}>
        <div className="mb-[25px] grid min-h-[50px] grid-cols-[1fr,56px] gap-[10px]">
          <div className="flex items-center rounded border border-lightWhiteGray px-[10px] py-[15px]">
            <img
              className="mr-[15px] h-[22px] w-[25px]"
              src={vehicleCategoryIconPath}
              alt="Manipulator"
            />
            <div className="mr-[15px] text-base font-semibold text-gray">
              {vehicleTypeName}
            </div>
            <div className="ml-auto text-md font-medium">{`${price} руб.`}</div>
          </div>
          <a
            href="tel:89117643445"
            className="flex items-center rounded bg-lightWhiteGray transition hover:text-primary focus:text-primary active:text-black"
          >
            <SvgHeadset className="mx-auto h-[25px] w-[25px]" />
          </a>
        </div>
      </QueryLayout>
      <div className="grid grid-cols-2 gap-[10px]">
        <Button disabled={isLoading} loader={isLoading} onClick={() => confirmOrder(orderId)}>
          Подтвердить
        </Button>
        <Button onClick={showOrderCancelationModal} mode="red">
          Отменить
        </Button>
      </div>
    </div>
  );
};
