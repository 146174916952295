import { INavLinkItemProps } from "common/models/components/ui/INavLinkItemProps";
import { FC, memo } from "react";
import { NavLink } from "react-router-dom";

export const SidebarLinkItem: FC<INavLinkItemProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        `flex w-full flex-col items-center justify-end ${
          isActive ? "text-primary" : "text-gray"
        } ${userClasses} py-[12px] outline-none transition hover:text-primary focus:text-primary active:text-gray`
      }
    >
      {props.icon}
      <span className="mt-[4px] text-center text-sm font-medium">
        {props.label}
      </span>
    </NavLink>
  );
});
