import { FC, memo } from "react";
import manipulator from "assets/images/manipulatorIcon.png";
import { ILinkProps } from "common/models/components/ui/ILinkProps";

export const PhoneButton: FC<ILinkProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <a
      {...props}
      href="tel:89117345448"
      className={`flex w-fit rounded border border-lightGray bg-lightWhiteGray p-[25px] outline-none transition hover:border-primary focus:border-green active:border-lightGray ${userClasses}`}
    >
      <div className="mr-[25px] flex flex-col">
        <span className="font-bold">9 (892) 23-23-423</span>
        <span className="text-sm font-medium text-lightGray">
          Заказать машину по телефон
        </span>
      </div>
      <div>
        <img
          className="h-[36px] w-[42px]"
          src={manipulator}
          alt="Manipulator"
        />
      </div>
    </a>
  );
});
