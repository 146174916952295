import { FC, memo, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { VehicleCharacteristics } from "../../../common/components/Vehicle/VehicleCharacteristics";
import { VehicleFormValues } from "../../../common/models/forms/VehicleFormValues";
import {
  useDeleteVehicleMutation,
  useGetAllVehiclesQuery,
  useGetVehicleByIdQuery,
  useUpdateVehicleMutation,
  useVerifyVehicleMutation,
} from "../../../services/vehicle/vehiclesApi";
import { setAvtiveVehicleId, setSearchText } from "./vehicleModerationSlice";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { getErrorMessage } from "common/utils/helpers";
import { VehicleList } from "common/components/Vehicle/VehicleList";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";
import { Input } from "common/components/Input/Input";
import { useUploadSignleImageMutation } from "services/fileApi";
import { IVehicleInputs } from "common/models/api/IVehicleInputs";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const VehicleModerationPage: FC = withErrorBoundary(
  memo(() => {
    const activeVehicleId = useAppSelector(
      (state) => state.platformManager.vehicleModeration.activeVehicleId
    );
    const currentCompanyId = useAppSelector(
      (state) => state.user.current?.account.company?.id
    );
    const searchText = useAppSelector(
      (state) => state.platformManager.vehicleModeration.searchText
    );

    const {
      data: vehicle,
      isLoading: isVehicleLoading,
      isFetching: isVehicleFetching,
      error: vehicleError,
    } = useGetVehicleByIdQuery(
      { vehicleId: activeVehicleId || -1 },
      { skip: !activeVehicleId }
    );

    const {
      data: vehicles,
      isLoading: isVehiclesLoading,
      isFetching: isVehiclesFetching,
      error: vehiclesError,
      refetch,
    } = useGetAllVehiclesQuery(
      {
        query: searchText.trim().length >= 3 ? searchText : undefined,
        isVerified: false,
      },
      {
        skip: !currentCompanyId,
      }
    );

    const [
      uploadSts,
      {
        error: uploadingImageError,
        isLoading: isStsImageUploadLoading,
        reset: resetStsUpload,
      },
    ] = useUploadSignleImageMutation();

    const [
      verifyVehicle,
      { error: verifyVehicleError, isLoading: isVerifyVehicleLoading },
    ] = useVerifyVehicleMutation();

    const [
      updateVehicle,
      { isLoading: isUpdatingLoading, error: updateError, reset: resetUpdate },
    ] = useUpdateVehicleMutation();

    const [
      deleteVehicle,
      { error: deleteVehicleError, isLoading: isDeleteVehicleLoading },
    ] = useDeleteVehicleMutation();

    const methods = useForm<VehicleFormValues>({
      mode: "all",
    });

    const {
      handleSubmit,
      formState: { errors, isDirty },
    } = methods;

    const dispatch = useAppDispatch();

    const { showModal } = useGlobalModalContext();

    const showErrorModal = (title: string, message?: string) => {
      showModal(MODAL_TYPES.ERROR_MODAL, {
        title: title,
        subTitle:
          message ||
          "При обновлении техники возникла ошибка, повторите попытку позже",
      });
    };

    const showConfirmationModal = () => {
      if (vehicle?.id && vehicle?.company.id) {
        showModal(MODAL_TYPES.CONFIRM_MODAL, {
          title: "Удаление техники",
          subTitle: "Вы уверены чот хотите удалить данный транспорт?",
          onConfirm: () =>
            deleteVehicle({
              vehicleId: vehicle.id,
              companyId: vehicle?.company.id,
            }),
        });
      }
    };

    useEffect(() => {
      if (deleteVehicleError) {
        showErrorModal(
          "Ошибка при удалении транспорта",
          getErrorMessage(deleteVehicleError)?.data.message
        );
      }
      if (verifyVehicleError) {
        showErrorModal(
          "Ошибка при одобрении техники",
          getErrorMessage(verifyVehicleError)?.data.message
        );
      }
      if (updateError) {
        showErrorModal(
          "Ошибка при изменении техники",
          getErrorMessage(updateError)?.data.message
        );
      }
      if (uploadingImageError) {
        showErrorModal(
          "Ошибка при изменении фото СТС",
          getErrorMessage(uploadingImageError)?.data.message
        );
      }
    }, [
      deleteVehicleError,
      verifyVehicleError,
      updateError,
      uploadingImageError,
    ]);

    const handleSetActiveVehicle = ({ id }: { id: number }) => {
      dispatch(setAvtiveVehicleId(id));
    };

    const handleSearchText = (text: string) => {
      dispatch(setSearchText(text));
    };

    const onSubmit = handleSubmit(async (data) => {
      const vehicleFormValues: IVehicleInputs = {
        vehicle_id: vehicle?.id,
        vehicle_type_id: data.specifications.tariff,
        company_id: vehicle?.company.id,
        chassis_model_id: data.main.chassisModel?.value || -1,
        kmu_model_id: data.main.kmuModel.value,
        search_radius_id: data.main.coverageRadius,
        vehicle_number: data.main.governmentNumber,
        sts_number: data.main.vrcNumber,
        location_address: data.main.location,
        arrow_carrying_capacity: data.specifications.boomCapacity,
        arrow_length: data.specifications.boomLength,
        board_carrying_capacity: data.specifications.sideLoadCapacity,
        vehicle_length: data.specifications.dimensions.length,
        vehicle_width: data.specifications.dimensions.width,
        vehicle_height: data.specifications.dimensions.height,
        is_verified: 1,
        options: data.specifications.optionalEquipment
          .filter((el) => el)
          .map((el) => +el),
      };

      if (typeof data.main.vrcPhoto === "object") {
        const formData = new FormData();
        formData.append("file_type_id", "7");
        formData.append("file", data.main.vrcPhoto[0]);
        const image = await uploadSts(formData).unwrap();
        const changedVehicle = await updateVehicle({
          ...vehicleFormValues,
          sts_photo_id: image.id,
        }).unwrap();
        await verifyVehicle({
          vehicleId: changedVehicle.id,
          companyId: changedVehicle.company.id,
        });
        handleSetActiveVehicle({ id: changedVehicle.id });
      } else {
        const changedVehicle = await updateVehicle(vehicleFormValues).unwrap();
        await verifyVehicle({
          vehicleId: changedVehicle.id,
          companyId: changedVehicle.company.id,
        });
        handleSetActiveVehicle({ id: changedVehicle.id });
      }

      refetch();
      resetStsUpload();
      resetUpdate();
    });

    const vehicleUpdateLoading =
      isUpdatingLoading || isStsImageUploadLoading || isVerifyVehicleLoading;

    const haveVehicles = !!vehicles?.length;

    return (
      <>
        <div className="grid h-fit grid-rows-[50px,1fr] gap-[10px] overflow-y-scroll">
          <div className="sticky top-0">
            <Input
              className="w-full"
              placeholder="Поиск (минимум 3 символа)"
              value={searchText}
              onChange={(e) => handleSearchText(e.target.value)}
            />
          </div>
          <QueryLayout
            isLoading={isVehiclesLoading}
            isFetching={isVehiclesFetching}
            error={vehiclesError}
          >
            {!haveVehicles && <NotFoundTitle />}
            {haveVehicles && (
              <VehicleList
                activeVehicleId={activeVehicleId}
                onItemClick={handleSetActiveVehicle}
                vehicles={vehicles}
              />
            )}
          </QueryLayout>
        </div>
        {activeVehicleId ? (
          <QueryLayout
            className="col-span-2 overflow-hidden"
            isLoading={isVehicleLoading}
            isFetching={isVehicleFetching}
            error={vehicleError}
          >
            <div className="col-span-2 mb-[15px] grid gap-[10px] overflow-y-scroll">
              <form onSubmit={onSubmit}>
                <FormProvider {...methods}>
                  <VehicleCharacteristics vehicle={vehicle} moderation />
                </FormProvider>
                <div className="grid grid-cols-2 gap-[15px]">
                  <Button
                    disabled={
                      Object.keys(errors).length !== 0 ||
                      !isDirty ||
                      vehicleUpdateLoading
                    }
                    type="submit"
                    className="text-base tracking-wider"
                  >
                    Одобрить
                  </Button>
                  <Button
                    className="text-base tracking-wider"
                    mode="red"
                    type="button"
                    onClick={showConfirmationModal}
                    disabled={isDeleteVehicleLoading}
                  >
                    Отказать
                  </Button>
                </div>
              </form>
            </div>
          </QueryLayout>
        ) : (
          <InformationBlock className="col-span-2 w-full" />
        )}
      </>
    );
  })
);
