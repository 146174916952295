import { FC, memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { OrderList } from "../../../common/components/Order/OrderList";
import { Tabs } from "../../../common/components/Tabs/Tabs";
import { useGetDisputesQuery } from "../../../services/orderApi";
import {
  setAvtiveDisputeId,
  setDisputeAccountId,
  setDisputesDateFrom,
  setDisputesDateTo,
  setDisputeStatus,
} from "./disputeSlice";
import { ORDER_STATUS } from "common/utils/consts";
import { IOrder } from "common/models/order/IOrder";
import { getQueryDateParam } from "common/utils/helpers";

export const DisputesTabs: FC = memo(() => {
  const disputesDateFrom = useAppSelector(
    (state) => state.platformManager.dispute.disputesDateFrom
  );
  const disputesDateTo = useAppSelector(
    (state) => state.platformManager.dispute.disputesDateTo
  );

  // const {
  //   data: disputes,
  //   isLoading: isDisputesLoading,
  //   isFetching: isDisputesFetching,
  //   error: disputesError,
  // } = useGetDisputesQuery({
  //   dateFrom: getQueryDateParam(disputesDateFrom),
  //   dateTo: getQueryDateParam(disputesDateTo),
  // });

  const dispatch = useAppDispatch();
  const disputeStatus = useAppSelector(
    (state) => state.platformManager.dispute.disputeStatus
  );
  const activeDisputeId = useAppSelector(
    (state) => state.platformManager.dispute.activeDisputeId
  );

  const handleSetAppealsStatus = (status: number) => {
    dispatch(setDisputeStatus(status));
  };

  const handleSetActiveDisputeId = (order: IOrder) => {
    dispatch(setAvtiveDisputeId(order.id));
    dispatch(setDisputeAccountId(order.account.id));
  };

  const handleDisputesDateFromChange = (date: Nullable<Date>) => {
    dispatch(setDisputesDateFrom(date?.toISOString() || null));
  };

  const handleDisputesDateToChange = (date: Nullable<Date>) => {
    dispatch(setDisputesDateTo(date?.toISOString() || null));
  };

  return (
    <>
      <Tabs
        tabs={[
          { value: 1, label: "Активные" },
          { value: 0, label: "Завершённые" },
        ]}
        value={disputeStatus}
        onTabClick={handleSetAppealsStatus}
      />
      <div>
        <DatePicker
          onStartDateChange={handleDisputesDateFromChange}
          onEndDateChange={handleDisputesDateToChange}
          placeholderText="Введите промежуток дат"
          isClearable={true}
        />
      </div>

      <OrderList
        filter={{
          statusId: [
            ORDER_STATUS.FINISHED,
            ORDER_STATUS.REJECTED,
            ORDER_STATUS.PAYMENT_ERROR,
          ],
          isActive: disputeStatus,
        }}
        activeOrderId={activeDisputeId}
        onItemClick={handleSetActiveDisputeId}
        withAddresses
      />
    </>
  );
});
