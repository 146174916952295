import { FC, useMemo, memo } from "react";
import { ShadowContainer } from "../Container/ShadowContainer";
import { Dropdown } from "../Dropdown/Dropdown";
import { Input } from "../Input/Input";
import { OrderItemFullDetails } from "../Order/OrderItemFullDetails";
import { timeFormatter } from "../../utils/helpers";
import { Slider } from "../Slider/Slider";
import { useGetTransactionByIdQuery } from "services/transactionApi";
import { QueryLayout } from "../Layout/QueryLayout";

export const TransactionInfo: FC<{ transactionId: number }> = memo(
  ({ transactionId }) => {
    const {
      data: transaction,
      isLoading,
      error,
    } = useGetTransactionByIdQuery(transactionId);

    const transactionDate = useMemo(
      () =>
        transaction?.created_at
          ? timeFormatter({ time: new Date(transaction.created_at) })
          : undefined,
      [transaction?.created_at]
    );

    return (
      <QueryLayout isLoading={isLoading} error={error}>
        {transaction && (
          <div>
            <ShadowContainer>
              <Dropdown title={`Детали транзакции #${transaction.id}`}>
                <div className="grid gap-[25px]">
                  <Input
                    className="w-full"
                    label="Дата"
                    value={transactionDate}
                    readOnly
                  />
                  <Input
                    className="w-full"
                    label="Статус"
                    value="В обработке"
                    readOnly
                  />
                  <Input
                    className="w-full"
                    label="Сумма"
                    value={`${transaction.credit || transaction.debit} руб.`}
                    readOnly
                  />
                </div>
              </Dropdown>
            </ShadowContainer>
            <ShadowContainer>
              <Dropdown title="Прикреплённые фото (чеки, договора)">
                <h4 className="mb-[10px] text-sm font-semibold text-gray">
                  Документы
                </h4>
                <Slider className="multiple" expandable images={[]} />
              </Dropdown>
            </ShadowContainer>
            {transaction.order?.id && (
              <ShadowContainer>
                <Dropdown title={`Детали заказа №${transaction.order.id}`}>
                  <OrderItemFullDetails
                    orderId={transaction.order.id}
                    readOnly
                  />
                </Dropdown>
              </ShadowContainer>
            )}
          </div>
        )}
      </QueryLayout>
    );
  }
);
