import { FC, memo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { Dropdown } from "../../../common/components/Dropdown/Dropdown";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { OrderItemDetails } from "../../../common/components/Order/OrderItemDetails";
import { TextArea } from "../../../common/components/TextArea/TextArea";
import { Title } from "../../../common/components/Title/Title";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { UserInformation } from "../../../common/components/User/UserInformation";
import { DisputesTabs } from "./DisputesTabs";
import { Cause } from "../../../common/components/Cause/Cause";
import { Slider } from "common/components/Slider/Slider";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const DisputePage: FC = withErrorBoundary(
  memo(() => {
    const activeDisputeId = useAppSelector(
      (state) => state.platformManager.dispute.activeDisputeId
    );
    const disputeAccountId = useAppSelector(
      (state) => state.platformManager.dispute.disputeAccountId
    );

    const { showModal } = useGlobalModalContext();

    const showBonusesModal = (userId: number) => {
      showModal(MODAL_TYPES.BONUSES_MODAL, {
        confirmText: "Пользователю успешно начисленны баллы",
        userId,
      });
    };

    const showConfirmApproveModal = () => {
      showModal(MODAL_TYPES.CONFIRM_MODAL, {
        title: "Одобрить отмену заказа",
        subTitle: "Заказ будет безвозвратно отменён",
        withComment: true,
        confirmText: "Спор одобрен",
      });
    };

    const showConfirmRejectModal = () => {
      showModal(MODAL_TYPES.CONFIRM_MODAL, {
        title: "Отклонить спор",
        subTitle: "Спор будет отклонён",
        withComment: true,
        confirmText: "Спор отклонён",
      });
    };

    return (
      <>
        <div className="grid grid-rows-[35px,40px,1fr] gap-[10px] overflow-y-hidden">
          <DisputesTabs />
        </div>
        {activeDisputeId && disputeAccountId ? (
          <>
            <div className="overflow-y-scroll">
              <ShadowContainer>
                <Dropdown title="Детали заказа" isOpen>
                  <OrderItemDetails orderId={activeDisputeId} readOnly />
                </Dropdown>
              </ShadowContainer>
              <ShadowContainer>
                <Dropdown title="Комментарий автора спора">
                  <TextArea
                    className="mb-[25px] h-full w-full resize-none"
                    label="Комментарий"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                    tenetur animi libero? Assumenda delectus impedit, sit
                    laboriosam voluptas ratione minus cumque placeat aperiam
                    obcaecati eveniet, perspiciatis adipisci minima ullam nemo.
                  </TextArea>
                  <ul className="mb-[15px] flex flex-wrap">
                    <li>
                      <Cause primary>Плохой салон</Cause>
                    </li>
                    <li>
                      <Cause primary>Плохие тормоза</Cause>
                    </li>
                    <li>
                      <Cause primary>Машина сломалась</Cause>
                    </li>
                  </ul>
                  <div>
                    <h4 className="mb-[10px] text-sm font-semibold text-gray">
                      Фото
                    </h4>
                    <div className="mb-[15px]">
                      <Slider expandable images={[]} />
                    </div>
                  </div>
                </Dropdown>
              </ShadowContainer>
              <ShadowContainer>
                <Dropdown title="Комментарий оппонента по спору">
                  <TextArea
                    className="mb-[25px] h-full w-full resize-none"
                    label="Комментарий"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                    tenetur animi libero? Assumenda delectus impedit, sit
                    laboriosam voluptas ratione minus cumque placeat aperiam
                    obcaecati eveniet, perspiciatis adipisci minima ullam nemo.
                  </TextArea>
                  <div>
                    <h4 className="mb-[10px] text-sm font-semibold text-gray">
                      Фото
                    </h4>
                    <div className="mb-[15px]">
                      <Slider expandable images={[]} />
                    </div>
                  </div>
                </Dropdown>
              </ShadowContainer>
            </div>
            <div className="grid grid-rows-[1fr,1fr,100px] gap-[10px] overflow-y-hidden">
              <div className="overflow-y-scroll">
                <Title className="mb-[10px] flex items-center">
                  Пользователь открывший спор
                </Title>
                <UserInformation userId={disputeAccountId} />
                <div className="mt-[15px] grid gap-[10px]">
                  <Button onClick={() => showBonusesModal(disputeAccountId)}>
                    Начислить баллы
                  </Button>
                  <Button className="h-[45px] w-full" mode="darkBorder">
                    Отправить разработчику
                  </Button>
                </div>
              </div>
              <div className="overflow-y-scroll">
                <Title className="mb-[10px] mt-[20px] flex items-center">
                  Пользователь-оппонент
                </Title>
                <UserInformation userId={disputeAccountId} />
                <div className="mt-[15px] grid gap-[10px]">
                  <Button onClick={() => showBonusesModal(disputeAccountId)}>
                    Начислить баллы
                  </Button>
                  <Button className="h-[45px] w-full" mode="darkBorder">
                    Отправить разработчику
                  </Button>
                </div>
              </div>
              <div className="mb-[15px] grid gap-[10px]">
                <Button onClick={showConfirmApproveModal}>Одобрить</Button>
                <Button mode="red" onClick={showConfirmRejectModal}>
                  Отклонить
                </Button>
              </div>
            </div>
          </>
        ) : (
          <InformationBlock className="col-span-2 w-full" />
        )}
      </>
    );
  })
);
