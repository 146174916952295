import { ORDER_STATUS } from "./../../../common/utils/consts";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface RequestsState {
  filters: {
    vehicles: number[];
    period?: number[];
    timeAt?: string;
    timeTo?: string;
  };
  vehicleAssignment: {
    activeOrderId?: number;
    assignedVehicleTypeId?: number;
    assignedVehicleId?: number;
    assignedVehicleCategoryId?: number;
    assignedDriverId?: number;
  };
}

const initialState: RequestsState = {
  filters: {
    vehicles: [],
    period: [
      ORDER_STATUS.IMPLEMENTING_COMPANY_SELECTION,
      ORDER_STATUS.DRIVER_CHOOSING,
      ORDER_STATUS.PAYMENT_WAITING,
    ],
    timeAt: undefined,
    timeTo: undefined,
  },
  vehicleAssignment: {},
};

const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setAssignmentRequestId(state, action: PayloadAction<Undefinable<number>>) {
      state.vehicleAssignment = {
        activeOrderId: action.payload,
      };
    },
    setAssigningVehicleId(state, action: PayloadAction<Undefinable<number>>) {
      state.vehicleAssignment.assignedVehicleId = action.payload;
    },
    setAssigningVehicleCategoryId(
      state,
      action: PayloadAction<Undefinable<number>>
    ) {
      state.vehicleAssignment.assignedVehicleCategoryId = action.payload;
    },
    setAssigningVehicleTypeId(state, action: PayloadAction<Undefinable<number>>) {
      state.vehicleAssignment.assignedVehicleTypeId = action.payload;
    },
    setAssigningDriverId(state, action: PayloadAction<Undefinable<number>>) {
      state.vehicleAssignment.assignedDriverId = action.payload;
    },
    resetAssignment(state) {
      state.vehicleAssignment = {};
    },

    setFilterVehicles(state, action: PayloadAction<number>) {
      let currentSelectedVehicles = state.filters.vehicles;

      if (currentSelectedVehicles.includes(action.payload)) {
        state.filters.vehicles = currentSelectedVehicles.filter(
          (el) => el !== action.payload
        );
      } else {
        state.filters.vehicles = [action.payload, ...currentSelectedVehicles];
      }
    },
    setFilterPeriod(state, action: PayloadAction<Undefinable<number[]>>) {
      state.filters.period = action.payload;
    },
    setFilterTimeAt(state, action: PayloadAction<Nullable<Date>>) {
      state.filters.timeAt = action.payload?.toLocaleDateString("sv");
    },
    setFilterTimeTo(state, action: PayloadAction<Nullable<Date>>) {
      state.filters.timeTo = action.payload?.toLocaleDateString("sv");
    },
    resetFilter(state) {
      state.filters = {
        vehicles: [],
        period: [
          ORDER_STATUS.IMPLEMENTING_COMPANY_SELECTION,
          ORDER_STATUS.DRIVER_CHOOSING,
          ORDER_STATUS.PAYMENT_WAITING,
        ],
        timeAt: undefined,
        timeTo: undefined,
      };
    },
  },
});

export const orderFiltersSelector = (state: RootState) =>
  state.implementerCompanyManager.requests.filters;
export const requestVehicleAssignmentSelector = (state: RootState) =>
  state.implementerCompanyManager.requests.vehicleAssignment;

export const {
  setAssignmentRequestId,
  setAssigningVehicleId,
  setAssigningDriverId,
  resetAssignment,

  setAssigningVehicleTypeId,
  setAssigningVehicleCategoryId,
  setFilterVehicles,
  setFilterPeriod,
  setFilterTimeAt,
  setFilterTimeTo,
  resetFilter,
} = requestsSlice.actions;

export const requestReducer = requestsSlice.reducer;
