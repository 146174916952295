import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { OptionFormInputs } from "./OptionFormInputs";
import { Button } from "../../../../../common/components/Button/Button";
import { useParams } from "react-router-dom";
import {
  useGetVehicleOptionByIdQuery,
  useUpdateVehicleOptionMutation,
} from "services/vehicle/optionsApi";
import { Success } from "common/components/Message/Success/Success";
import { getErrorMessage } from "common/utils/helpers";
import { Error } from "common/components/Message/Error/Error";

export const OptionEdit: FC = () => {
  const { id } = useParams();

  const {
    data: option,
    isLoading: isOptionLoading,
    error: optionError,
  } = useGetVehicleOptionByIdQuery(id ? +id : -1, { skip: !id });

  const [update, { isLoading: isUpdating, error: updateError, isSuccess }] =
    useUpdateVehicleOptionMutation();

  const methods = useForm<{
    name: string;
    price: number;
    id: number;
  }>();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (option) {
      reset(option);
    }
  }, [option]);

  const onSubmit = handleSubmit((data) => {
    if (option) {
      update({ ...data, vehicle_category: option.vehicle_category });
    }
  });

  if (optionError || updateError) {
    return (
      <Error>{getErrorMessage(optionError || updateError)?.data.message}</Error>
    );
  }

  if (isSuccess) {
    return <Success>Марка успешно отредактирована!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <OptionFormInputs />
      </FormProvider>
      <Button
        className="w-fit"
        disabled={!isDirty || isUpdating || isOptionLoading}
        loader={isUpdating}
      >
        Изменить
      </Button>
    </form>
  );
};
