import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../Button/Button";
import { CompanyFormInputs } from "./CompanyFormInputs";
import { ShadowContainer } from "../Container/ShadowContainer";
import { Dropdown } from "../Dropdown/Dropdown";
import { EditCompanyValues } from "../../models/forms/EditCompanyValues";
import { getErrorMessage } from "../../utils/helpers";
import {
  useDeleteCompanyMutation,
  useGetCompanyByIdQuery,
  useUpdateCompanyByIdMutation,
} from "../../../services/companiesApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { ICompanyEditProps } from "common/models/components/company/ICompanyEditProps";
import { SvgSuccess } from "assets/icons/SvgSuccess";
import { Title } from "../Title/Title";
import { useAppSelector } from "app/hooks";
import { MODAL_TYPES } from "common/utils/consts";
import { useGlobalModalContext } from "../Modal/GlobalModal";

export const CompanyEdit: FC<ICompanyEditProps> = ({
  companyId,
  isOpen,
  isModeration,
  readOnly = false,
}) => {
  const userId = useAppSelector((state) => state.user.current?.id);

  const {
    data: company,
    isLoading: isGettingCompanyLoading,
    isFetching,
    error,
  } = useGetCompanyByIdQuery(companyId);

  const [
    deleteCompany,
    { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess },
  ] = useDeleteCompanyMutation();

  const [
    updateCompany,
    {
      isSuccess: isUpdateSuccess,
      isLoading: isUpdateLoading,
      error: updatingError,
      reset: resetQuery,
    },
  ] = useUpdateCompanyByIdMutation();

  const { showModal } = useGlobalModalContext();

  const showConfirmationModal = () => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      subTitle: "Вы уверены что хотите удалить компанию?",
      confirmText: "Компания успешно удалена",
      onConfirm: () => deleteCompany(companyId),
    });
  };

  const currentCompany = {
    id: company?.id,
    company_category_id: company?.category.id,
    name: company?.name,
    bank_name: company?.bank_name,
    inn: company?.inn,
    orgn: company?.orgn,
    legal_address: company?.legal_address,
    post_address: company?.post_address,
    kpp: company?.kpp,
    bik: company?.bik,
    payment_account: company?.payment_account,
    is_works_for_himself: company?.is_works_for_himself,
    logotype: company?.logotype?.path,
    documents: company?.documents.length
      ? company.documents?.map((doc) => doc.path)
      : undefined,
  };

  const methods = useForm<EditCompanyValues>({
    defaultValues: currentCompany,
  });

  const { handleSubmit, reset: resetForm } = methods;

  const onSubmit = handleSubmit((data) => {
    updateCompany({ ...data, post_address: data.post_address || undefined });
  });

  const handleResetCreating = () => {
    resetForm(currentCompany);
    resetQuery();
  };

  useEffect(() => {
    resetForm(currentCompany);
  }, [company]);

  return (
    <ShadowContainer>
      <Dropdown
        title={readOnly ? "Данные о компании" : "Редактировать компанию"}
        isOpen={isOpen}
      >
        {isDeleteSuccess && (
          <div className="grid grid-rows-[1fr,45px]">
            <div className="flex h-[100px] flex-col items-center justify-center text-primary">
              <SvgSuccess className="mb-[15px] h-[50px] w-[50px]" />
              <Title className="text-center">Компания успешно удалена</Title>
            </div>
          </div>
        )}
        {isUpdateSuccess && (
          <div className="grid grid-rows-[1fr,45px]">
            <div className="flex h-[200px] flex-col items-center justify-center text-primary">
              <SvgSuccess className="mb-[15px] h-[50px] w-[50px]" />
              <Title className="text-center">
                Компания успешно отредактированна
              </Title>
            </div>
            <Button onClick={handleResetCreating}>Продолжить</Button>
          </div>
        )}
        {!isUpdateSuccess && !isDeleteSuccess && (
          <QueryLayout
            isLoading={isGettingCompanyLoading}
            isFetching={isFetching}
            error={error}
          >
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <CompanyFormInputs
                  isModeration={isModeration}
                  readOnly={readOnly || company?.owner.id !== userId}
                />
                {!readOnly && company?.owner.id === userId && (
                  <>
                    <div className="grid grid-cols-2 gap-[15px]">
                      <Button
                        className="w-full"
                        disabled={isUpdateLoading || isDeleteLoading}
                        loader={isUpdateLoading || isDeleteLoading}
                      >
                        Сохранить
                      </Button>
                      <Button
                        type="button"
                        className="w-full"
                        mode="red"
                        onClick={showConfirmationModal}
                        disabled={isDeleteLoading || isUpdateLoading}
                        loader={isUpdateLoading || isDeleteLoading}
                      >
                        Удалить
                      </Button>
                    </div>

                    <div className="mt-[15px] text-center">
                      {updatingError && (
                        <span
                          className={`m-auto mb-[12px] block text-sm font-semibold text-red`}
                        >
                          {getErrorMessage(updatingError)?.data?.message}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </form>
            </FormProvider>
          </QueryLayout>
        )}
      </Dropdown>
    </ShadowContainer>
  );
};
