import { useAppDispatch, useAppSelector } from "app/hooks";
import { SvgCaretUp } from "assets/icons/SvgCaretUp";
import { Button } from "common/components/Button/Button";
import { CustomInput } from "common/components/Input/CustomInput";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { List } from "common/components/List/List";
import { VehicleListItem } from "common/components/Vehicle/VehicleListItem";
import { useGetVehicleCategoriesQuery } from "services/vehicle/vehiclesApi";
import {
  nextOrderStep,
  setVehicleCategoryId,
} from "features/ordering/orderingSlice";

export const CategoryChoose = () => {
  const dispatch = useAppDispatch();
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    error: categoriesError,
  } = useGetVehicleCategoriesQuery();

  const vehicleCategoryId = useAppSelector(
    (state) => state.ordering.vehicleCategoryId
  );

  const handleVehiclecategoryChange = (id: number) => {
    dispatch(setVehicleCategoryId(id));
  };

  const handleNextOrderState = () => {
    dispatch(nextOrderStep());
  };

  return (
    <>
      <QueryLayout isLoading={isCategoriesLoading} error={categoriesError}>
        <List>
          {categories &&
            categories.map((category) => {
              const isChecked =
                !!vehicleCategoryId && +vehicleCategoryId === category.id;
              return (
                <li key={category.id} className="relative">
                  <CustomInput
                    value={category.id}
                    checked={isChecked}
                    type="radio"
                    onClick={() => handleVehiclecategoryChange(category.id)}
                    innerelement={
                      <VehicleListItem
                        mode="short"
                        name={category.name}
                        img={category.icon.path}
                      />
                    }
                  />
                  {!isChecked && (
                    <SvgCaretUp className="absolute right-[20px] top-[50%] h-[5px] w-[10px] translate-y-[-50%] rotate-[90deg]" />
                  )}
                </li>
              );
            })}
        </List>
      </QueryLayout>
      <Button
        className="mt-[25px] w-full"
        onClick={handleNextOrderState}
        disabled={!vehicleCategoryId}
      >
        Продолжить
      </Button>
    </>
  );
};
