import { FC, useEffect, useState } from "react";
import { Button } from "common/components/Button/Button";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { Spinner } from "common/components/Spinner/Spinner";
import { getHours } from "common/utils/helpers";

export const Searching: FC<{
  dateAt: Date;
  title: string;
  orderId: number;
}> = ({ dateAt, title, orderId }) => {
  const [timePassed, setTimePassed] = useState<number>(
    (+new Date() - +dateAt) / 1000
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimePassed((+new Date() - +dateAt) / 1000);
    }, 800);
    return () => clearInterval(interval);
  }, [dateAt]);

  const { showModal } = useGlobalModalContext();

  const showOrderCancelationModal = () => {
    showModal(MODAL_TYPES.ORDER_CANCELATION_MODAL, { orderId });
  };

  return (
    <div className="grid gap-[15px] justify-center">
      <Spinner className="h-[50px] w-[50px] text-primary" />
      <div className="text-center font-semibold">
        {title}
        <span className="ml-[4px] inline-block w-[60px] text-primary">
          {getHours(timePassed)}
        </span>
      </div>
      <Button mode="red" className="w-full max-w-xs" onClick={showOrderCancelationModal}>
        Отменить заказ
      </Button>
    </div>
  );
};
