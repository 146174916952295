import { FC, useState } from "react";
import { Tabs } from "../../../common/components/Tabs/Tabs";
import { List } from "../../../common/components/List/List";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { NotFoundTitle } from "../../../common/components/Title/NotFoundTitle";
import {
  useGetAllAppealsQuery,
  useGetAppealStatusesQuery,
} from "../../../services/appealApi";
import { AppealListGenerator } from "../Appeal/AppealListGenerator";

export const UserAppeals: FC<{ userId: number }> = ({ userId }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const {
    data: appeals,
    isLoading: isAppealsLoading,
    isFetching: isAppealsFetching,
    error: appealsError,
  } = useGetAllAppealsQuery({ userId, statusId: activeTab || undefined });

  const { data: appealStatuses } = useGetAppealStatusesQuery();

  const appealStatusesArr = appealStatuses?.map((status) => ({
    value: status.id,
    label: status.name,
  }));

  const handleSetAppealStatus = (id: number) => {
    setActiveTab(id);
  };

  return (
    <div>
      {appealStatusesArr && (
        <Tabs
          className="whitespace-nowrap"
          tabs={[{ value: 0, label: "Все" }, ...appealStatusesArr]}
          value={activeTab}
          onTabClick={handleSetAppealStatus}
        />
      )}
      <QueryLayout
        isLoading={isAppealsLoading}
        isFetching={isAppealsFetching}
        error={appealsError}
      >
        {appeals?.length ? (
          <List className="mt-[10px] overflow-y-scroll">
            <AppealListGenerator activeAppealId={1} appeals={appeals} />
          </List>
        ) : (
          <NotFoundTitle />
        )}
      </QueryLayout>
    </div>
  );
};
