import { FC } from "react";
import { NavLink } from "react-router-dom";
import { SvgLogo } from "../../../assets/logo/SvgLogo";
import { SvgUserCircle } from "assets/icons/SvgUserCircle";
import { SvgListDashes } from "assets/icons/SvgListDashes";
import { SvgChatCircleDots } from "assets/icons/SvgChatCircleDots";
import { SvgHome } from "assets/icons/SvgHome";
import { SvgUsers } from "assets/icons/SvgUsers";
import { SidebarLinkItem } from "common/components/Navigation/LinkItem";
import { SvgSuitcase } from "assets/icons/SvgSuitcase";
import { SvgInvoice } from "assets/icons/SvgInvoice";
import { SvgTransaction } from "assets/icons/SvgTransaction";

export const CustomerCompanyManagerSidebar: FC = () => {
  return (
    <aside className="z-10 flex w-[90px] flex-col items-center bg-lightWhiteGray">
      <NavLink
        to="/"
        className="flex w-full items-center justify-center pt-[15px] pb-[18px] text-primary hover:text-black focus:text-black active:text-primary"
      >
        <SvgLogo className="h-[34px] w-[34px] transition " />
      </NavLink>
      {routeLinks.map((route) => (
        <SidebarLinkItem
          key={route.path}
          to={route.path}
          label={route.label}
          icon={route.Icon}
        />
      ))}
    </aside>
  );
};

const routeLinks = [
  {
    path: "/",
    Icon: <SvgHome className="h-[16px] w-[16px]" />,
    label: "Главная",
  },
  {
    path: "/orders",
    Icon: <SvgListDashes className="h-[20px] w-[20px]" />,
    label: "Заказы",
  },
  {
    path: "/support",
    Icon: <SvgChatCircleDots className="h-[20px] w-[20px]" />,
    label: "Поддержка",
  },
  {
    path: "/transactions",
    Icon: <SvgTransaction className="h-[20px] w-[20px]" />,
    label: "Транзакции",
  },
  {
    path: "/invoices",
    Icon: <SvgInvoice className="h-[20px] w-[20px]" />,
    label: "Счета",
  },
  {
    path: "/employees",
    Icon: <SvgUsers className="h-[20px] w-[20px]" />,
    label: "Работники",
  },
  {
    path: "/companies",
    Icon: <SvgSuitcase className="h-[20px] w-[20px]" />,
    label: "Компании",
  },
  {
    path: "/profile",
    Icon: <SvgUserCircle className="h-[20px] w-[20px]" />,
    label: "Профиль",
  },
];
