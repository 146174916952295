import { useForm, useWatch } from "react-hook-form";
import { Button } from "../Button/Button";
import { useGlobalModalContext } from "./GlobalModal";
import { TextArea } from "../TextArea/TextArea";
import { Cause } from "../Cause/Cause";
import { MODAL_TYPES } from "common/utils/consts";
import {
  useCancelOrderMutation,
  useGetOrderCancelReasonsQuery,
} from "services/orderApi";
import { CustomInput } from "../Input/CustomInput";
import { useEffect } from "react";
import { getErrorMessage } from "common/utils/helpers";
import { SvgClose } from "assets/icons/SvgClose";

export const OrderCancelationModal = () => {
  const { hideModal, showModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { orderId } = modalProps;

  const { data: reasons } = useGetOrderCancelReasonsQuery();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<{ reasonId: string[]; message: string }>({
    defaultValues: { reasonId: [] },
  });

  const activeReasonsId = useWatch({ control, name: "reasonId" });

  const [cancelOrder, { isLoading, isSuccess, error }] =
    useCancelOrderMutation();

  const handleModalToggle = () => {
    hideModal();
  };

  useEffect(() => {
    if (isSuccess) {
      handleModalToggle();
      showModal(MODAL_TYPES.SUCCESS_MODAL, { title: "Заказ успешно отменен" });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      handleModalToggle();
      showModal(MODAL_TYPES.ERROR_MODAL, {
        title: "Ошибка при отмене заказа",
        subTitle: getErrorMessage(error)?.data.message,
      });
    }
  }, [error]);

  const onSubmit = handleSubmit((data) => {
    cancelOrder({
      order_id: orderId,
      reason_id: data.reasonId
        .filter((reason) => reason)
        .map((reason) => +reason),
      message: data.message,
    });
  });

  return (
    <div className="relative w-[320px] overflow-y-scroll rounded bg-white p-[15px]">
      <SvgClose onClick={handleModalToggle} className="h-[23px] w-[23px] text-graySecond relative top-[11px] ml-auto cursor-pointer" />
      <div className="mb-[25px] text-center text-lg">Отмена заказа</div>
      <form onSubmit={onSubmit}>
        <div>
          <span
            className={`mb-[10px] block text-sm font-semibold ${
              !!errors.reasonId ? "text-red" : "text-gray"
            }`}
          >
            Причина
          </span>

          <ul className="mb-[5px] flex flex-wrap">
            {reasons?.map((reason, i) => (
              <li key={reason.id}
              >
                <CustomInput
                  onClick={() => {
                    const reasonMessage = getValues("message")
                    if (!reasonMessage.includes(String(reason.name))) {
                      setValue("message", reasonMessage ? `${reasonMessage} ${reason.name}` : reason.name)
                    }
                  }}
                  type="checkbox"
                  {...register(`reasonId.${i}`)}
                  value={reason.id}
                  className="border-none p-0"
                  innerelement={
                    <Cause
                      className={`${
                        activeReasonsId.includes(String(reason.id))
                          ? ""
                          : "bg-[#FFFFFF] border-current"
                      }`}
                      hover={!activeReasonsId.includes(String(reason.id)) ? "hover:border-[#FFA321] hover:bg-[#FFFFFF] hover:text-[#FFA321]" : ""}
                    >
                      {reason.name}
                    </Cause>
                  }
                />
              </li>
            ))}
          </ul>
        </div>
        <TextArea
          {...register("message", { required: true })}
          invalid={!!errors.message}
          className="mb-[25px] h-[125px] w-full resize-none"
          placeholder="Причина"
          label="По какой причине вы хотите остановить выполнение заказа"
        />
        <Button mode="red" className="w-full" disabled={isLoading} loader={isLoading}>
          Подтвердить отмену
        </Button>
      </form>
    </div>
  );
};
