import { Button } from "../Button/Button";
import { useGlobalModalContext } from "./GlobalModal";
import { SvgClose } from "../../../assets/icons/SvgClose";
import * as VoxImplant from 'voximplant-websdk';
import { useState } from "react";

export const CallVoxImplant = () => {
    const { hideModal, store } = useGlobalModalContext();
    const { modalProps } = store;
    const [answered, setAnswered] = useState(false)

    const cancelCall = () => {
        hideModal();
        if (answered) {
            modalProps.ev.call.hangup()
        } else {
            modalProps.ev.call.decline()
        }
    };

    const answerCall = () => {
        modalProps.ev.call.answer();
        setAnswered(true)
    }

    modalProps.ev.call.addEventListener(VoxImplant.CallEvents.Disconnected, () => {
        hideModal()
    });

    modalProps.ev.call.addEventListener(VoxImplant.CallEvents.Failed, () => {
        hideModal()
    });


    return (
        <div className="relative w-[320px] overflow-y-scroll rounded bg-white p-[15px]">
            <SvgClose onClick={cancelCall} className="h-[23px] w-[23px] text-graySecond relative top-[11px] ml-auto cursor-pointer" />
            <div className="mb-[25px] text-center text-lg">{answered ? "In progress" : "Incoming Call..."}</div>
            <p className="mb-[25px] text-xs text-black text-center text-xs">
                {modalProps.ev.call.settings.number}
            </p>
            <div className="flex gap-5">
                {!answered && <Button onClick={answerCall}>Ответить</Button>}
                <Button mode="red" onClick={cancelCall}>Сбросить</Button>
            </div>
        </div>
    );
};
