import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ImplementerCompanyManagerHeader } from "../../features/implementerCompanyManager/header/ImplementerCompanyManagerHeader";
import { ImplementerCompanyManagerSidebar } from "../../features/implementerCompanyManager/sidebar/ImplementerCompanyManagerSidebar";
import { CompaniesPage } from "../../features/implementerCompanyManager/companies/CompaniesPage";
import { EmployeesPage } from "../../features/implementerCompanyManager/employees/EmployeesPage";
import { OrdersPage } from "../../features/implementerCompanyManager/orders/OrdersPage";
import { RequestsPage } from "../../features/implementerCompanyManager/requests/RequestsPage";
import { TransactionsPage } from "../../features/implementerCompanyManager/transactions/TransactionsPage";
import { VehiclesPage } from "../../features/implementerCompanyManager/vehicles/VehiclesPage";
import { SvgClipboardText } from "../../assets/icons/SvgClipboardText";
import { SvgUsers } from "../../assets/icons/SvgUsers";
import { SvgTruck } from "../../assets/icons/SvgTruck";
import { SvgListDashes } from "../../assets/icons/SvgListDashes";
import { SvgUserCircle } from "../../assets/icons/SvgUserCircle";
import { useAppSelector } from "../../app/hooks";
import { ProfilePage } from "features/user/profile/ProfilePage";
import { SvgSuitcase } from "assets/icons/SvgSuitcase";
import { SvgTransaction } from "assets/icons/SvgTransaction";
import { Map } from "common/components/Map/Map";

export const ImplementerCompanyManagerPage: FC = () => {
  const currentCompany = useAppSelector(
    (state) => state.user.current?.account?.company
  );

  const route = useAppSelector(
    (state) => state.implementerCompanyManager.map.route
  );

  return (
    <>
      <ImplementerCompanyManagerSidebar />
      <div className="h-full w-full">
        <ImplementerCompanyManagerHeader />
        <main className="relative h-full min-h-[calc(100vh-90px)]">
          <Routes>
            {implementerCompanyManagerRoutesLinks.map((route) =>
              !currentCompany && route.isCompanyRequired ? null : (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              )
            )}
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
          <Map route={route} />
        </main>
      </div>
    </>
  );
};

export const implementerCompanyManagerRoutesLinks = [
  {
    Icon: <SvgClipboardText className="h-[20px] w-[20px]" />,
    label: "Заявки",
    path: "/",
    element: <RequestsPage />,
    isCompanyRequired: true,
  },
  {
    Icon: <SvgUsers className="h-[20px] w-[20px]" />,
    label: "Сотрудники",
    path: "/employees",
    element: <EmployeesPage />,
    isCompanyRequired: true,
  },
  {
    Icon: <SvgTruck className="h-[20px] w-[20px]" />,
    label: "Техника",
    path: "/vehicles",
    element: <VehiclesPage />,
    isCompanyRequired: true,
  },
  {
    Icon: <SvgListDashes className="h-[20px] w-[20px]" />,
    label: "Заказы",
    path: "/orders",
    element: <OrdersPage />,
    isCompanyRequired: true,
  },
  {
    Icon: <SvgUserCircle className="h-[20px] w-[20px]" />,
    label: "Профиль",
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    Icon: <SvgSuitcase className="h-[20px] w-[20px]" />,
    label: "Компании",
    path: "/companies",
    element: <CompaniesPage />,
  },
  {
    Icon: <SvgTransaction className="h-[20px] w-[20px]" />,
    label: "Транзакции",
    path: "/transactions",
    element: <TransactionsPage />,
    isCompanyRequired: true,
  },
];
