import { FC } from "react";
import { NavLink } from "react-router-dom";
import { SvgLogo } from "../../../assets/logo/SvgLogo";
import { SvgUserCircle } from "assets/icons/SvgUserCircle";
import { SidebarLinkItem } from "common/components/Navigation/LinkItem";

const routeLinks = [
  {
    path: "/",
    Icon: <SvgUserCircle className="h-[20px] w-[20px]" />,
    label: "Профиль",
  },
];

export const DriverSidebar: FC = () => {
  return (
    <aside className="z-10 flex w-[90px] flex-col items-center bg-lightWhiteGray">
      <NavLink
        to="/"
        className="flex w-full items-center justify-center pt-[15px] pb-[18px] text-primary hover:text-black focus:text-black active:text-primary"
      >
        <SvgLogo className="h-[34px] w-[34px] transition " />
      </NavLink>
      {routeLinks.map((route) => (
        <SidebarLinkItem
          key={route.path}
          to={route.path}
          label={route.label}
          icon={route.Icon}
        />
      ))}
    </aside>
  );
};
