import { FC, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Spinner } from "../common/components/Spinner/Spinner";
import { guestLogin, login } from "../features/user/userSlice";
import { AuthorizationPage } from "../pages/AuthorizationPage";
import { MainPage } from "../pages/MainPage";
import { useAuthUserQuery } from "../services/authApi";
import { useAppDispatch, useAppSelector } from "./hooks";
import * as VoxImplant from 'voximplant-websdk';
import { useGlobalModalContext } from "../common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "../common/utils/consts";

export const App: FC = () => {
  const { data, isLoading } = useAuthUserQuery();
  const dispatch = useAppDispatch();
  const { showModal } = useGlobalModalContext();

  const isReady = useAppSelector((state) => state.user.isReady);

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        dispatch(login(data));
      } else {
        dispatch(guestLogin());
      }
    }
  }, [data, dispatch, isLoading]);

  useEffect(() => {
    const customerName = 'support',
        appName = 'calls.manipulators',
        userName = 'n4',
        password = '9d08ed48';
    const sdk = VoxImplant.getInstance();
    if (!sdk.alreadyInitialized) {
      sdk.init()
          .then(ev=>sdk.connect())
          .then(ev=>sdk.login(`${customerName}@${appName}.${userName}.voximplant.com`, password))
          .then(ev=>{
            sdk.on(VoxImplant.Events.IncomingCall,ev=>{
              showModal(MODAL_TYPES.CALL_VOXIMPLANT, { ev });
            })
          })
    }
  }, [])

  if (!isReady)
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="h-[100px] w-[100px]" />
      </div>
    );

  return (
    <div className="min-h-screen overflow-hidden">
      <Routes>
        <Route path="*" element={<MainPage />} />
        <Route path="auth/*" element={<AuthorizationPage />} />
      </Routes>
    </div>
  );
};
