import { FC } from "react";
import { Button } from "../../../../../common/components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { ChassisMarkFormInputs } from "./ChassisMarkFormInputs";
import { useCreateChassisMarkMutation } from "services/vehicle/chassisApi";
import { Error } from "common/components/Message/Error/Error";
import { getErrorMessage } from "common/utils/helpers";
import { Success } from "common/components/Message/Success/Success";

export const ChassisMarkAdd: FC = () => {
  const [create, { isLoading, error, isSuccess }] =
    useCreateChassisMarkMutation();

  const methods = useForm<{
    name: string;
  }>();

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {
    create(data);
  });

  if (error) {
    return <Error>{getErrorMessage(error)?.data.message}</Error>;
  }

  if (isSuccess) {
    return <Success>Марка успешно создана!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <ChassisMarkFormInputs />
      </FormProvider>
      <Button className="w-fit" disabled={isLoading} loader={isLoading}>
        Сохранить
      </Button>
    </form>
  );
};
