import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IFinishedOrderModerationState {
  activeOrderId?: number;
  finishedOrderDateFrom?: string;
  finishedOrderDateTo?: string;
}

const initialState: IFinishedOrderModerationState = {};

const finishedOrderModerationSlice = createSlice({
  name: "finishedOrderModeration",
  initialState,
  reducers: {
    setAvtiveFinishedOrderId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeOrderId === action.payload) {
        state.activeOrderId = undefined;
      } else state.activeOrderId = action.payload;
    },
    setDateFrom(state, action: PayloadAction<Undefinable<string>>) {
      state.finishedOrderDateFrom = action.payload;
    },
    setDateTo(state, action: PayloadAction<Undefinable<string>>) {
      state.finishedOrderDateTo = action.payload;
    },
  },
});

export const { setAvtiveFinishedOrderId, setDateFrom, setDateTo } =
  finishedOrderModerationSlice.actions;

export const finishedOrderModerationReducer =
  finishedOrderModerationSlice.reducer;
