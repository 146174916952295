import { FC, useState } from "react";
import { Button } from "../../../common/components/Button/Button";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { CustomInput } from "../../../common/components/Input/CustomInput";
import { EmployeeListItem } from "../../../common/components/Employee/EmployeeListItem";
import { Input } from "../../../common/components/Input/Input";
import { List } from "../../../common/components/List/List";
import { Title } from "../../../common/components/Title/Title";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import {
  useDeleteVehicleDriversMutation,
  useSetVehicleDriversMutation,
} from "../../../services/vehicle/vehiclesApi";
import { useGetCompanyEmployeesQuery } from "../../../services/employeeApi";
import { IVehicleDriverEditProps } from "common/models/components/vehicle/IVehicleDriverEditProps";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";
import { EMPLOYEE_ROLE } from "common/utils/consts";

export const VehicleDriverEdit: FC<IVehicleDriverEditProps> = ({
  setEditDrivers,
  vehicleId,
  companyId,
  currentDrivers,
}) => {
  const [searchText, setSearchText] = useState<string>("");

  const currentDriversId = currentDrivers?.map((driver) => driver.id);

  const {
    data: drivers,
    isLoading,
    isFetching,
    error,
  } = useGetCompanyEmployeesQuery({
    roleId: EMPLOYEE_ROLE.DRIVER,
    companyId,
    query: searchText.length > 3 ? searchText : undefined,
  });
  const [
    setDrivers,
    { isLoading: isSettingDriversLoading, error: settingDriversError },
  ] = useSetVehicleDriversMutation();
  const [
    deleteDrivers,
    { isLoading: isDeletingDriversLoading, error: deletingDriversError },
  ] = useDeleteVehicleDriversMutation();

  const [activeDrivers, setActiveDrivers] = useState<number[]>(
    currentDriversId || []
  );

  const handlerSetActiveDrivers = (id: number) => {
    if (activeDrivers.includes(id))
      setActiveDrivers((prev) => prev.filter((driver) => driver !== id));
    else setActiveDrivers((prev) => [...prev, id]);
  };

  const removedDriversArr = currentDriversId?.filter(
    (driver) => !activeDrivers.includes(driver)
  );
  const addedDriversArr = activeDrivers?.filter(
    (driver) => !currentDriversId?.includes(driver)
  );

  const handleSetDrivers = async () => {
    if (addedDriversArr.length) {
      await setDrivers({ employees: addedDriversArr, companyId, vehicleId });
    }
    if (removedDriversArr && removedDriversArr.length) {
      await deleteDrivers({
        employees: removedDriversArr,
        companyId,
        vehicleId,
      });
    }

    setEditDrivers();
  };

  const aviableDrivers = drivers?.filter(
    (driver) =>
      driver.invite?.confirmed_at ||
      currentDrivers?.find((currentDriver) => currentDriver.id === driver.id)
  );

  return (
    <ShadowContainer>
      <Title className="mb-[25px]">Водители</Title>
      <QueryLayout
        isLoading={isLoading}
        isFetching={isFetching}
        error={error || settingDriversError || deletingDriversError}
      >
        {drivers && (
          <>
            <div className="sticky top-0">
              <Input
                className="mb-[15px] mt-[5px] w-full"
                placeholder="Поиск"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <List className="mb-[30px]">
              {aviableDrivers?.length ? (
                aviableDrivers?.map((driver) => (
                  <li key={driver.id}>
                    <CustomInput
                      type="checkbox"
                      className="py-[10px]"
                      checked={activeDrivers.includes(driver.id)}
                      onClick={() => handlerSetActiveDrivers(driver.id)}
                      innerelement={
                        <EmployeeListItem
                          img={driver.user && driver.user.avatar?.path}
                          name={
                            driver.user?.first_name
                              ? `${driver.user.last_name} ${driver.user.first_name}`
                              : String(driver.invite?.phone)
                          }
                          rating={driver.user?.rate}
                          role={driver.role.name}
                        />
                      }
                    />
                  </li>
                ))
              ) : (
                <NotFoundTitle />
              )}
            </List>
            <div className="grid grid-cols-2 gap-[15px]">
              <Button mode="darkBorder" onClick={setEditDrivers}>
                Назад
              </Button>
              <Button
                onClick={handleSetDrivers}
                disabled={
                  isSettingDriversLoading ||
                  isDeletingDriversLoading ||
                  (!removedDriversArr?.length && !addedDriversArr?.length)
                }
              >
                Сохранить
              </Button>
            </div>
          </>
        )}
      </QueryLayout>
    </ShadowContainer>
  );
};
