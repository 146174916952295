import { FC, memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { Dropdown } from "../../../common/components/Dropdown/Dropdown";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { OrderItemDetails } from "../../../common/components/Order/OrderItemDetails";
import { TextArea } from "../../../common/components/TextArea/TextArea";
import { Title } from "../../../common/components/Title/Title";
import { Cause } from "../../../common/components/Cause/Cause";
import {
  setAvtiveOrderId,
  setCancelOrdersDateFrom,
  setCancelOrdersDateTo,
} from "./cancelOrderSlice";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { List } from "../../../common/components/List/List";
import { OrderList } from "../../../common/components/Order/OrderList";
import { ORDER_STATUS } from "common/utils/consts";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";
import { useGetOrderByIdQuery } from "services/orderApi";
import { IOrder } from "common/models/order/IOrder";

export const CancelOrdersPage: FC = withErrorBoundary(
  memo(() => {
    const activeOrderId = useAppSelector(
      (state) => state.platformManager.cancelOrder.activeOrderId
    );
    const orderDateFrom = useAppSelector(
      (state) => state.platformManager.cancelOrder.cancelOrdersDateFrom
    );
    const ordersDateTo = useAppSelector(
      (state) => state.platformManager.cancelOrder.cancelOrdersDateTo
    );

    const dispatch = useAppDispatch();

    const {
      data: order,
      isLoading,
      error,
    } = useGetOrderByIdQuery(
      { id: activeOrderId || -1 },
      { skip: !activeOrderId }
    );

    const handleSetActiveDisputeId = (order: IOrder) => {
      dispatch(setAvtiveOrderId(order.id));
    };

    const handleDisputesDateFromChange = (date: Nullable<Date>) => {
      dispatch(setCancelOrdersDateFrom(date?.toISOString() || undefined));
    };

    const handleDisputesDateToChange = (date: Nullable<Date>) => {
      dispatch(setCancelOrdersDateTo(date?.toISOString() || undefined));
    };

    return (
      <>
        <div className="grid grid-rows-[40px,1fr] gap-[10px] overflow-y-hidden">
          <div>
            <DatePicker
              onStartDateChange={handleDisputesDateFromChange}
              onEndDateChange={handleDisputesDateToChange}
              placeholderText="Введите промежуток дат"
              isClearable={true}
            />
          </div>
          <List className="mt-[10px] overflow-y-scroll">
            <OrderList
              filter={{
                statusId: [ORDER_STATUS.REJECTED],
                dateFrom: orderDateFrom,
                dateTo: ordersDateTo,
              }}
              activeOrderId={activeOrderId}
              onItemClick={handleSetActiveDisputeId}
              withAddresses
            />
          </List>
        </div>
        {activeOrderId ? (
          <>
            <div className="overflow-y-scroll">
              <ShadowContainer>
                <Dropdown title="Детали заказа" isOpen>
                  <OrderItemDetails readOnly orderId={activeOrderId} />
                </Dropdown>
              </ShadowContainer>
            </div>
            {order && (
              <div className="overflow-y-scroll">
                <Title className="mb-[15px] flex items-center">
                  Причина отмены
                </Title>
                <ul className="mb-[15px] flex flex-wrap">
                  {order.cancel_reason
                    ? order.cancel_reason.map((reason) => (
                        <li key={reason.id}>
                          <Cause>{reason.name}</Cause>
                        </li>
                      ))
                    : "Нет"}
                </ul>
                <Title className="mb-[15px] flex items-center">
                  Комментарий пользователя
                </Title>
                <TextArea className="mb-[25px] h-full min-h-[220px] w-full resize-none">
                  {order.cancel_message || "Нет сообщения"}
                </TextArea>
              </div>
            )}
          </>
        ) : (
          <InformationBlock className="col-span-2 w-full" />
        )}
      </>
    );
  })
);
