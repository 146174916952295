import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { IndividualSidebar } from "features/individual/sidebar/IndividualSidebar";
import { IndividualHeader } from "features/individual/header/IndividualHeader";
import { AboutServicePage } from "pages/faq/aboutService/AboutServicePage";
import { FaqPage } from "pages/faq/faq/FaqPage";
import { UseRulesPage } from "pages/faq/useRules/UseRulesPage";
import { PoliticsPage } from "pages/faq/politics/PoliticsPage";
import { OrdersPage } from "features/individual/orders/OrdersPage";
import { ProfilePage } from "features/user/profile/ProfilePage";
import { HomePage } from "features/individual/home/HomePage";
import { TransactionsPage } from "features/individual/transactions/TransactionsPage";
import { AppealPage } from "features/individual/appeal/AppealPage";
import { APPLICATIONS } from "common/utils/consts";
import { BankCardsPage } from "features/individual/bankCards/BankCardsPage";
import { CompaniesPage } from "features/individual/companies/CompaniesPage";
import { useAppSelector } from "app/hooks";
import { Map } from "common/components/Map/Map";

export const IndividualPage: FC = () => {
  const route = useAppSelector((state) => state.individual.map.route);

  return (
    <>
      <IndividualSidebar />
      <div className="h-full w-full">
        <IndividualHeader />
        <main className="relative h-full min-h-[calc(100vh-90px)]">
          <Routes>
            <Route path="" element={<HomePage />} />
            <Route path="/about" element={<AboutServicePage />} />
            <Route
              path="/faq"
              element={<FaqPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route
              path="/use-rules"
              element={<UseRulesPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route
              path="/privacy-policy"
              element={<PoliticsPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/companies" element={<CompaniesPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/transactions" element={<TransactionsPage />} />
            <Route path="/support" element={<AppealPage />} />
            <Route path="/bank-cards" element={<BankCardsPage />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
          <Map route={route} />
        </main>
      </div>
    </>
  );
};
