import { ICauseProps } from "common/models/components/ui/ICauseProps";
import { FC, memo } from "react";

export const Cause: FC<ICauseProps> = memo((props) => {
  const userClasses = props.className || "";

  const specClass = props.primary
    ? "bg-[#FFA321] text-[#FFFFFF] border-[#FFA321]"
    : "bg-whiteGray text-current";

  const hoverClass = props.hover || ""

  return (
    <div
      {...props}
      className={`border border-whiteGray mr-[10px] mb-[10px] whitespace-nowrap rounded py-[7px] px-[17px] text-center text-[11px] font-medium transition ${specClass} ${userClasses} ${hoverClass}`}
    >
      {props.children}
    </div>
  );
});
