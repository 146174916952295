import { FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { SvgLogo } from "../../../assets/logo/SvgLogo";
import { implementerCompanyManagerRoutesLinks } from "../../../pages/roles/ImplementerCompanyManagerPage";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import { SidebarLinkItem } from "common/components/Navigation/LinkItem";
import {MODAL_TYPES} from "../../../common/utils/consts";
import {useDetachOrderCompanyMutation} from "../../../services/orderApi";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { setReservedOrder } from "../orders/ordersSlice";
import { resetAssignment } from "../requests/requestsSlice";

export const ImplementerCompanyManagerSidebar: FC = () => {
      const currentCompany = useAppSelector(
        (state) => state.user.current?.account?.company
      );

    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const { showModal } = useGlobalModalContext();

    const reservedOrder = useAppSelector(
        (state) => state.implementerCompanyManager.orders.reservedOrder);

    const [detachOrder] =
        useDetachOrderCompanyMutation();

    const handleNavigation = (e : any, link : string) => {
        if (reservedOrder) {
            e.preventDefault();
            showModal(MODAL_TYPES.CONFIRM_MODAL, {
                subTitle: "Вы уверены что хотите покинуть страницы?",
                onConfirm: () => {
                    detachOrder(reservedOrder.id)
                    dispatch(setReservedOrder(undefined))
                    dispatch(resetAssignment())
                    navigate(link)
                },
            });
        }
    };

  return (
    <aside className="z-10 flex w-[90px] flex-col items-center bg-lightWhiteGray">
      <NavLink
        onClick={(e) => {
            handleNavigation(e, '/')
        }}
        to="/"
        className="flex w-full items-center justify-center pt-[15px] pb-[18px] text-primary hover:text-black focus:text-black active:text-primary"
      >
        <SvgLogo className="h-[34px] w-[34px] transition" />
      </NavLink>
      {implementerCompanyManagerRoutesLinks.map((route) =>
        !currentCompany && route.isCompanyRequired ? null : (
          <SidebarLinkItem
            key={route.path}
            to={route.path}
            label={route.label}
            icon={route.Icon}
            onClick={(e) => {
                handleNavigation(e, route.path)
            }}
          />
        )
      )}
    </aside>
  );
};
