import { FC } from "react";
import { List } from "../../../common/components/List/List";
import { CustomInput } from "../../../common/components/Input/CustomInput";
import { VehicleListItem } from "../../../common/components/Vehicle/VehicleListItem";
import { IVehicle } from "../../../common/models/vehicles/IVehicle";

export const VehicleList: FC<{
  vehicles: IVehicle[];
  onItemClick: ({ id, categoryId }: { id: number; categoryId: number }) => void;
  activeVehicleId?: number;
}> = ({ vehicles, activeVehicleId, onItemClick }) => {
  return (
    <List>
      {vehicles.map((vehicle) => (
        <li key={vehicle.id}>
          <CustomInput
            type="radio"
            className="py-[10px]"
            checked={vehicle.id === activeVehicleId}
            onClick={() =>
              onItemClick({
                id: vehicle.id,
                categoryId: vehicle.vehicle_category.id,
              })
            }
            innerelement={
              <VehicleListItem
                name={vehicle.vehicle_category.name}
                registrationNumber={vehicle.vehicle_number}
                companyName={vehicle.company?.name}
                desc={vehicle.vehicle_type?.name || "Ещё не назначен тип"}
                img={vehicle.vehicle_category.icon.path}
              />
            }
          />
        </li>
      ))}
    </List>
  );
};
