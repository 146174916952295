import { FC } from "react";
import { useGetAllAppealsQuery } from "services/appealApi";
import { SvgPlus } from "assets/icons/SvgPlus";
import { Input } from "common/components/Input/Input";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { WideIconButton } from "common/components/Button/WideIconButton";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";
import { AppealListGenerator } from "./AppealListGenerator";
import { IExtendedAppealListProps } from "common/models/components/appeal/IExtendedAppealListProps";

export const AppealList: FC<IExtendedAppealListProps> = ({
  onItemClick,
  onSearch,
  onAdd,
  userId,
  searchText,
  activeItemId,
  statusId,
}) => {
  const {
    data: appeals,
    isLoading: isAppealsLoading,
    isFetching: isAppealsFetching,
    error: appealsError,
  } = useGetAllAppealsQuery({
    statusId: statusId || undefined,
    userId,
  });

  const handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <>
      <div className="mb-[15px] flex items-center justify-between">
        <h4 className="text-md font-semibold">Обращения</h4>
        {!!appeals?.length && (
          <button
            className="text-primary outline-none transition hover:text-black focus:text-black active:text-primary"
            onClick={onAdd}
          >
            <SvgPlus className="h-[20px] w-[20px]" />
          </button>
        )}
      </div>
      <div className="sticky top-0">
        <Input
          className="mb-[15px] mt-[5px] w-full"
          placeholder="Введите минимум 3 символа"
          value={searchText}
          onChange={handleOnSearch}
        />
      </div>
      <QueryLayout isLoading={isAppealsLoading} error={appealsError}>
        {!appeals?.length && !searchText && !statusId ? (
          <WideIconButton
            className="w-full text-sm font-medium text-gray"
            icon={<SvgPlus className="h-[16px] w-[16px] text-primary" />}
            onClick={onAdd}
          >
            У вас ещё ни одного обращения
          </WideIconButton>
        ) : (
          !appeals?.length && <NotFoundTitle />
        )}
        {appeals && (
          <AppealListGenerator
            appeals={appeals}
            onItemClick={onItemClick}
            activeAppealId={activeItemId}
          />
        )}
      </QueryLayout>
    </>
  );
};
