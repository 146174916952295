import { FC, memo } from "react";
import { Button } from "../../../common/components/Button/Button";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { Title } from "../../../common/components/Title/Title";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { AppealsTabs } from "./AppealsTabs";
import { Chat } from "../../../common/components/Chat/Chat";
import { UserInformation } from "../../../common/components/User/UserInformation";
import { useGetAppealByIdQuery } from "../../../services/appealApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { setActiveAppealId } from "./desktopSlice";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const DesktopPage: FC = withErrorBoundary(
  memo(() => {
    const activeAppealId = useAppSelector(
      (state) => state.platformManager.desktop.activeAppealId
    );

    const {
      data: appeal,
      isLoading: isAppealLoading,
      isFetching: isAppealFetching,
      error: appealError,
      refetch,
    } = useGetAppealByIdQuery(activeAppealId || -1, {
      skip: !activeAppealId,
    });

    const dispatch = useAppDispatch();

    const handleCloseAppeal = () => {
      dispatch(setActiveAppealId(undefined));
    };

    const { showModal } = useGlobalModalContext();

    const showBanModal = () => {
      showModal(MODAL_TYPES.BAN_MODAL, {
        userId: appeal?.user.id,
        isBanned: appeal?.user.is_banned,
        unbannedAt: new Date(),
        onConfirm: () => refetch(),
      });
    };

    const showBonusesModal = () => {
      showModal(MODAL_TYPES.BONUSES_MODAL, {
        userId: appeal?.user?.id,
      });
    };

    const showReportModal = () => {
      showModal(MODAL_TYPES.REPORT_MODAL);
    };

    return (
      <>
        <div className="grid grid-rows-[35px,40px,1fr] gap-[10px] overflow-y-hidden">
          <AppealsTabs />
        </div>
        <QueryLayout
          isLoading={isAppealLoading}
          isFetching={isAppealFetching}
          error={appealError}
        >
          {appeal && activeAppealId ? (
            <>
              <div className="grid grid-rows-[40px,minmax(400px,1fr),75px] gap-[10px] overflow-y-hidden">
                <Title className="flex items-center">Чат с пользователем</Title>
                <Chat appealId={appeal.id} />
              </div>
              <div className="grid grid-rows-[40px,90px,1fr,225px] gap-[10px] overflow-y-hidden">
                <Title className="flex items-center">
                  Информация о пользователе
                </Title>
                <UserInformation userId={appeal.user.id} />
                <div className="mb-[15px] grid gap-[10px]">
                  <Button onClick={showBanModal} className="max-h-[45px]">
                    {appeal.user.is_banned
                      ? "Разблокировать пользователя"
                      : "Заблокировать пользователя"}
                  </Button>
                  <Button onClick={showBonusesModal} className="max-h-[45px]">
                    Начислить баллы
                  </Button>
                  <Button className="max-h-[45px]" onClick={handleCloseAppeal}>
                    Закрыть тикет
                  </Button>
                  <Button
                    className="max-h-[45px]"
                    mode="darkBorder"
                    onClick={showReportModal}
                  >
                    Отправить разработчику
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <InformationBlock className="col-span-2" />
          )}
        </QueryLayout>
      </>
    );
  })
);
