import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgHome: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59924 11.9996V8.99953C8.59924 8.86693 8.54657 8.73975 8.4528 8.64598C8.35903 8.55221 8.23185 8.49953 8.09924 8.49953H6.09924C5.96663 8.49953 5.83946 8.55221 5.74569 8.64598C5.65192 8.73975 5.59924 8.86693 5.59924 8.99953V11.9996C5.59924 12.1322 5.54657 12.2594 5.45282 12.3531C5.35906 12.4469 5.2319 12.4996 5.09931 12.4996L2.09967 12.5C2.03401 12.5 1.96898 12.4871 1.90831 12.4619C1.84764 12.4368 1.79251 12.4 1.74608 12.3536C1.69964 12.3071 1.66281 12.252 1.63768 12.1913C1.61254 12.1307 1.59961 12.0657 1.59961 12V6.22122C1.59961 6.15156 1.61417 6.08267 1.64234 6.01896C1.67052 5.95525 1.7117 5.89814 1.76324 5.85128L6.7629 1.30535C6.85493 1.22167 6.97486 1.1753 7.09925 1.17529C7.22364 1.17529 7.34357 1.22165 7.43561 1.30533L12.436 5.85127C12.4875 5.89814 12.5287 5.95525 12.5569 6.01896C12.5851 6.08267 12.5996 6.15157 12.5996 6.22124V12C12.5996 12.0657 12.5867 12.1307 12.5615 12.1914C12.5364 12.252 12.4996 12.3071 12.4531 12.3536C12.4067 12.4 12.3516 12.4368 12.2909 12.462C12.2302 12.4871 12.1652 12.5 12.0995 12.5L9.09918 12.4996C8.96658 12.4996 8.83942 12.4469 8.74567 12.3531C8.65191 12.2594 8.59924 12.1322 8.59924 11.9996V11.9996Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
