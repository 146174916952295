import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Title } from 'common/components/Title/Title';
import { List } from 'common/components/List/List';
import { MODAL_TYPES } from 'common/utils/consts';
import { useGlobalModalContext } from 'common/components/Modal/GlobalModal';
import { SvgTrash } from 'assets/icons/SvgTrash';
import { setPhotos } from './orderingSlice';

export const LoadedImages: FC = () => {
  const photos = useAppSelector((state) => state.ordering.photos)

  const photoArr = photos && photos.map((file) => URL.createObjectURL(file))

  const { showModal } = useGlobalModalContext()
  const dispatch = useAppDispatch()

  const showConfirmationModal = (index: number) => {
    if (!photos) return;

    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      title: 'Удалить картинку',
      subTitle: 'Вы уверены что хотите удалить картинку?',
      confirmText: 'Картинка успешно удалена',
      onConfirm: () => {
        let newPhotoArr = [...photos];
        newPhotoArr.splice(index, 1);
        dispatch(setPhotos(newPhotoArr.length ? newPhotoArr : undefined));
      }
    })
  }

  return (
    <div className='pointer-events-auto flex h-fit max-h-[100%] max-w-[400px] flex-col overflow-y-auto rounded bg-white p-[15px]'>
      <Title className='mb-[15px] text-center'>Загруженные фото</Title>
      <List>
        {photoArr?.map((image, index) => (
          <div key={index} className='mb-[15px]'>
            <div className='h-[222px]'>
              <img
                className='h-full w-full rounded object-cover'
                src={image}
                alt='current'
              />
            </div>
            <div
              className='mt-[-237px] flex h-[222px] cursor-pointer flex-wrap content-center justify-center hover:bg-slate-50 hover:bg-opacity-50'
              onClick={() => { showConfirmationModal(index) }}
            >
              <button
                type='button'
                className='h-[46px] w-[46px] rounded bg-primary p-[8px] text-white transition
                  hover:bg-white hover:text-red focus:bg-white focus:text-red active:bg-primary active:text-white'
                onClick={() => { showConfirmationModal(index) }}
              >
                <SvgTrash className='h-[30px] w-[30px]' />
              </button>
            </div>
          </div>
        ))}
      </List>
    </div>
  )
}
