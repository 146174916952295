import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgClipboardText: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5996 1.5H5.59961V4H10.5996V1.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5996 2.5H12.5996C12.7322 2.5 12.8594 2.55268 12.9532 2.64645C13.0469 2.74021 13.0996 2.86739 13.0996 3V13.5C13.0996 13.6326 13.0469 13.7598 12.9532 13.8536C12.8594 13.9473 12.7322 14 12.5996 14H3.59961C3.467 14 3.33982 13.9473 3.24606 13.8536C3.15229 13.7598 3.09961 13.6326 3.09961 13.5V3C3.09961 2.86739 3.15229 2.74021 3.24606 2.64645C3.33982 2.55268 3.467 2.5 3.59961 2.5H5.59961"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.09961 9.5H10.0996"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.09961 7.5H10.0996"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
