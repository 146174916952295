import { FC, useEffect, useState, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { MaskedInput } from "../../../common/components/Input/MaskedInput";
import { PhoneVerificationInput } from "../../../common/components/Input/PhoneVerificationInput";
import { LoginFormValues } from "../../../common/models/forms/LoginFormValues";
import { phoneStrTransform } from "../../../common/utils/helpers";
import {
  useLoginUserMutation,
  useSendCodeMutation,
} from "../../../services/authApi";
import { login as setUser } from "../../user/userSlice";
import { SvgArrowInCircle } from "../../../assets/icons/SvgArrowInCircle";

export const Login: FC = () => {
  const [
    login,
    {
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      isLoading: isLoginLoading,
      data: userData,
      reset,
    },
  ] = useLoginUserMutation();
  const [
    sendCode,
    {
      isSuccess: isSendCodeSuccess,
      isError: isSendCodeError,
      isLoading: isSendCodeLoading,
    },
  ] = useSendCodeMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isVerificationCodeOpen, setIsVerificationCodeOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const intervalID: any = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<LoginFormValues>();

  const [otp, setOtp] = useState<any>(undefined);

  useEffect(() => {
    setValue("code", otp);
  }, [otp, setValue]);

  useEffect(() => {
    if (!isVerificationCodeOpen) {
      setOtp(undefined);
    }
  }, [isVerificationCodeOpen]);

  useEffect(() => {
    if (isSendCodeSuccess) {
      setIsVerificationCodeOpen(true);
      startTimer();
    }
  }, [isSendCodeSuccess]);

  useEffect(() => {
    if (isLoginSuccess && userData) {
      dispatch(setUser(userData));
      navigate("/");
      stopTimer();
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    if (timeLeft === 0) {
      stopTimer();
    }
  }, [timeLeft])

  const onSubmit = handleSubmit((data) => {
    const loginData = {
      phone: phoneStrTransform(data.phone),
      code: data.code,
    };
    if (!isVerificationCodeOpen) {
      sendCode(loginData);
    } else {
      login(loginData);
    }
  });

  const requestNewCode = () => {
    sendCode({ phone: phoneStrTransform(getValues("phone")) });
    startTimer();
  }

  const startTimer = useCallback(() => {
    if (intervalID) {
      stopTimer();
    }
    setTimeLeft(60);
    intervalID.current = setInterval(() => {
      setTimeLeft(timeLeft => timeLeft - 1);
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    clearInterval(intervalID.current);
    intervalID.current = null;
  }, []);

  const verificationError = !!errors.code || isLoginError;
  const codeSendingError = !!errors.phone || isSendCodeError;

  return (
    <>
      <form onSubmit={onSubmit}>
        {isVerificationCodeOpen ? (
          <div className="mt-[-40px] w-full">
            <button
              type="button"
              className="relative top-[-90px] left-[0px] z-10 h-[32px] w-[32px] rotate-180 text-black
            outline-none transition hover:text-primary focus:text-primary active:text-black"
              onClick={() => {
                setIsVerificationCodeOpen(false);
                reset();
              }}
            >
              <SvgArrowInCircle className="text-inherit" />
            </button>
            <label>
              <span
                className={`mb-[12px] block text-sm font-semibold ${
                  verificationError ? "text-red" : "text-gray"
                }`}
              >
                Код из СМС
              </span>
              <PhoneVerificationInput
                {...register("code", { required: true, minLength: 4 })}
                value={otp}
                containerStyle="w-full justify-around"
                shouldAutoFocus={true}
                inputStyle={{
                  width: 55,
                  height: 55,
                  backgroundColor: "#F9F9F9",
                  borderRadius: 5,
                  border: verificationError ? "1px solid red" : "",
                  fontSize: 18,
                  outlineColor: '#4BD035'
                }}
                isInputNum
                isInputSecure
                onChange={(number: string) => setOtp(number || undefined)}
                numInputs={4}
              />
            </label>
            {verificationError && (
              <div className="mt-[15px] text-center text-sm text-red">
                Неверный код. Попробуйте ещё раз.
              </div>
            )}
            <div className="mt-[10px] mb-[25px] text-center text-xs text-graySecond">
              Запросите код повторно через <span className="text-primary">0:{timeLeft}</span>.
            </div>
            <Button
              className="mb-[16px] w-full disabled"
              mode="darkBorder"
              type="button"
              disabled={timeLeft > 0}
              onClick={() => requestNewCode()}
            >
              Получить новый код
            </Button>
          </div>
        ) : (
          <div className="mb-[15px] w-full">
            <label>
              <MaskedInput
                {...register("phone", {
                  required: true,
                  validate: (value) => phoneStrTransform(value).length === 11,
                })}
                invalid={codeSendingError}
                label="Телефон"
                className="w-full"
                type="tel"
                mask="+7 (999) 999 99-99"
                placeholder="Введите телефон"
              />
            </label>

            {codeSendingError && (
              <span className="mt-[5px] text-xs text-red">
                Указанный телефон отсутствует в системе
              </span>
            )}

            <div className="mt-[10px] mb-[25px] text-center text-xs text-graySecond">
              Нажимая кнопку, вы подтверждаете свое согласие на
              <br />
              <NavLink
                target="_blank"
                rel="noopener noreferrer"
                to="/privacy-policy"
                className="cursor-pointer text-black"
              >
                обработку персональных данных.
              </NavLink>
            </div>
          </div>
        )}
        <Button
          className="w-full mb-[25px]"
          type="submit"
          disabled={isSendCodeLoading || isLoginLoading}
          loader={isLoginLoading}
        >
          Войти
        </Button>
      </form>
      <div className="flex items-center justify-between">
        <NavLink
          to="registration"
          className="text-base font-semibold text-black"
        >
          Зарегистрироваться
        </NavLink>
        <NavLink to="/" className="text-base font-semibold text-black">
          Войти как гость
        </NavLink>
      </div>
    </>
  );
};
