import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgReload: FC<ISvgProps> = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9999 4.86276V7.93974C20.0028 8.14456 19.923 8.34167 19.7779 8.48688C19.6328 8.63111 19.4359 8.71188 19.2312 8.709C19.1495 8.72342 19.0668 8.72342 18.9851 8.709L15.9101 7.68301C15.7102 7.62339 15.5421 7.48493 15.446 7.29935C15.3499 7.11377 15.3335 6.89742 15.4008 6.69934C15.4671 6.50126 15.6122 6.33876 15.8006 6.24933C15.9899 6.16087 16.207 6.15222 16.4021 6.22626L17.7244 6.66762C16.6635 4.25507 14.535 2.478 11.9731 1.86654C9.41022 1.25499 6.70984 1.88 4.67465 3.55504C2.64033 5.22912 1.50736 7.76091 1.61216 10.3946C1.71787 13.0282 3.04975 15.461 5.21094 16.9677C7.37309 18.4745 10.1148 18.8812 12.6208 18.0668C15.1261 17.2523 17.1054 15.312 17.9704 12.8214C18.0271 12.6156 18.1664 12.4416 18.3557 12.3435C18.545 12.2445 18.767 12.2291 18.9688 12.3002C19.1697 12.3714 19.3321 12.5233 19.4176 12.7195C19.5021 12.9156 19.5012 13.1387 19.4157 13.3339C18.4009 16.2051 16.134 18.4569 13.257 19.4514C10.38 20.4466 7.20775 20.0745 4.63824 18.4427C2.06874 16.811 0.382141 14.0956 0.0574169 11.0676C-0.268341 8.03959 0.804086 5.02808 2.96813 2.88774C5.13315 0.746354 8.15437 -0.29017 11.1765 0.0704524C14.1987 0.430065 16.8922 2.14933 18.4931 4.73983C18.5441 4.3706 18.8583 4.09463 19.2312 4.09367C19.653 4.09847 19.9942 4.43966 19.9999 4.86276Z"
        fill="black"
      />
    </svg>
  );
};
