import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ORDER_STEP } from "../../common/utils/consts";
import { OrderingStepTypes } from "../../common/models/types/OrderingStepTypes";
import { PaymentMethod } from "common/models/types/PaymentMethod";
import { IAddress } from "common/models/order/IAddress";
import { IManipulatorCharacteristics } from "common/models/commonModels/IManipulatorCharacteristics";

export type OrderingModeType = "manual" | "manualVehicleChoosing" | "manager";

export interface OrderingState {
  orderStep: OrderingStepTypes;
  createdOrderId?: number;
  vehicleCategoryId?: number;
  vehicleTypeId?: number;
  paymentMethod: PaymentMethod;
  bankCardId?: number;
  orderingMode: OrderingModeType;
  isCodeSended?: boolean;
  isReloadActive?: boolean;
  manualVehicleCharacteristics: {
    sideLoadCapacity?: number;
    boomCapacity?: number;
    boomLength?: number;
    optionalEquipment?: { id: string; price: number }[];
  };
  useBonuses: boolean;
  address: IAddress[];
  vehicleDeliveryDay?: string;
  vehicleDeliveryTime?: number;
  description?: string;
  photos?: Array<File>;
  price?: number;
  user: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    phone?: string;
  };
}

const initialState: OrderingState = {
  orderStep: ORDER_STEP.VEHICLE_CATEGORY_CHOOSE.value,
  user: {},
  manualVehicleCharacteristics: {},
  orderingMode: "manual",
  useBonuses: false,
  vehicleCategoryId: undefined,
  paymentMethod: "cash",
  vehicleDeliveryDay: undefined,
  vehicleTypeId: undefined,
  address: [],
  vehicleDeliveryTime: undefined,
  description: undefined,
  photos: undefined,
};

const orderingSlice = createSlice({
  name: "ordering",
  initialState,
  reducers: {
    setOrderState(state, action: PayloadAction<OrderingStepTypes>) {
      state.orderStep = action.payload;
    },
    setIsCodeSended(state, action: PayloadAction<boolean>) {
      state.isCodeSended = action.payload;
    },
    setIsReloadActive(state, action: PayloadAction<boolean>) {
      state.isReloadActive = action.payload
    },
    setCreatedOrderId(state, action: PayloadAction<number>) {
      state.createdOrderId = action.payload;
    },
    nextOrderStep(state) {
      const orderStepsArr = Object.getOwnPropertyNames(ORDER_STEP);
      const currentOrderStepIndex = orderStepsArr.indexOf(state.orderStep);
      state.orderStep = orderStepsArr[
        currentOrderStepIndex + 1
      ] as OrderingStepTypes;
    },
    prevOrderStep(state) {
      const orderStepsArr = Object.getOwnPropertyNames(ORDER_STEP);
      const currentOrderStepIndex = orderStepsArr.indexOf(state.orderStep);
      state.orderStep = orderStepsArr[
        currentOrderStepIndex - 1
      ] as OrderingStepTypes;
    },
    setCardId(state, action: PayloadAction<Undefinable<number>>) {
      state.bankCardId = action.payload;
    },
    setOptionalEquipment(
      state,
      action: PayloadAction<{ id: string; price: number }>
    ) {
      if (
        state.manualVehicleCharacteristics.optionalEquipment?.find(
          (option) => option.id === action.payload.id
        )
      ) {
        state.manualVehicleCharacteristics.optionalEquipment = [
          ...state.manualVehicleCharacteristics.optionalEquipment,
          action.payload,
        ];
      } else
        state.manualVehicleCharacteristics.optionalEquipment =
          state.manualVehicleCharacteristics.optionalEquipment?.filter(
            (option) => option.id !== action.payload.id
          );
      state.price = state.price || 0 + action.payload.price;
    },
    setVehicleCategoryId(state, action: PayloadAction<number>) {
      state.vehicleCategoryId = action.payload;
    },
    setPaymentMethod(state, action: PayloadAction<PaymentMethod>) {
      state.paymentMethod = action.payload;
      state.bankCardId = undefined;
    },
    setUseBonuses(state, action: PayloadAction<boolean>) {
      state.useBonuses = action.payload;
    },
    setOrderingMode(state, action: PayloadAction<OrderingModeType>) {
      if (state.orderingMode !== action.payload) {
        state.vehicleTypeId = undefined;
        state.price = undefined;
      }
      state.orderingMode = action.payload;
    },
    setManualVehicleCharacteristics(
      state,
      action: PayloadAction<IManipulatorCharacteristics>
    ) {
      state.manualVehicleCharacteristics = action.payload;
    },
    setVehicleTypeId(state, action: PayloadAction<Undefinable<number>>) {
      state.vehicleTypeId = action.payload;
    },
    addAddress(
      state,
      action: PayloadAction<{
        latitude: number;
        longitude: number;
        name: string;
        id: string;
      }>
    ) {
      state.address = state.address?.length
        ? [
            ...state.address,
            { ...action.payload, id: action.payload.id, is_start: false },
          ]
        : [{ ...action.payload, id: action.payload.id, is_start: true }];
    },
    changeAddress(
      state,
      action: PayloadAction<{
        latitude: number;
        longitude: number;
        name: string;
        id: string;
      }>
    ) {
      state.address = [
        ...state.address.map((address) =>
          address.id === action.payload.id
            ? (action.payload as IAddress)
            : address
        ),
      ];
    },
    removeAddress(state, action: PayloadAction<string>) {
      state.address = state.address?.filter(
        (address) => address.id !== action.payload
      );
      if (state.address?.length) {
        state.address[0].is_start = true;
      }
    },
    changeAddressPosition(
      state,
      action: PayloadAction<{
        currentIndex: number;
        newPositionIndex: number;
      }>
    ) {
      const currentEl = state.address[action.payload.currentIndex];
      const newEl = state.address[action.payload.newPositionIndex];

      state.address[action.payload.currentIndex] = newEl;
      state.address[action.payload.newPositionIndex] = currentEl;
    },
    setDescriptionText(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    addPhotos(state, action: PayloadAction<Array<File>>) {
      if (state.photos) {
        state.photos = state.photos.concat(action.payload);
      } else {
        state.photos = action.payload;
      }
    },
    setPhotos(state, action: PayloadAction<Array<File> | undefined>) {
      state.photos = action.payload;
    },
    setFirstName(state, action: PayloadAction<string>) {
      state.user.firstName = action.payload;
    },
    setLastName(state, action: PayloadAction<string>) {
      state.user.lastName = action.payload;
    },
    setMiddleName(state, action: PayloadAction<string>) {
      state.user.middleName = action.payload;
    },
    setPhone(state, action: PayloadAction<string>) {
      state.user.phone = action.payload;
    },
    setVehicleDeliveryDay(state, action: PayloadAction<Undefinable<string>>) {
      state.vehicleDeliveryDay = action.payload;
    },
    setVehicleDeliveryTime(state, action: PayloadAction<Undefinable<number>>) {
      state.vehicleDeliveryTime = action.payload;
    },
    setOrderPrice(state, action: PayloadAction<number>) {
      state.price = action.payload;
    },
    resetOrdering() {
      return initialState;
    },
  },
});

export const {
  setCreatedOrderId,
  setIsCodeSended,
  setIsReloadActive,
  nextOrderStep,
  prevOrderStep,
  setOrderingMode,
  setManualVehicleCharacteristics,
  setCardId,
  setOptionalEquipment,
  setVehicleCategoryId,
  setUseBonuses,
  setOrderState,
  setPaymentMethod,
  setMiddleName,
  setVehicleDeliveryDay,
  setVehicleDeliveryTime,
  setVehicleTypeId,
  addAddress,
  changeAddressPosition,
  changeAddress,
  removeAddress,
  setOrderPrice,
  setDescriptionText,
  addPhotos,
  setPhotos,
  setFirstName,
  setLastName,
  setPhone,
  resetOrdering,
} = orderingSlice.actions;

export const orderingReducer = orderingSlice.reducer;
