import { FC, memo, useMemo } from "react";
import { timeFormatter } from "../../utils/helpers";
import { DetailedUserProfileCard } from "../Profile/DetailedUserProfileCard";
import { ITransactionCardProps } from "common/models/components/ITransactionCardProps";

export const TransactionCard: FC<ITransactionCardProps> = memo(
  ({ transactionId, time, amount, desc, orderId, account, ...props }) => {
    const userClasses = props.className || "";

    const isPositiveAmount = amount > 0;

    const transactionDate = useMemo(
      () => timeFormatter({ time: time, mode: "dd-Mth-yyyy hh:mm" }),
      [time]
    );

    return (
      <div
        {...props}
        className={`flex flex-col rounded border border-whiteGray p-[15px] ${userClasses}`}
      >
        {account && (
          <div className="mb-[5px] flex rounded border border-whiteGray p-[8px]">
            <DetailedUserProfileCard
              className="w-full"
              status={"В обработке"}
              name={`${account?.last_name} ${account?.last_name}`}
              userId={account?.id}
              registrationDate={new Date()}
            />
          </div>
        )}
        <div
          className={`flex items-center justify-between text-base font-medium ${
            isPositiveAmount && "text-primary"
          }`}
        >
          <div className="mr-[10px] text-md">
            {`${isPositiveAmount && "+"} ${amount} руб.`}
          </div>
          <time className="text-xs text-lightGray">{transactionDate}</time>
        </div>
        <p className="text-left text-xs text-lightGray">{desc}</p>
      </div>
    );
  }
);
