import { FC, memo, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { EmployeeListItem } from "../../../common/components/Employee/EmployeeListItem";
import { CustomInput } from "../../../common/components/Input/CustomInput";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { List } from "../../../common/components/List/List";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { Title } from "../../../common/components/Title/Title";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { UserInformation } from "../../../common/components/User/UserInformation";
import { setAvtiveSearchItemId, setSearchText } from "./searchSlice";
import { useGetAllUsersQuery } from "../../../services/userApi";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const SearchPage: FC = withErrorBoundary(
  memo(() => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const firstPaint = useRef(false);

    const searchText = useAppSelector(
      (state) => state.platformManager.search.searchText
    );
    const activeSearchItemId = useAppSelector(
      (state) => state.platformManager.search.activeSearchItemId
    );

    const {
      data: users,
      isLoading: isUsersLoading,
      isFetching: isUsersFetching,
      error: usersError,
    } = useGetAllUsersQuery(searchText, {
      skip: searchText.length < 3,
    });

    const { showModal } = useGlobalModalContext();

    const showBanModal = () => {
      showModal(MODAL_TYPES.BAN_MODAL, {
        userId: activeSearchItemId,
      });
    };

    const showBonusesModal = () => {
      showModal(MODAL_TYPES.BONUSES_MODAL, {
        userId: activeSearchItemId,
      });
    };

    useEffect(() => {
      if (!firstPaint.current) {
        firstPaint.current = true;
        dispatch(setSearchText(searchParams.get("text") || ""));
      }
    }, []);

    useEffect(() => {
      dispatch(setSearchText(searchParams.get("text") || ""));
    }, [searchParams]);

    const handlesetActiveSearchItem = (id: number) => {
      dispatch(setAvtiveSearchItemId(id));
    };

    return (
      <>
        <div className="grid grid-rows-[35px,1fr] gap-[10px] overflow-y-hidden">
          <Title>Результат поиска</Title>
          <QueryLayout
            isLoading={isUsersLoading}
            isFetching={isUsersFetching}
            error={usersError}
          >
            <div className="overflow-y-scroll">
              {!users ? (
                <div>Ничего не найдено</div>
              ) : (
                <List>
                  {users.map((user) => (
                    <li key={user.id}>
                      <CustomInput
                        type="radio"
                        className="py-[10px]"
                        checked={user.id === activeSearchItemId}
                        onClick={() => handlesetActiveSearchItem(user.id)}
                        innerelement={
                          <EmployeeListItem
                            name={
                              user.first_name
                                ? `${user.first_name} ${user.first_name}`
                                : `# ${user.id}`
                            }
                            rating={5}
                            role={user.account.role.name}
                          />
                        }
                      />
                    </li>
                  ))}
                </List>
              )}
            </div>
          </QueryLayout>
        </div>
        {activeSearchItemId ? (
          <div className="grid grid-rows-[40px,90px,1fr,auto] gap-[10px] overflow-y-hidden">
            <Title className="flex items-center">
              Информация о пользователе
            </Title>
            <UserInformation userId={activeSearchItemId} extended />
            <div className="mb-[15px] grid gap-[10px]">
              <Button onClick={showBanModal} className="max-h-[45px]">
                Блокировка
              </Button>
              <Button
                mode="darkBorder"
                onClick={showBonusesModal}
                className="max-h-[45px]"
              >
                Начислить бонусы
              </Button>
            </div>
          </div>
        ) : (
          <InformationBlock className="col-span-2" />
        )}
      </>
    );
  })
);
