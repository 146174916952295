import { FC, useState } from "react";
import { List } from "../../../common/components/List/List";
import { Tabs } from "../../../common/components/Tabs/Tabs";
import { useGetAllTransactionsQuery } from "../../../services/transactionApi";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { NotFoundTitle } from "../../../common/components/Title/NotFoundTitle";
import { InvoiceCard } from "../invoice/InvoiceCard";
import { TransactionCard } from "../Transaction/TransactionCard";

export const UserTransactions: FC<{ userId: number }> = ({ userId }) => {
  const {
    data: transactions,
    isLoading,
    isFetching,
    error,
  } = useGetAllTransactionsQuery({ userId });

  const [value, setActiveTab] = useState<"all" | "replenishment" | "writeOff">(
    "all"
  );

  return (
    <div>
      <Tabs
        className="mb-[12px]"
        tabs={[
          { value: "all", label: "Все" },
          { value: "replenishment", label: "Пополнение" },
          { value: "writeOff", label: "Списание" },
        ]}
        value={value}
        onTabClick={setActiveTab}
      />
      <QueryLayout isLoading={isLoading} isFetching={isFetching} error={error}>
        {transactions?.length ? (
          <List>
            {transactions.map((el) => (
              <TransactionCard
                key={el.id}
                transactionId={el.id}
                time={new Date(el.created_at)}
                account={el.account}
                amount={el.order?.price || el.debit || 0}
                desc={el.description}
              />
            ))}
          </List>
        ) : (
          <NotFoundTitle />
        )}
      </QueryLayout>
    </div>
  );
};
