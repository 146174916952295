import { useAppDispatch, useAppSelector } from "app/hooks";
import { ILongLatAddress } from "common/models/commonModels/ILongLatAddress";
import { setLocation } from "features/user/userSlice";
import { FC, memo, useEffect } from "react";

export const Map: FC<{ route?: ILongLatAddress[] }> = memo(({ route }) => {
  const dispatch = useAppDispatch();

  const userLocation = useAppSelector((state) => state.user.location);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) =>
      dispatch(
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      )
    );
  }, []);

  const mapSrc = route
    ? route.length > 1
      ? `https://yandex.ru/map-widget/v1/?mode=routes&rtext=${route?.reduce(
          (acc, curr, i) =>
            (acc += `${curr.latitude}%2C${curr.longitude}${
              i !== route.length - 1 ? "~" : ""
            }`),
          ""
        )}&rtt=auto&ruri=~~&z=15.4`
      : `https://yandex.ru/map-widget/v1/?indoorLevel=1&ll=${route[0].longitude}%2C${route[0].latitude}&mode=whatshere&whatshere%5Bpoint%5D=${route[0].longitude}%2C${route[0].latitude}&whatshere%5Bzoom%5D=17&z=16.58`
    : userLocation
    ? `https://yandex.ru/map-widget/v1/?ll=${userLocation.longitude}%2C${userLocation.latitude}&z=12`
    : "https://yandex.ru/map-widget/v1/?ll=37.624513%2C55.748635&z=12";

  return (
    <iframe
      title="yandex map"
      src={mapSrc}
      className="absolute top-0 bottom-0 right-0 left-0 h-full w-full"
      allowFullScreen
    />
  );
});
