import { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import { Actions } from "../../../../common/components/Table/Actions";
import {
  useDeleteKmuModelMutation,
  useGetKmuModelsQuery,
} from "services/vehicle/kmuApi";
import { IVehicleKmuModel } from "common/models/vehicles/IVehicleKmuModel";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const KmuModelsTable: FC<{ vehicleCategoryId: number }> = ({
  vehicleCategoryId,
}) => {
  const {
    data: kmu,
    isLoading,
    error,
  } = useGetKmuModelsQuery({ vehicleCategoryId });

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {kmu?.length ? <Table data={kmu} columns={columns} /> : <NotFoundTitle />}
    </QueryLayout>
  );
};

const columns: Column<IVehicleKmuModel>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Название",
    accessor: "name",
  },
  {
    Header: "Бренд",
    accessor: "kmu_brand",
    Cell: ({ row }: any) => <span>{row.values.kmu_brand.name}</span>,
  },
  {
    Header: "Грузоподъёмность стрелы",
    accessor: "arrow_carrying_capacity",
    Cell: ({ row }: any) => (
      <span>{`${
        row.values.arrow_carrying_capacity
          ? `${row.values.arrow_carrying_capacity} Т`
          : "Не указано"
      }`}</span>
    ),
  },
  {
    Header: "Грузоподъёмность борта",
    accessor: "lifting_height",
    Cell: ({ row }: any) => (
      <span>{`${
        row.values.lifting_height
          ? `${row.values.lifting_height} Т`
          : "Не указано"
      }`}</span>
    ),
  },
  {
    Header: "Длина стрелы",
    accessor: "arrow_length",
    Cell: ({ row }: any) => (
      <span>{`${
        row.values.arrow_length ? `${row.values.arrow_length} м` : "Не указано"
      }`}</span>
    ),
  },
  {
    id: "action",
    Header: () => (
      <span className="ml-auto block w-fit pr-[8px]">Действия</span>
    ),
    Cell: ({ row }: any) => (
      <Actions id={row.original.id} onDelete={useDeleteKmuModelMutation} />
    ),
  },
];
