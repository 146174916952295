import { FC, useState, useEffect } from "react";
import { List } from "../../../common/components/List/List";
import { VehicleListItem } from "../../../common/components/Vehicle/VehicleListItem";
import { CustomInput } from "../../../common/components/Input/CustomInput";
import { SvgCaretUp } from "../../../assets/icons/SvgCaretUp";
import { Button } from "../../../common/components/Button/Button";
import { VehicleCharacteristics } from "../../../common/components/Vehicle/VehicleCharacteristics";
import { SvgSuccess } from "../../../assets/icons/SvgSuccess";
import {
  useCreateVehicleMutation,
  useGetVehicleCategoriesQuery,
} from "../../../services/vehicle/vehiclesApi";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { VehicleFormValues } from "../../../common/models/forms/VehicleFormValues";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { getErrorMessage } from "../../../common/utils/helpers";
import { useUploadSignleImageMutation } from "../../../services/fileApi";
import { IVehicleInputs } from "common/models/api/IVehicleInputs";

export const VehicleAdd: FC<{ companyId: number }> = ({ companyId }) => {
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isFetching: isCategoriesFetching,
    error: categoriesError,
  } = useGetVehicleCategoriesQuery();
  const [
    createVehicle,
    {
      isSuccess: isCreatingSuccess,
      isLoading: isCreatingLoading,
      error: createError,
    },
  ] = useCreateVehicleMutation();
  const [uploadSts] = useUploadSignleImageMutation();

  const methods = useForm<VehicleFormValues>({
    mode: "all",
  });

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = methods;

  const [isAddingSuccess, setIsAddingSuccess] = useState<boolean>(false);

  const { showModal } = useGlobalModalContext();

  const openErrorModal = (message?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Ошибка при создании",
      subTitle:
        message ||
        "При создании техники возникла ошибка, повторите попытку позже",
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    if (data.category) {
      const vehicleFormValues: IVehicleInputs = {
        vehicle_category_id: data.category,
        location_latitude: 59,
        location_longitude: 60,
        company_id: companyId,
        chassis_model_id: data.main.chassisModel?.value || -1,
        kmu_model_id: data.main.kmuModel.value,
        search_radius_id: data.main.coverageRadius,
        vehicle_number: data.main.governmentNumber,
        sts_number: data.main.vrcNumber,
        location_address: data.main.location,
        arrow_carrying_capacity: +data.specifications.boomCapacity,
        arrow_length: +data.specifications.boomLength,
        board_carrying_capacity: +data.specifications.sideLoadCapacity,
        vehicle_length: +data.specifications.dimensions.length,
        vehicle_width: +data.specifications.dimensions.width,
        vehicle_height: +data.specifications.dimensions.height,
        options: data.specifications.optionalEquipment
          .filter((el) => el)
          .map((el) => +el),
      };
      const formData = new FormData();
      formData.append("file_type_id", "7");
      if (data.main.vrcPhoto) {
        formData.append("file", data.main.vrcPhoto[0]);
      }
      const image = await uploadSts(formData).unwrap();
      createVehicle({ ...vehicleFormValues, sts_photo_id: image.id });
    }
  });

  useEffect(() => {
    if (createError) {
      openErrorModal(getErrorMessage(createError)?.data.message);
    }
  }, [createError]);

  useEffect(() => {
    if (isCreatingSuccess) {
      setIsAddingSuccess(true);
    }
  }, [isCreatingSuccess]);

  const vehicleType = useWatch({
    control,
    name: "category",
  });

  return (
    <QueryLayout
      isLoading={isCategoriesLoading}
      isFetching={isCategoriesFetching}
      error={categoriesError}
    >
      {isAddingSuccess ? (
        <div className="flex min-h-full w-full flex-col items-center justify-center text-primary">
          <SvgSuccess className="mb-[17px] h-[57px] w-[57px]" />
          <p className="text-base font-semibold">
            Ваша техника успешно добавлена в список
          </p>
        </div>
      ) : (
        <div>
          {vehicleType ? (
            <form onSubmit={onSubmit}>
              <FormProvider {...methods}>
                <VehicleCharacteristics />
              </FormProvider>
              <div className="grid grid-cols-2 gap-[15px]">
                <Button
                  className="text-base font-medium tracking-wider"
                  mode="lightBorder"
                  type="button"
                  onClick={() => setValue("category", null)}
                >
                  Назад
                </Button>
                <Button
                  disabled={
                    Object.keys(errors).length !== 0 || isCreatingLoading
                  }
                  className="text-base font-medium tracking-wider"
                >
                  Добавить
                </Button>
              </div>
            </form>
          ) : (
            <div>
              <p className="mb-[15px] text-sm text-lightGray">
                Необходимо выбрать город и тип техники
              </p>
              <List>
                {categories &&
                  categories.map((category) => {
                    const isChecked =
                      !!vehicleType && +vehicleType === category.id;
                    return (
                      <li key={category.id} className="relative">
                        <CustomInput
                          {...register("category", {
                            required: true,
                            valueAsNumber: true,
                          })}
                          value={category.id}
                          checked={isChecked}
                          type="radio"
                          innerelement={
                            <VehicleListItem
                              mode="short"
                              name={category.name}
                              img={category.icon.path}
                            />
                          }
                        />
                        {!isChecked && (
                          <SvgCaretUp className="absolute right-[20px] top-[50%] h-[5px] w-[10px] translate-y-[-50%] rotate-[90deg]" />
                        )}
                      </li>
                    );
                  })}
              </List>
            </div>
          )}
        </div>
      )}
    </QueryLayout>
  );
};
