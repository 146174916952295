import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgArrowInCircle } from "../../../assets/icons/SvgArrowInCircle";
import { CustomInput } from "../../../common/components/Input/CustomInput";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { List } from "../../../common/components/List/List";
import { Title } from "../../../common/components/Title/Title";
import { VehicleListItem } from "../../../common/components/Vehicle/VehicleListItem";
import { useGetVehicleTypesQuery } from "../../../services/vehicle/vehicleTypesApi";
import {
  setAssignedVehicleCategoryId,
  setAssignmentVehicleTypeId,
} from "./chooseVehicleSlice";

export const ManipulatorsList: FC<{ categoryId: number }> = ({
  categoryId,
}) => {
  const dispatch = useAppDispatch();
  const assignedVehicleId = useAppSelector(
    (state) =>
      state.platformManager.chooseVehicle.vehicleAssignment
        .assignedVehicleTypeId
  );

  const {
    data: vehicleTypes,
    isLoading,
    isFetching,
    error,
  } = useGetVehicleTypesQuery([categoryId]);

  const handleVehicleChange = (id: Undefinable<number>) => {
    dispatch(setAssignmentVehicleTypeId(id));
  };

  const handleVehicleCategoryChange = (id: Undefinable<number>) => {
    dispatch(setAssignedVehicleCategoryId(id));
  };

  return (
    <div>
      <div className="mb-[15px] flex items-center">
        <button
          className="mr-[15px] transition hover:text-primary focus:text-primary active:text-black"
          onClick={() => {
            handleVehicleCategoryChange(undefined);
            handleVehicleChange(undefined);
          }}
        >
          <SvgArrowInCircle className="h-[35px] w-[35px] rotate-180" />
        </button>
        <Title>Выбрать манипулятор</Title>
      </div>
      <QueryLayout isLoading={isLoading} isFetching={isFetching} error={error}>
        <List>
          {vehicleTypes &&
            vehicleTypes.map((vehicle) => (
              <li key={vehicle.id} className="relative">
                <CustomInput
                  value={vehicle.id}
                  checked={assignedVehicleId === vehicle.id}
                  onChange={() => handleVehicleChange(vehicle.id)}
                  type="checkbox"
                  innerelement={
                    <VehicleListItem
                      mode="short"
                      name={vehicle.name}
                      img={vehicle.category.icon.path}
                    />
                  }
                />
              </li>
            ))}
        </List>
      </QueryLayout>
    </div>
  );
};
