import { FC } from "react";
import { useAppSelector } from "../../../app/hooks";
import { CompanyEdit } from "../../../common/components/Company/CompanyEdit";
import { UserEdit } from "../../../common/components/User/UserEdit";
import { USER_ROLE } from "common/utils/consts";

export const ProfileInfo: FC = () => {
  const user = useAppSelector((state) => state.user.current);
  const currentCompanyId = useAppSelector(
    (state) => state.user.current?.account?.company?.id
  );
  const roleId = useAppSelector(
    (state) => state.user.current?.account?.role.id
  );

  const isAdmin = roleId === USER_ROLE.ADMINISTRATOR;
  const isDriver = roleId === USER_ROLE.DRIVER;

  return (
    <div className="h-full overflow-y-scroll">
      {currentCompanyId && !isDriver && (
        <CompanyEdit companyId={currentCompanyId} isOpen readOnly={!isAdmin} />
      )}
      {user && <UserEdit user={user} isOpen={!currentCompanyId || isDriver} />}
    </div>
  );
};
