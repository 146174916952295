import { FC, useMemo, memo, useEffect } from "react";
import { ShadowContainer } from "../Container/ShadowContainer";
import { Dropdown } from "../Dropdown/Dropdown";
import { Input } from "../Input/Input";
import { timeFormatter } from "../../utils/helpers";
import {
  useDeleteInvoiceMutation,
  useGetInvoiceByIdQuery,
} from "services/invoicesApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { Button } from "../Button/Button";
import { useGlobalModalContext } from "../Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";

export const InvoiceInfo: FC<{ invoiceId: number; onDelete: () => void }> =
  memo(({ invoiceId, onDelete }) => {
    const {
      data: invoice,
      isLoading: isInvoiceLoading,
      error: invoiceError,
    } = useGetInvoiceByIdQuery({ id: invoiceId });

    const [
      deleteinvoice,
      { isLoading: isDeleting, error: deleteError, isSuccess: isDeleteSuccess },
    ] = useDeleteInvoiceMutation();

    const { showModal } = useGlobalModalContext();

    const showConfirmationModal = (invoiceId: number) => {
      showModal(MODAL_TYPES.CONFIRM_MODAL, {
        subTitle: "Вы уверены что хотите удалить данный чсёт?",
        confirmText: "Счёт успешно удалён",
        onConfirm: () => deleteinvoice(invoiceId),
      });
    };

    const showErrorModal = (erorrText?: string) => {
      showModal(MODAL_TYPES.ERROR_MODAL, {
        title: erorrText || "Ошибка при удалении счёта",
        subTitle: "Повторите попытку позже",
      });
    };

    useEffect(() => {
      if (deleteError) {
        showErrorModal();
      }
    }, [deleteError]);

    useEffect(() => {
      if (isDeleteSuccess) {
        onDelete();
      }
    }, [isDeleteSuccess]);

    const invoiceDate = useMemo(
      () =>
        invoice?.created_at
          ? timeFormatter({ time: new Date(invoice.created_at) || null })
          : null,
      [invoice?.created_at]
    );

    return (
      <QueryLayout
        isLoading={isInvoiceLoading}
        error={invoiceError || deleteError}
      >
        {invoice && (
          <div>
            <ShadowContainer>
              <Dropdown title={`Детали счёта #${invoiceId}`} isOpen>
                <div className="grid gap-[25px]">
                  <Input
                    className="w-full"
                    label="Дата"
                    value={invoiceDate || ""}
                    readOnly
                  />
                  <Input
                    className="w-full"
                    label="Статус"
                    value={invoice.status.name}
                    readOnly
                  />
                  <Input
                    className="w-full"
                    label="Сумма"
                    value={`${invoice.amount} руб.`}
                    readOnly
                  />
                </div>
              </Dropdown>
            </ShadowContainer>
            <ShadowContainer>
              <Dropdown title="Прикреплённые документы (чеки, договора)">
                <a
                  className="rounded border border-black bg-black px-[23px] py-[10px] text-base text-white outline-none transition hover:bg-white hover:text-black focus:bg-white focus:text-black active:bg-black active:text-white"
                  target="_blank"
                  rel="noreferrer"
                  href={invoice.file.path}
                >
                  Ссылка на документ
                </a>
              </Dropdown>
            </ShadowContainer>
            {invoice.status.id === 1 && (
              <Button
                onClick={() => showConfirmationModal(invoice.id)}
                disabled={isDeleting}
                loader={isDeleting}
                className="mt-[10px] w-full"
              >
                Удалить выставленный счёт
              </Button>
            )}
          </div>
        )}
      </QueryLayout>
    );
  });
