import { IBankCardItemProps } from "common/models/components/bankCards/IBankCardItemProps";
import { FC, memo } from "react";

export const BankCardItem: FC<IBankCardItemProps> = memo(
  ({ className, card, ...props }) => {
    const userClasses = className || "";

    return (
      <div
        {...props}
        className={`flex items-center font-medium text-gray ${userClasses}`}
      >
        {card.first_6} {"******"} {card.last_4}
      </div>
    );
  }
);
