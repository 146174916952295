import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Container } from "../../../common/components/Container/Container";
import { OrderList } from "../../../common/components/Order/OrderList";
import { setIsOrdering } from "./homeSlice";
import { Button } from "common/components/Button/Button";
import { OrderingPage } from "features/ordering/OrderingPage";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";
import { useNavigate } from "react-router-dom";
import { setActiveOrderId } from "../orders/ordersSlice";

export const HomePage: FC = withErrorBoundary(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeOrderId = useAppSelector(
    (state) => state.customerCompanyManager.home.activeOrderId
  );
  const isOrdering = useAppSelector(
    (state) => state.customerCompanyManager.home.isOrdering
  );

  const handleSetIsOrdering = () => {
    dispatch(setIsOrdering(!isOrdering));
  };

  const handleOnOrderShow = (orderId: number) => {
    navigate("/orders");
    dispatch(setActiveOrderId(orderId));
  }

  return (
    <Container className="pointer-events-none bg-transparent">
      <div className="pointer-events-auto flex h-fit max-h-[100%] flex-col overflow-y-auto">
        {isOrdering ? (
          <OrderingPage onOrderShow={handleOnOrderShow} />
        ) : (
          <OrderList
            activeOrderId={activeOrderId}
            withAddresses
            withDetails
            withTracking
          />
        )}
      </div>
      <div
        className={`col-start-2 ml-auto grid h-full max-h-[calc(100vh-105px)] w-full max-w-[375px] grid-rows-[1fr] items-end gap-[15px] overflow-y-hidden ${
          isOrdering ? "col-span-1 row-start-2" : ""
        }`}
      >
        <Button
          className={`pointer-events-auto mt-auto ml-auto w-full ${
            isOrdering ? "col-span-2 row-start-2" : ""
          }`}
          onClick={handleSetIsOrdering}
        >
          {isOrdering ? "Остановить оформление" : "Заказать технику"}
        </Button>
      </div>
    </Container>
  );
});
