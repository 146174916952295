import { FC, useState } from "react";
import { SvgArrowInCircle } from "../../../assets/icons/SvgArrowInCircle";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { VehicleInput } from "../Vehicle/VehicleInput";
import { VehicleListItem } from "../Vehicle/VehicleListItem";
import { useGetCompanyVehiclesQuery } from "../../../services/vehicle/vehiclesApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { List } from "../List/List";
import { Title } from "../Title/Title";
import { NotFoundTitle } from "../Title/NotFoundTitle";
import { IChooseVehicleProps } from "common/models/components/IChooseVehicleProps";

export const ChooseVehicle: FC<IChooseVehicleProps> = ({
  driverChoosingOpen,
  resetAssignment,
  setAssignedVehicle,
  assignedVehicleId,
  assignedVehicleTypeId,
  companyId,
  reserve
}) => {
  const [searchText, setSearchText] = useState<string>("");

  const {
    data: vehicles,
    isLoading,
    isFetching,
    error,
  } = useGetCompanyVehiclesQuery({
    companyId,
    vehicleTypeId: assignedVehicleTypeId,
    query: searchText || undefined,
  });

  const handleSetAssignedVehicle = (id: number) => {
    setAssignedVehicle(id);
  };

  const handleSetSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const vehicleWithDriver = vehicles?.filter(
    (vehicle) => vehicle.drivers.length
  );
  const vehicleWithoutDriver = vehicles?.filter(
    (vehicle) => !vehicle.drivers.length
  );

  return (
    <>
      <div>
        <div className="mb-[20px] flex items-center">
          {!reserve &&
            <button
                className="mr-[16px] h-[32px] w-[32px] text-black
        outline-none transition hover:text-primary focus:text-primary active:text-black"
                onClick={resetAssignment}
            >
              <SvgArrowInCircle className="rotate-180 text-inherit" />
            </button>
          }
          <h3 className="text-center text-md font-semibold">
            Назначить технику
          </h3>
        </div>
        <div className="mb-[25px]">
          <Input
            className="w-full"
            placeholder="Поиск"
            value={searchText}
            onChange={handleSetSearchText}
          />
        </div>
      </div>

      <QueryLayout isLoading={isLoading} isFetching={isFetching} error={error}>
        {vehicleWithDriver && (
          <div className="grid gap-[25px] overflow-y-scroll">
            <div>
              <Title className="mb-[15px]">С водителем</Title>
              <List>
                {vehicleWithDriver.length ? (
                  vehicleWithDriver.map((vehicle) => (
                    <li key={vehicle.id}>
                      <VehicleInput
                        type="radio"
                        checked={vehicle.id === assignedVehicleId}
                        onClick={() => handleSetAssignedVehicle(vehicle.id)}
                        innerelement={
                          <VehicleListItem
                            name={vehicle.vehicle_type.category.name}
                            img={vehicle.vehicle_type.category.icon.path}
                            registrationNumber={vehicle.vehicle_number}
                            companyName={vehicle.company.name}
                            desc={vehicle.vehicle_type.name}
                          />
                        }
                      />
                    </li>
                  ))
                ) : (
                  <NotFoundTitle />
                )}
              </List>
            </div>
            <div>
              <Title className="mb-[15px]">Без водителя</Title>
              <List>
                {vehicleWithoutDriver?.length ? (
                  vehicleWithoutDriver.map((vehicle) => (
                    <li key={vehicle.id}>
                      <VehicleInput
                        type="radio"
                        checked={vehicle.id === assignedVehicleId}
                        onClick={() => handleSetAssignedVehicle(vehicle.id)}
                        innerelement={
                          <VehicleListItem
                            name={vehicle.vehicle_type.category.name}
                            img={vehicle.vehicle_type.category.icon.path}
                            registrationNumber={vehicle.vehicle_number}
                            companyName={vehicle.company.name}
                            desc={vehicle.vehicle_type.name}
                          />
                        }
                      />
                    </li>
                  ))
                ) : (
                  <NotFoundTitle />
                )}
              </List>
            </div>
          </div>
        )}
      </QueryLayout>

      <div className="grid grid-cols-2 gap-[15px] pt-[30px]">
        {!reserve &&
          <Button
              className="h-[45px] text-base tracking-wider"
              mode="darkBorder"
              onClick={resetAssignment}
          >
            Назад
          </Button>
        }
        <Button
          className="h-[45px] text-base tracking-wider"
          onClick={driverChoosingOpen}
          disabled={!assignedVehicleId}
        >
          Назначить
        </Button>
      </div>
    </>
  );
};
