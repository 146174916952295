import { FC, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { AdministratorPage } from "./roles/AdministratorPage";
import { ImplementerCompanyManagerPage } from "./roles/ImplementerCompanyManagerPage";
import { PlatformManagerPage } from "./roles/PlatformManagerPage";
import { GuestPage } from "./roles/GuestPage";
import { IndividualPage } from "./roles/IndividualPage";
import { CustomerCompanyManagerPage } from "./roles/CustomerCompanyManagerPage";
import { DriverPage } from "./roles/DriverPage";
import { COMPANY_CATEGORY, USER_ROLE } from "common/utils/consts";

export const MainPage: FC = () => {
  const isReady = useAppSelector((state) => state.user.isReady);
  const userId = useAppSelector((state) => state.user.current?.id);
  const roleId = useAppSelector(
    (state) => state.user.current?.account?.role.id
  );
  const companyRoleId = useAppSelector(
    (state) => state.user.current?.account?.company?.category.id
  );
  const userFirstName = useAppSelector(
    (state) => state.user.current?.first_name
  );

  const CurrentRolePage = useMemo(
    () => getRolePage(roleId, companyRoleId),
    [roleId, companyRoleId]
  );

  if (isReady && !userFirstName && userId) {
    return <Navigate to="/auth/registration/dop" />;
  }

  return (
    <div className="min-w-screen flex min-h-screen">
      <CurrentRolePage />
    </div>
  );
};

const getRolePage = (roleId?: number, companyRoleId?: number): FC => {
  switch (roleId) {
    case USER_ROLE.INDIVIDUAL:
      return IndividualPage;
    case USER_ROLE.MANAGER:
      switch (companyRoleId) {
        case COMPANY_CATEGORY.CUSTOMER:
          return CustomerCompanyManagerPage;
        case COMPANY_CATEGORY.IMPLEMENTER:
          return ImplementerCompanyManagerPage;
        case COMPANY_CATEGORY.PLATFORM:
          return PlatformManagerPage;
        default:
          return GuestPage;
      }
    case USER_ROLE.DRIVER:
      return DriverPage;
    case USER_ROLE.ADMINISTRATOR:
      switch (companyRoleId) {
        case COMPANY_CATEGORY.CUSTOMER:
          return CustomerCompanyManagerPage;
        case COMPANY_CATEGORY.IMPLEMENTER:
          return ImplementerCompanyManagerPage;
        default:
          return AdministratorPage;
      }
    default:
      return GuestPage;
  }
};
