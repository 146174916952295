import { FC, memo, useState } from "react";
import { SvgTelegram } from "../../../assets/icons/SvgTelegram";
import { Message } from "./Message";
import { Input } from "../Input/Input";
import {
  useGetAppealMessagesQuery,
  useSendMessageMutation,
} from "services/appealApi";
import { QueryLayout } from "../Layout/QueryLayout";

export const Chat: FC<{
  appealId: number;
}> = memo(({ appealId }) => {
  const [messageText, setMessageText] = useState<string>("");

  const {
    data: messages,
    isLoading: isMessagesLoading,
    error: messagesError,
  } = useGetAppealMessagesQuery(appealId);
  const [
    sendMessage,
    { isLoading: isSendingMessage, error: sendingMessageError },
  ] = useSendMessageMutation();

  const handleMessageTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessageText(e.target.value);
  };

  const handleSendMessage = () => {
    sendMessage({ message: messageText, id: appealId });
    setMessageText("");
  };

  return (
    <>
      <div className="mb-[10px] overflow-y-scroll">
        <QueryLayout isLoading={isMessagesLoading} error={messagesError}>
          {messages?.map((message) => (
            <Message
              key={message.id}
              time={new Date(message.created_at)}
              isMyMessage={!!message.manager}
            >
              {message.message}
            </Message>
          ))}
        </QueryLayout>
      </div>
      <div className="mx-[5px] mb-[15px] flex items-center rounded p-[15px] pr-0 shadow">
        <Input
          value={messageText}
          onChange={handleMessageTextChange}
          className="h-[35px] w-full text-[12px]"
          placeholder="Сообщение"
        />
        <button
          onClick={handleSendMessage}
          disabled={!messageText || isSendingMessage}
          className="flex h-full w-[60px] items-center justify-center text-black transition hover:text-primary focus:text-primary active:text-black disabled:text-gray"
        >
          <SvgTelegram className="h-[22px] w-[22px]" />
        </button>
      </div>
    </>
  );
});
