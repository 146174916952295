import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgGooglePlay: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.411 10.1359L15.6158 7.94531L11.7305 11.8282L15.6155 15.7107L19.4239 13.5129C20.0177 13.1523 20.3721 12.5224 20.3721 11.8282C20.3721 11.1339 20.0175 10.504 19.411 10.1359Z"
        fill="#FFCE00"
      />
      <path
        d="M11.7292 11.828L1.14632 1.17578C0.778229 1.54363 0.554688 2.04277 0.554688 2.59455V21.029C0.554688 21.5808 0.778229 22.0799 1.14632 22.448L11.7292 11.828Z"
        fill="url(#paint0_linear_1030_16642)"
      />
      <path
        d="M11.7313 11.8283L15.6167 7.94541L15.6229 7.93925L10.417 4.94383L3.55445 0.913164C2.93649 0.532157 2.18715 0.518999 1.55602 0.873691C1.41148 0.965513 1.27982 1.05762 1.14844 1.17575L11.7313 11.8283Z"
        fill="#00F076"
      />
      <path
        d="M1.14844 22.4479C1.27982 22.566 1.41148 22.6581 1.55602 22.7499C1.87144 22.9207 2.20031 23.0128 2.5289 23.0128C2.88382 23.0128 3.22558 22.9078 3.54128 22.7239L10.4167 18.7126L15.6226 15.7171L15.6164 15.711L11.7313 11.8281L1.14844 22.4479Z"
        fill="#F63448"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1030_16642"
          x1="-4.17497"
          y1="17.1299"
          x2="6.46102"
          y2="6.48678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E2FF" />
          <stop offset="1" stopColor="#00C5FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
