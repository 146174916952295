import { FC, useEffect } from "react";
import { Button } from "../Button/Button";
import { Input } from "common/components/Input/Input";
import { useForm } from "react-hook-form";
import {
  useCreateRefillMutation,
  useCreateWithDrawalsMutation,
} from "services/invoicesApi";
import {
  setIsReloadActive
} from "features/ordering/orderingSlice";
import { SvgSuccess } from "assets/icons/SvgSuccess";
import { useAppDispatch } from "../../../app/hooks";

export const InvoiceForm: FC<{ isWithdrawals?: boolean }> = ({
  isWithdrawals,
}) => {
  const [
    createRefill,
    {
      isLoading: isRefillLoading,
      error: refillError,
      isSuccess: isRefillSuccess,
      reset: resetRefill,
      data: refillData,
    },
  ] = useCreateRefillMutation();
  const [
    createWithdrawals,
    {
      isLoading: isWithdrawalsLoading,
      error: withdrawalError,
      isSuccess: isWithDrawalsSuccess,
      reset: resetWithdrawals,
      data: withdrawData,
    },
  ] = useCreateWithDrawalsMutation();

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<{ amount: number; email: string }>();

  const onSubmit = handleSubmit((data) => {
    if (isWithdrawals) createWithdrawals(data);
    else createRefill(data);
  });

  const email = withdrawData?.email || refillData?.email;
  const amount = withdrawData?.amount || refillData?.amount;

  useEffect(() => {
    if (isRefillSuccess) {
      dispatch(setIsReloadActive(true))
    }
  }, [isRefillSuccess])

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <Input
        {...register("amount", {
          required: { value: true, message: "Данное поле обязательно" },
        })}
        invalidMessage={errors.amount?.message}
        invalid={!!errors.amount}
        className="w-full"
        label="Сумма пополнения"
        type="number"
      />
      <Input
        {...register("email", {
          required: { value: true, message: "Данное поле обязательно" },
          pattern: {
            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            message: "Неправильный формат почты",
          },
        })}
        invalid={!!errors.email}
        invalidMessage={errors.email?.message}
        className="w-full"
        label="Email для выставления счета * "
        type="email"
      />
      {isRefillSuccess || isWithDrawalsSuccess ? (
        <div className="grid justify-center gap-[25px] text-center">
          <SvgSuccess className="mx-auto h-[50px] w-[50px]" />
          <div className="text-lg">Ваш запрос принят</div>
          <p className="text-semibold text-gray">
            В течении 30 мин. вам на почту{" "}
            <span className="text-primary">{email}</span> придет счет
          </p>
          <div className="text-xl font-semibold">{`${amount} руб.`}</div>
          <Button
            type="button"
            onClick={() => {
              resetRefill();
              resetWithdrawals();
              resetForm();
            }}
            className=""
          >
            Выставить ещё счёт
          </Button>
        </div>
      ) : (
        <Button disabled={isRefillLoading || isWithdrawalsLoading}>
          Выставить счет
        </Button>
      )}
    </form>
  );
};
