import { FC } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Title } from "common/components/Title/Title";
import { useForm } from "react-hook-form";
import { CardValues } from "common/models/forms/CardValues";
import { Input } from "common/components/Input/Input";
import { Button } from "common/components/Button/Button";

export const AddBankCard: FC = () => {
  const dispatch = useAppDispatch();

  const bankCardId = useAppSelector((state) => state.ordering.bankCardId);
  const userId = useAppSelector((state) => state.user.current?.id);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CardValues>({
    defaultValues: {
      // number: String(card.number),
      // cvc: String(card.cvc),
      // dateExpires: String(card.dateExpires),
    },
  });

  const onSubmit = handleSubmit((data) => {
    // dispatch(
    //   setCard({
    //     number: +data.number,
    //     cvc: +data.cvc,
    //     dateExpires: +data.dateExpires,
    //   })
    // );
  });

  const handleReset = () => {
    // dispatch(setCard({}));
    reset({ number: "", cvc: "", dateExpires: "" });
  };

  return (
    <div className="pointer-events-auto h-fit overflow-hidden rounded bg-white p-[15px]">
      <div className="h-full overflow-y-scroll">
        <Title className="mb-[25px] text-center">
          {userId ? "Добавить карту" : "Ваша карта"}
        </Title>
        <p className="mb-[25px] text-xs text-lightGray">
          Для того чтобы подтвердить карту с вас спишется один рубль
        </p>
        <form onSubmit={onSubmit}>
          <Input
            className="mb-[25px] w-full"
            {...register("number", {
              required: true,
              minLength: 17,
              maxLength: 17,
            })}
            // readOnly={!!card.number}
            invalid={!!errors.number}
            label="Ноер карты"
            type="number"
            placeholder="Введите значение"
          />
          <div className="mb-[25px] grid grid-cols-2 gap-[10px]">
            <Input
              className=" w-full"
              {...register("dateExpires", {
                required: true,
                minLength: 4,
                maxLength: 4,
              })}
              // readOnly={!!card.number}
              invalid={!!errors.dateExpires}
              label="Срок действия"
              type="number"
              placeholder="Срок действия"
            />
            <Input
              className="w-full min-w-[100px]"
              {...register("cvc", {
                required: true,
                minLength: 3,
                maxLength: 3,
              })}
              // readOnly={!!card.number}
              invalid={!!errors.cvc}
              label="CVC"
              type="number"
              placeholder="CVC"
            />
          </div>
          {true ? (
            <Button
              key={1}
              className="w-full"
              type="button"
              onClick={handleReset}
            >
              Использовать другую карту
            </Button>
          ) : (
            <Button key={2} className="w-full" type="submit">
              {userId ? "Продолжить" : "Использовать"}
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};
