import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ChassisModelFormInputs } from "./ChassisModelFormInputs";
import { Button } from "../../../../../common/components/Button/Button";
import { useParams } from "react-router-dom";
import {
  useGetChassisModelByIdQuery,
  useUpdateChassisModelMutation,
} from "services/vehicle/chassisApi";
import { getErrorMessage } from "common/utils/helpers";
import { Error } from "common/components/Message/Error/Error";
import { Success } from "common/components/Message/Success/Success";

export const ChassisModelEdit: FC = () => {
  const { id } = useParams();

  const {
    data: model,
    isLoading: isModelLoading,
    error: modelError,
  } = useGetChassisModelByIdQuery(id ? +id : -1, { skip: !id });

  const [update, { isLoading: isUpdating, error: updateError, isSuccess }] =
    useUpdateChassisModelMutation();

  const methods = useForm<{
    id: number;
    name: string;
    chassis_mark_id: number;
  }>();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (model) {
      reset({ ...model, chassis_mark_id: model.chassis_model.id });
    }
  }, [model]);

  const onSubmit = handleSubmit((data) => {
    update(data);
  });

  if (modelError || updateError) {
    return (
      <Error>{getErrorMessage(modelError || updateError)?.data.message}</Error>
    );
  }

  if (isSuccess) {
    return <Success>Марка успешно отредактирована!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <ChassisModelFormInputs />
      </FormProvider>
      <Button
        className="w-fit"
        loader={isUpdating}
        disabled={!isDirty || isUpdating || isModelLoading}
      >
        Изменить
      </Button>
    </form>
  );
};
