import { FC, useCallback, useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Input } from "common/components/Input/Input";
import {
  setVehicleDeliveryDay,
  setVehicleDeliveryTime,
} from "features/ordering/orderingSlice";
import { DatePicker } from "common/components/DatePicker/DatePicker";
import { Title } from "common/components/Title/Title";
import { timeFormatter } from "common/utils/helpers";

export const TimeChoose: FC = () => {
  const vehicleDeliveryDay = useAppSelector(
    (state) => state.ordering.vehicleDeliveryDay
  );
  const vehicleDeliveryTime = useAppSelector(
    (state) => state.ordering.vehicleDeliveryTime
  );

  const [hours, setHours] = useState<number>(
    vehicleDeliveryTime ? Math.floor(vehicleDeliveryTime / 60 / 1000) : 0
  );
  const [minutes, setMinutes] = useState<number>(
    vehicleDeliveryTime
      ? (vehicleDeliveryTime -
          Math.floor(vehicleDeliveryTime / 60 / 1000) * 60 * 1000) /
          1000
      : 0
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setVehicleDeliveryTime(hours * 60 * 1000 + minutes * 1000));
  }, [hours, minutes]);

  const handleSetDeliveryHours = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentTimeNumber = +e.target.value;

      if (currentTimeNumber > 24) {
        setHours(24);
      } else if (currentTimeNumber < 0) {
        setHours(0);
      } else setHours(+currentTimeNumber);
    },
    []
  );

  const handleSetDeliveryMinutes = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentTimeNumber = +e.target.value;

      if (currentTimeNumber > 59) {
        setMinutes(59);
      } else if (currentTimeNumber < 0) {
        setMinutes(0);
      } else setMinutes(+currentTimeNumber);
    },
    []
  );

  const setTimeAt = useCallback((period: Date | [Date | null, Date | null]) => {
    if (!Array.isArray(period)) {
      dispatch(setVehicleDeliveryDay(period.toISOString().split("T")[0]));
    }
  }, []);

  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const hourseString = hours < 10 ? `0${hours}` : `${hours}`;

  return (
    <div className="pointer-events-auto flex h-fit w-fit flex-col items-center rounded bg-white p-[15px]">
      <Title className="mb-[15px] text-center">Время проведения работ</Title>
      <p className="mb-[10px] text-center text-sm text-lightGray">
        В какой день подъехать
      </p>
      <div className="mx-auto mb-[15px] w-fit">
        <DatePicker
          selectsRange={undefined}
          placeholderText="Время от"
          selected={new Date(vehicleDeliveryDay || new Date())}
          onChange={setTimeAt}
          minDate={new Date()}
          inline
        />
      </div>
      <label className="grid gap-[15px] text-sm text-lightGray">
        В какое время подъехать
        <div className="grid w-fit grid-cols-[50px,3px,50px] items-center gap-[10px]">
          <Input
            value={hourseString}
            onChange={handleSetDeliveryHours}
            type="number"
            className="h-[50px] w-[50px] pl-[5px] pr-[5px] text-center"
            max={24}
            min={0}
          />
          :
          <Input
            value={minutesString}
            onChange={handleSetDeliveryMinutes}
            type="number"
            className="h-[50px] w-[50px] pl-[5px] pr-[5px] text-center"
            max={59}
            min={0}
          />
        </div>
      </label>
    </div>
  );
};
