import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { MaskedInput } from "../../../common/components/Input/MaskedInput";
import { PhoneVerificationInput } from "../../../common/components/Input/PhoneVerificationInput";
import { LoginFormValues } from "../../../common/models/forms/LoginFormValues";
import { phoneStrTransform } from "../../../common/utils/helpers";
import {
  useLoginUserMutation,
  useSendCodeMutation,
} from "../../../services/authApi";
import { login as setUser } from "../../user/userSlice";

export const Registration: FC = () => {
  const [
    login,
    {
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      isLoading: isLoginLoading,
      data: userData,
      reset,
    },
  ] = useLoginUserMutation();
  const [
    sendCode,
    {
      isSuccess: isSendCodeSuccess,
      isError: isSendCodeError,
      isLoading: isSendCodeLoading,
    },
  ] = useSendCodeMutation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [isVerificationCodeOpen, setIsVerificationCodeOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormValues>();

  const [otp, setOtp] = useState<any>(undefined);

  useEffect(() => {
    setValue("code", otp);
  }, [otp, setValue]);

  useEffect(() => {
    setIsVerificationCodeOpen(isSendCodeSuccess);
  }, [isSendCodeSuccess]);

  useEffect(() => {
    if (isLoginSuccess && userData) {
      dispatch(setUser(userData));
      navigate("/auth/registration/dop");
    }
  }, [isLoginSuccess]);

  const onSubmit = handleSubmit((data) => {
    const registrationData = {
      phone: phoneStrTransform(data.phone),
      code: data.code,
    };
    if (!isVerificationCodeOpen) {
      // setIsVerificationCodeOpen(true);
      sendCode(registrationData);
    } else {
      login(registrationData);
    }
  });

  const verificationError = !!errors.code || isLoginError;
  const codeSendingError = !!errors.phone || isSendCodeError;

  return (
    <>
      <form onSubmit={onSubmit}>
        {isVerificationCodeOpen ? (
          <div className="mb-[25px] w-full">
            <label>
              <span
                className={`mb-[12px] block text-sm font-semibold ${
                  verificationError ? "text-red" : "text-gray"
                }`}
              >
                Код из СМС
              </span>
              <PhoneVerificationInput
                {...register("code", { required: true, minLength: 4 })}
                value={otp}
                containerStyle="w-full justify-around"
                shouldAutoFocus={true}
                inputStyle={{
                  width: 55,
                  height: 55,
                  backgroundColor: "#F9F9F9",
                  border: verificationError ? "1px solid red" : "",
                  borderRadius: 5,
                  fontSize: 18,
                  outlineColor: '#4BD035'
                }}
                isInputNum
                isInputSecure
                onChange={(number: string) => setOtp(number || undefined)}
                numInputs={4}
              />
            </label>
            {verificationError && (
              <div className="mt-[15px] text-center text-sm text-red">
                Неверный код. Попробуйте ещё раз.
              </div>
            )}
          </div>
        ) : (
          <div className="mb-[15px] w-full">
            <label>
              <MaskedInput
                {...register("phone", {
                  required: true,
                  validate: (value) => phoneStrTransform(value).length === 11,
                })}
                invalid={codeSendingError}
                label="Телефон"
                className="w-full"
                type="tel"
                mask="+7 (999) 999 99-99"
                placeholder="Введите телефон"
              />
            </label>
            {codeSendingError && (
              <span className="mt-[5px] text-xs text-red">
                Неправильно набран номер
              </span>
            )}
          </div>
        )}
        {!isVerificationCodeOpen && (
          <div className="mb-[25px] text-center text-xs text-graySecond">
            Нажимая кнопку, вы подтверждаете свое согласие на
            <br />
            <NavLink
              target="_blank"
              rel="noopener noreferrer"
              to="/privacy-policy"
              className="cursor-pointer text-black"
            >
              обработку персональных данных.
            </NavLink>
          </div>
        )}

        {isVerificationCodeOpen && (
          <Button
            className="mb-[16px] w-full"
            type="button"
            mode="darkBorder"
            onClick={() => {
              setIsVerificationCodeOpen(false);
              reset();
            }}
          >
            Назад
          </Button>
        )}

        <div>
          <Button
            className="mb-[25px] w-full"
            type="submit"
            disabled={isLoginLoading || isSendCodeLoading}
            loader={isLoginLoading}
          >
            Регистрация
          </Button>
        </div>
      </form>
      <div className="flex justify-center text-center">
        <div className="text-[13px] font-semibold text-gray">
          У вас уже есть профиль?
          <NavLink
            to="/auth"
            className="ml-[4px] text-base font-semibold text-black"
          >
            Войти
          </NavLink>
          <br />
          или
          <br />
          <NavLink
            to="/"
            className="ml-[4px] text-base font-semibold text-black"
          >
            Войти как гость
          </NavLink>
        </div>
      </div>
    </>
  );
};
