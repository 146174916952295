import { FC, memo, useMemo } from "react";
import { timeFormatter } from "../../utils/helpers";
import { IMessageProps } from "common/models/components/ui/IMessageProps";

export const Message: FC<IMessageProps> = memo((props) => {
  const userClasses = props.className || "";

  const messageTime = useMemo(
    () => timeFormatter({ time: props.time, mode: "dd-MM-yyyy hh:mm" }),
    [props.time]
  );

  return (
    <div
      className={`mb-[20px] h-fit w-[280px] rounded p-[12px]
      ${
        props.isMyMessage
          ? "ml-auto bg-black text-white"
          : "bg-lightWhiteGray text-gray"
      } ${userClasses}`}
    >
      <p className={`mb-[5px] text-xs font-medium`}>{props.children}</p>
      <time className="block text-right text-xxs text-lightGray">
        {messageTime}
      </time>
    </div>
  );
});
