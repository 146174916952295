import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgChatCircleDots: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.6786 22.1245C4.18768 19.6112 3.66554 16.64 4.21024 13.7689C4.75494 10.8979 6.329 8.32442 8.63685 6.53179C10.9447 4.73916 13.8276 3.85069 16.7442 4.0332C19.6607 4.21571 22.4103 5.45665 24.4767 7.52301C26.5431 9.58936 27.784 12.339 27.9666 15.2555C28.1491 18.1721 27.2606 21.055 25.468 23.3629C23.6754 25.6707 21.1019 27.2448 18.2309 27.7895C15.3598 28.3342 12.3887 27.8121 9.87532 26.3212L9.87534 26.3211L5.73077 27.5052C5.5593 27.5542 5.37784 27.5565 5.20521 27.5117C5.03257 27.467 4.87504 27.3769 4.74894 27.2508C4.62283 27.1247 4.53275 26.9672 4.48802 26.7945C4.44328 26.6219 4.44553 26.4404 4.49452 26.269L5.67869 22.1244L5.6786 22.1245Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 17.5059C10.8284 17.5059 11.5 16.8343 11.5 16.0059C11.5 15.1774 10.8284 14.5059 10 14.5059C9.17157 14.5059 8.5 15.1774 8.5 16.0059C8.5 16.8343 9.17157 17.5059 10 17.5059Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17.5059C16.8284 17.5059 17.5 16.8343 17.5 16.0059C17.5 15.1774 16.8284 14.5059 16 14.5059C15.1716 14.5059 14.5 15.1774 14.5 16.0059C14.5 16.8343 15.1716 17.5059 16 17.5059Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 17.5059C22.8284 17.5059 23.5 16.8343 23.5 16.0059C23.5 15.1774 22.8284 14.5059 22 14.5059C21.1716 14.5059 20.5 15.1774 20.5 16.0059C20.5 16.8343 21.1716 17.5059 22 17.5059Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
