import { Button } from "common/components/Button/Button";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { FC } from "react";
import {
  useConfirmInviteMutation,
  useGetUserAccountsQuery,
  useRejectInviteMutation,
} from "services/userApi";

export const Invites: FC = () => {
  const { data: userAccounts } = useGetUserAccountsQuery();

  const [confirmInvite, { isLoading: isConfirmLoading, error: confirmError }] =
    useConfirmInviteMutation();
  const [rejectInvite, { isLoading: isRejectLoading, error: rejectError }] =
    useRejectInviteMutation();

  const { showModal } = useGlobalModalContext();

  const showInviteConfirmationModal = (accountId: number) => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      title: "Принять инвайт",
      subTitle: "Вы уверены что хотите принять инвайт?",
      confirmText: "Инвайт успешно принят!",
      onConfirm: () => confirmInvite(accountId),
    });
  };

  const showInviteRejectModal = (accountId: number) => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      title: "ОТклонить инвайт",
      subTitle: "Вы уверены что хотите отклонить инвайт?",
      confirmText: "Инвайт отклонён!",
      onConfirm: () => rejectInvite(accountId),
    });
  };

  const inviteAccountArr = userAccounts?.filter(
    (account) => account.invite && !account.invite.confirmed_at
  );

  return (
    <div className="grid gap-[15px]">
      {inviteAccountArr?.map((account) => (
        <div className="grid gap-[10px] rounded border border-whiteGray p-[12px] text-center">
          <QueryLayout
            isLoading={isConfirmLoading || isRejectLoading}
            error={confirmError || rejectError}
          >
            <div className="text-sm font-medium text-gray">
              Вам пришёл инвайт от компании
            </div>
            <div className="font-medium text-primary">
              {account.company?.name}
            </div>
            <div className="flex items-center justify-center">
              <div className="mr-[5px] text-sm font-medium text-gray">
                На должность:
              </div>
              <div className="font-medium text-primary">
                {account.role.name}
              </div>
            </div>
          </QueryLayout>

          <div className="grid grid-cols-2 gap-[15px]">
            <Button
              onClick={() => showInviteConfirmationModal(account.id)}
              disabled={isConfirmLoading}
              className="w-full"
            >
              Принять
            </Button>
            <Button
              onClick={() => showInviteRejectModal(account.id)}
              disabled={isRejectLoading}
              className="w-full"
            >
              Отклонить
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};
