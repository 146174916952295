import { FC, memo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { OrderItemDetails } from "../../../common/components/Order/OrderItemDetails";
import { Title } from "../../../common/components/Title/Title";
import { VehicleAssignment } from "./VehicleAssignment";
import { VehiclesTabs } from "./VehiclesTabs";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const ChooseVehiclePage: FC = withErrorBoundary(
  memo(() => {
    const activeOrderId = useAppSelector(
      (state) => state.platformManager.chooseVehicle.activeOrderId
    );

    return (
      <>
        <div className="grid overflow-y-hidden">
          <VehiclesTabs />
        </div>
        {activeOrderId ? (
          <>
            <div className="grid grid-rows-[40px,1fr] gap-[10px] overflow-y-hidden">
              <Title className="flex items-center">Детали заказа</Title>
              <OrderItemDetails readOnly orderId={activeOrderId} />
            </div>
            <div className="grid grid-rows-[40px,90px,1fr,225px] gap-[10px] overflow-y-hidden">
              <Title className="flex items-center">Назначить технику</Title>
              <VehicleAssignment />
            </div>
          </>
        ) : (
          <InformationBlock className="col-span-2 w-full" />
        )}
      </>
    );
  })
);
