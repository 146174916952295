import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  nextOrderStep,
  setDescriptionText,
  addPhotos,
} from "features/ordering/orderingSlice";
import { Title } from "common/components/Title/Title";
import { TextArea } from "common/components/TextArea/TextArea";
import { PhotoInput } from "common/components/Input/PhotoInput";
import { Button } from "common/components/Button/Button";
import { IOrderDescriptionProps } from "common/models/ordering/IOrderDescriptionProps";

export const OrderDescription: FC<IOrderDescriptionProps> = ({
  setIsPhotoAdding,
  isPhotoAdding,
}) => {
  const description = useAppSelector((state) => state.ordering.description);
  const price = useAppSelector((state) => state.ordering.price);
  const photos = useAppSelector((state) => state.ordering.photos);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (photos?.length) {
      setIsPhotoAdding(true);
    }
    return () => {
      setIsPhotoAdding(false);
    };
  }, []);

  const handleDescriptionChange = (text: string) => {
    dispatch(setDescriptionText(text));
  };

  const handlePhotosChange = (photos: FileList | null) => {
    if (photos) {
      dispatch(addPhotos(Array.from(photos)));
    }
  };

  const handleNextOrderState = () => {
    dispatch(nextOrderStep());
  };

  return (
    <div>
      <Title className="mb-[15px]">Описание</Title>
      <ul className="mb-[5px] flex flex-wrap">
        {["Поддон кирпича", "Стекло", "Поддон плиты", "Поддон"].map((tag) => (
          <button
            onClick={() => handleDescriptionChange(tag)}
            key={tag}
            className="mr-[10px] mb-[10px] whitespace-nowrap rounded bg-white p-[5px] text-xs text-current transition border border-current focus:bg-lightWhiteGray focus:border-lightWhiteGray hover:text-primary hover:border-primary"
          >
            {tag}
          </button>
        ))}
      </ul>
      <TextArea
        className="mb-[25px] h-[180px] w-full resize-none"
        placeholder="Опишите задачу"
        value={description}
        onChange={(e) => handleDescriptionChange(e.target.value)}
      />
      <button
        className="ml-auto mb-[10px] block font-bold text-primary outline-none transition hover:text-black focus:text-black active:text-primary"
        onClick={() => setIsPhotoAdding(!isPhotoAdding)}
      >
        + Добавить фото
      </button>
      {isPhotoAdding && (
        <div className="mx-[15px] mb-[25px]">
          {/*<Title className="mb-[15px]">Перетяните фото для загрузки</Title>*/}
          <PhotoInput
            multiple
            withoutPreview
            onChange={(e) => handlePhotosChange(e.target.files)}
          />
        </div>
      )}
      <div className="grid grid-cols-2 items-center gap-[15px] ">
        {price && <Title className="text-xl">{`${price} руб.`}</Title>}
        <Button className="w-full" onClick={handleNextOrderState}>
          Продолжить
        </Button>
      </div>
    </div>
  );
};
