import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { Input } from "../../../common/components/Input/Input";
import { RegistrationFormValues } from "../../../common/models/forms/RegistrationFormValues";
import { useUpdateUserMutation } from "../../../services/userApi";
import { getErrorMessage } from "common/utils/helpers";

export const RegistrationDop: FC = () => {
  const navigate = useNavigate();

  const userId = useAppSelector((state) => state.user.current?.id);
  const userName = useAppSelector((state) => state.user.current?.first_name);

  const [registrate, { isLoading, error }] = useUpdateUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormValues>();

  useEffect(() => {
    if (!userId) navigate("/auth");
    if (userId && userName) navigate("/auth/onboarding");
  }, [userId, userName]);

  const onSubmitOriginal = handleSubmit((data) => {
    if (userId) {
      registrate({
        user_id: userId,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email || undefined,
        middle_name: data.middle_name || undefined,
      });
    }
  });

  return (
    <form onSubmit={onSubmitOriginal}>
      <p className="mb-[30px] text-center text-base text-lightGray">
        Чтобы продолжить вам необходимо заполнить обязательные данные.
      </p>
      <div className="mb-[15px] w-full">
        <label>
          <Input
            {...register("last_name", {
              required: { value: true, message: "Данное поле обязательно" },
            })}
            invalid={!!errors.last_name}
            invalidMessage={errors.last_name?.message}
            label="Имя"
            className="w-full"
            type="text"
            placeholder="Введите имя"
          />
        </label>
      </div>
      <div className="mb-[15px] w-full">
        <label>
          <Input
            {...register("first_name", {
              required: { value: true, message: "Данное поле обязательно" },
            })}
            invalid={!!errors.first_name}
            invalidMessage={errors.first_name?.message}
            label="Фамилия"
            className="w-full"
            type="text"
            placeholder="Введите фамилию"
          />
        </label>
      </div>
      <div className="mb-[15px] w-full">
        <label>
          <Input
            {...register("middle_name")}
            invalid={!!errors.middle_name}
            invalidMessage={errors.middle_name?.message}
            label="Отчество"
            className="w-full"
            type="text"
            placeholder="Введите отчество"
          />
        </label>
      </div>
      <div className="mb-[15px] w-full">
        <label>
          <Input
            {...register("email", {
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "Неправильный формат почты",
              },
            })}
            invalid={!!errors.email}
            invalidMessage={errors.email?.message}
            label="Почта"
            autoComplete="email"
            className="w-full"
            type="email"
            placeholder="Введите почту"
          />
        </label>
      </div>
      {error && (
        <p className="mb-[25px] text-center text-xs text-red">
          {getErrorMessage(error)?.data.message ||
            "Произошла непредвиденная ошибка, повторите попытку позже"}
        </p>
      )}
      <div className="mb-[25px] text-center text-xs text-graySecond">
        Нажимая кнопку, вы подтверждаете свое согласие на
        <br />
        <NavLink
          target="_blank"
          rel="noopener noreferrer"
          to="/privacy-policy"
          className="cursor-pointer text-black"
        >
          обработку персональных данных.
        </NavLink>
      </div>
      <Button
        className="h-[45px] w-full px-0 text-base leading-[15px]"
        type="submit"
        disabled={isLoading}
        loader={isLoading}
      >
        Продолжить
      </Button>
    </form>
  );
};
