import { FC, memo } from "react";
import { IColorPalette } from "../../models/commonModels/IColorPalette";
import { IIconButtonProps } from "common/models/components/ui/IButtonProps";

export const IconButton: FC<IIconButtonProps> = memo((props) => {
  const { icon, iconPlacement = "right" } = props;

  const userClasses = props.className || "";

  return (
    <button
      {...props}
      className={`flex items-center rounded px-[13px] py-[10px] text-sm tracking-wider outline-none transition ${colorPalette.normal} ${colorPalette.hover} ${colorPalette.active} ${colorPalette.focus} ${colorPalette.disabled} ${userClasses}`}
    >
      {iconPlacement === "right" && <span>{props.children}</span>}
      {icon}
      {iconPlacement === "left" && (
        <div className="w-full">{props.children}</div>
      )}
    </button>
  );
});

const colorPalette: IColorPalette = {
  normal: "bg-white text-black border border-green",
  hover: "hover:bg-lightWhiteGray hover:text-black hover:border-lightWhiteGray",
  active: "active:bg-white active:text-black active:border-green",
  focus: "focus:bg-lightWhiteGray focus:text-black focus:border-lightWhiteGray",
  disabled:
    "disabled:bg-lightGray disabled:text-white disabled:border-lightGray",
};
