import { FC, useState, useEffect } from "react";
import { PhoneButton } from "../../common/components/Button/PhoneButton";
import { Title } from "../../common/components/Title/Title";
import { CategoryChoose } from "./CategoryChoose";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { TypeChoose } from "./TypeChoose";
import { VehicleTypeSelection } from "../../common/components/Vehicle/VehicleTypeSelection";
import { IManipulatorCharacteristics } from "../../common/models/commonModels/IManipulatorCharacteristics";
import { useGetVehicleTypesQuery } from "../../services/vehicle/vehicleTypesApi";
import {
  prevOrderStep,
  setCardId,
  setOrderingMode,
  setManualVehicleCharacteristics,
  setOrderPrice,
  setVehicleTypeId,
  OrderingModeType,
  resetOrdering,
  OrderingState,
  nextOrderStep,
  setOrderState,
  setCreatedOrderId,
} from "./orderingSlice";
import {
  getSuitableVehicleTypes,
  timeFormatter,
} from "../../common/utils/helpers";
import { AddressesChoose } from "./AddressesChoose";
import { TimeChoose } from "./TimeChoose";
import { OrderDescription } from "./OrderDescription";
import { LoadedImages } from "./LoadedImages";
import { ContactInformation } from "./ContactInformation";
import { SvgArrowInCircle } from "../../assets/icons/SvgArrowInCircle";
import { OrderCreated } from "./OrderCreated";
import { PaymentMethodChoose } from "./PaymentMethodChoose";
import { AddBankCard } from "../../common/components/BankCard/AddBankCard";
import {
  COMPANY_CATEGORY,
  MODAL_TYPES,
  ORDER_STEP,
  PAYMENT_METHOD,
} from "common/utils/consts";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { CardsList } from "./CardsList";
import { InvoiceForm } from "common/components/invoice/InvoiceForm";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";
import { useCreateOrderMutation } from "services/orderApi";
import { useGetCompanyByIdQuery } from "services/companiesApi";
import { useUploadImagesMutation } from "services/fileApi";

const orderStepsArr = Object.getOwnPropertyNames(ORDER_STEP);

export const OrderingPage: FC<{ onOrderShow?: (id: number) => void }> =
  withErrorBoundary(({ onOrderShow }) => {
    const dispatch = useAppDispatch();

    const userId = useAppSelector((state) => state.user.current?.id);
    const currentOrderStep = useAppSelector(
      (state) => state.ordering.orderStep
    );
    const createdOrderId = useAppSelector(
      (state) => state.ordering.createdOrderId
    );
    const manualVehicleCharacteristics = useAppSelector(
      (state) => state.ordering.manualVehicleCharacteristics
    );
    const defaultCardId = useAppSelector(
      (state) => state.user.current?.default_bank_card?.id
    );
    const vehicleCategoryId = useAppSelector(
      (state) => state.ordering.vehicleCategoryId
    );
    const currentCompanyId = useAppSelector(
      (state) => state.user.current?.account.company?.id
    );
    const photos = useAppSelector((state) => state.ordering.photos);
    const orderingMode = useAppSelector((state) => state.ordering.orderingMode);
    const bankCardId = useAppSelector((state) => state.ordering.bankCardId);
    const paymentMethod = useAppSelector(
      (state) => state.ordering.paymentMethod
    );

    const [isChangingPaymentMethod, setIsChangingPaymentMethod] =
      useState<boolean>(!!bankCardId);
    const [isWorkTime, setIsWorkTime] = useState<boolean>(false);
    const [isPhotoAdding, setIsPhotoAdding] = useState<boolean>(false);
    const [isCreatingInvoice, setIsCreatingInvoice] = useState<boolean>(false);

    const { data: types } = useGetVehicleTypesQuery([vehicleCategoryId || -1], {
      skip: !vehicleCategoryId,
    });
    const [createOrder, { isLoading: isCreatingOrderLoading, data }] =
      useCreateOrderMutation();
    const [
      uploadImages,
      { isLoading: isUploadingImagesLoading, error: uploadingImagesError },
    ] = useUploadImagesMutation();

    const { data: currentCompany, isLoading: isCurrentCompanyLoading } =
      useGetCompanyByIdQuery(currentCompanyId || -1, {
        skip: !currentCompanyId,
      });

    const currentOrderStepIndex = orderStepsArr.indexOf(currentOrderStep);

    const { showModal } = useGlobalModalContext();

    useEffect(() => {
      if (defaultCardId) {
        if (
          currentCompany?.category.id === COMPANY_CATEGORY.CUSTOMER ||
          paymentMethod === "cash"
        ) {
          dispatch(setCardId(undefined));
        } else {
          dispatch(setCardId(defaultCardId));
        }
      }
    }, [currentCompany?.category.id, paymentMethod]);

    useEffect(() => {
      if (data?.id) {
        dispatch(setCreatedOrderId(data.id));
      }
    }, [data]);

    useEffect(() => {
      if (currentOrderStep === ORDER_STEP.ORDER_CREATED.value) {
        dispatch(resetOrdering());
      }
    }, [userId]);

    useEffect(() => {
      if (uploadingImagesError) {
        showErrorModal(
          "Ошибка при загрузке изображений",
          "При загрузке изображений произошла ошибка, они не будут добавлены в заказ"
        );
      }
    }, [uploadingImagesError]);

    const showErrorModal = (title: string, subTitle: string) => {
      showModal(MODAL_TYPES.ERROR_MODAL, {
        title,
        subTitle,
      });
    };

    const isFistStep = currentOrderStepIndex === 0;
    const isLastStep = currentOrderStepIndex === orderStepsArr.length - 1;

    const handleOrderCreate = async (orderingData: OrderingState) => {
      const deliveryDate = orderingData.vehicleDeliveryDay
        ? orderingData.vehicleDeliveryTime
          ? timeFormatter({
              time: new Date(
                +new Date(
                  orderingData.vehicleDeliveryDay.replaceAll("-", "/")
                ) +
                  (orderingData.vehicleDeliveryTime || 0) * 60
              ),
              mode: "yyyy-MM-dd hh:mm:ss",
            })
          : timeFormatter({
              time: new Date(
                orderingData.vehicleDeliveryDay.replaceAll("-", "/")
              ),
              mode: "yyyy-MM-dd",
            })
        : undefined;

      let images;

      if (orderingData.photos) {
        const formData = new FormData();
        for (let x = 0; x < orderingData.photos.length; x++) {
          formData.append(`files[${x}][file_type_id]`, "6");
          formData.append(`files[${x}][file]`, orderingData.photos[x]);
        }
        images = await uploadImages(formData).unwrap();
      }

      const createdOrder = await createOrder({
        addresses: orderingData.address,
        vehicle_category_id: orderingData.vehicleCategoryId,
        vehicle_type_id: orderingData.vehicleTypeId,
        payment_method_id:
          currentCompany?.category.id === COMPANY_CATEGORY.CUSTOMER
            ? PAYMENT_METHOD.COMPANY_BALANCE
            : orderingData.bankCardId
            ? PAYMENT_METHOD.CARD
            : PAYMENT_METHOD.CASH,
        is_manual: orderingData.orderingMode !== "manager",
        use_bonuses: orderingData.useBonuses,
        description: orderingData.description,
        bank_card_id: orderingData.bankCardId,
        options:
          orderingData.manualVehicleCharacteristics.optionalEquipment?.map(
            (option) => option.id
          ),
        photos: images?.map((image) => image.id),
        datetime: deliveryDate,
        first_name: orderingData.user.firstName,
        middle_name: orderingData.user.middleName || undefined,
        last_name: orderingData.user.lastName,
        phone: orderingData.user.phone,
      }).unwrap();
      if (createdOrder.id) {
        handleNextOrderState();
      } else {
        showErrorModal(
          "Ошибка при создании заказа",
          "При создании заказа произошла ошибка, повторите попытку позже"
        );
        dispatch(setOrderState(ORDER_STEP.VEHICLE_CATEGORY_CHOOSE.value));
      }
    };

    const handlePrevOrderState = () => {
      dispatch(prevOrderStep());
    };

    const handleNextOrderState = () => {
      dispatch(nextOrderStep());
    };

    const handleSetOrderingMode = (mode: OrderingModeType) => {
      dispatch(setOrderingMode(mode));
    };

    const handleSetIsWorkTime = (state: boolean) => {
      setIsWorkTime(state);
    };

    const handleSetIsPhotoAdding = (state: boolean) => {
      setIsPhotoAdding(state);
    };
    const handleSetIsCreatingInvoiceg = (state: boolean) => {
      setIsCreatingInvoice(state);
    };

    const handleSetIsChangingPaymentMethod = (state: boolean) => {
      setIsChangingPaymentMethod(state);
    };

    const handleVehicleTypeIdChange = (id: number) => {
      dispatch(setVehicleTypeId(id));
    };

    const handlePriceChange = (price: number) => {
      dispatch(setOrderPrice(price));
    };

    const handleVehicleTypeChoose = (data: IManipulatorCharacteristics) => {
      dispatch(setManualVehicleCharacteristics(data));

      if (types) {
        const suitableType = getSuitableVehicleTypes(types, data);
        if (suitableType?.length) {
          handleVehicleTypeIdChange(suitableType[0].id);
          handlePriceChange(
            suitableType[0].price +
              data.optionalEquipment?.reduce(
                (curr, next) => curr + next.price,
                0
              )
          );
        } else {
          showErrorModal(
            "Не найдено подходящей техники",
            "К сожалению мы не нашли технику, соответствующую вашим требованиям"
          );
        }
      }
    };

    const handleCloseVehicleTypeManualChoosing = () => {
      dispatch(setOrderingMode("manual"));
    };

    const handleSetActiveCardId = (id: number) => {
      dispatch(setCardId(id));
    };

    const isManualVehicleTypeChoosing =
      orderingMode === "manualVehicleChoosing";

    return (
      <div className="pointer-events-none absolute top-0 bottom-0 left-0 right-0 z-10 bg-transparent p-[15px]">
        <div className="relative grid h-full grid-cols-3">
          <div className="pointer-events-auto relative flex max-h-[100%] flex-col  overflow-y-auto">
            {!isFistStep && !isLastStep && (
              <button
                className="absolute top-[10px] left-[10px] z-10 h-[32px] w-[32px] rotate-180 text-black
              outline-none transition hover:text-primary focus:text-primary active:text-black"
                onClick={handlePrevOrderState}
              >
                <SvgArrowInCircle className="text-inherit" />
              </button>
            )}
            <div className="h-fit rounded bg-white p-[15px]">
              <Title className="mb-[15px] text-center">Заказать технику</Title>
              <div className="mb-[25px] text-center text-sm text-lightGray">
                {ORDER_STEP[currentOrderStep].label}
              </div>
              <div className="mb-[25px] flex justify-center gap-[3px]">
                {orderStepsArr.map((step, index) => (
                  <div
                    key={step}
                    className={`block h-[4px] w-[30px] rounded transition ${
                      index <= currentOrderStepIndex
                        ? "bg-green"
                        : "bg-lightWhiteGray"
                    }`}
                  />
                ))}
              </div>
              {currentOrderStep ===
                ORDER_STEP.VEHICLE_CATEGORY_CHOOSE.value && <CategoryChoose />}
              {currentOrderStep === ORDER_STEP.VEHICLE_TYPE_CHOOSE.value &&
                vehicleCategoryId && (
                  <TypeChoose categoryId={vehicleCategoryId} />
                )}
              {currentOrderStep === ORDER_STEP.DESCRIPTION.value && (
                <OrderDescription
                  isPhotoAdding={isPhotoAdding}
                  setIsPhotoAdding={handleSetIsPhotoAdding}
                />
              )}
              {currentOrderStep === ORDER_STEP.ADDRESS_CHOOSE.value && (
                <AddressesChoose
                  isWorkTime={isWorkTime}
                  setIsWorkTime={handleSetIsWorkTime}
                />
              )}
              {currentOrderStep === ORDER_STEP.CONTACT_INFORMATION.value && (
                <ContactInformation
                  isChangingPaymentMethod={isChangingPaymentMethod}
                  setIsChangingPaymentMethod={handleSetIsChangingPaymentMethod}
                  isCreatingInvoice={isCreatingInvoice}
                  setIsCreatingInvoice={handleSetIsCreatingInvoiceg}
                  createOrder={handleOrderCreate}
                  isLoading={
                    isCreatingOrderLoading ||
                    isCurrentCompanyLoading ||
                    isUploadingImagesLoading
                  }
                  currentCompany={currentCompany}
                />
              )}
              {currentOrderStep === ORDER_STEP.ORDER_CREATED.value &&
                createdOrderId && (
                  <OrderCreated
                    onOrderShow={onOrderShow}
                    createdOrderId={createdOrderId}
                  />
                )}
            </div>
          </div>
          {!(
            isChangingPaymentMethod &&
            currentOrderStep === ORDER_STEP.CONTACT_INFORMATION.value
          ) &&
            !(
              isManualVehicleTypeChoosing &&
              currentOrderStep === ORDER_STEP.VEHICLE_TYPE_CHOOSE.value
            ) &&
            !(
              isWorkTime && currentOrderStep === ORDER_STEP.ADDRESS_CHOOSE.value
            ) &&
            !(
              photos?.length &&
              currentOrderStep === ORDER_STEP.DESCRIPTION.value
            ) &&
            !(
              isCreatingInvoice &&
              currentOrderStep === ORDER_STEP.CONTACT_INFORMATION.value
            ) && (
              <div className="pointer-events-auto h-fit">
                <PhoneButton className="ml-[15px]" />
              </div>
            )}
          {isManualVehicleTypeChoosing &&
            currentOrderStep === ORDER_STEP.VEHICLE_TYPE_CHOOSE.value &&
            vehicleCategoryId && (
              <div className="pointer-events-auto col-span-2 h-fit max-h-[100%] bg-white p-[15px]">
                <VehicleTypeSelection
                  vehicleCategoryId={vehicleCategoryId}
                  onClose={() => {
                    handleSetOrderingMode("manual");
                    handleCloseVehicleTypeManualChoosing();
                  }}
                  defaultValues={manualVehicleCharacteristics}
                  onSubmit={handleVehicleTypeChoose}
                />
              </div>
            )}
          {isWorkTime &&
            currentOrderStep === ORDER_STEP.ADDRESS_CHOOSE.value && (
              <TimeChoose />
            )}
          {photos?.length &&
            isPhotoAdding &&
            currentOrderStep === ORDER_STEP.DESCRIPTION.value && (
              <LoadedImages />
            )}

          {isChangingPaymentMethod &&
            currentOrderStep === ORDER_STEP.CONTACT_INFORMATION.value && (
              <PaymentMethodChoose />
            )}
          {isCreatingInvoice &&
            userId &&
            currentOrderStep === ORDER_STEP.CONTACT_INFORMATION.value && (
              <div className="pointer-events-auto h-fit bg-white p-[15px]">
                <Title className="mb-[15px] text-center">
                  Пополнение счёта
                </Title>
                <InvoiceForm />
              </div>
            )}

          {isChangingPaymentMethod &&
            currentOrderStep === ORDER_STEP.CONTACT_INFORMATION.value &&
            paymentMethod === "card" &&
            userId && (
              <CardsList
                activeCardId={bankCardId}
                handleSetActiveCardId={handleSetActiveCardId}
              />
            )}
          {isChangingPaymentMethod &&
            currentOrderStep === ORDER_STEP.CONTACT_INFORMATION.value &&
            paymentMethod === "card" &&
            !userId && <AddBankCard />}
        </div>
      </div>
    );
  });
