import { QueryLayout } from "common/components/Layout/QueryLayout";
import { Title } from "common/components/Title/Title";
import { NavLink } from "react-router-dom";
import { useGetAboutUsQuery } from "services/appApi";

export const AboutServicePage = () => {
  const { data, isLoading, error, isFetching } = useGetAboutUsQuery();

  return (
    <section className="absolute top-0 bottom-0 left-0 right-0 z-10 flex h-full flex-col bg-white py-[20px] px-[15px]">
      <Title>О сервисе</Title>
      <div className="mb-[15px] text-sm text-gray">
        <NavLink to="/">Главная</NavLink> / О сервисе
      </div>
      <div className="grid gap-[15px] rounded border border-lightWhiteGray p-[15px] leading-[17px] text-lightGray max-w-[800px]">
        <QueryLayout
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
        >
          <p>{data?.content}</p>
        </QueryLayout>
      </div>
    </section>
  );
};
