import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AboutServicePage } from "pages/faq/aboutService/AboutServicePage";
import { FaqPage } from "pages/faq/faq/FaqPage";
import { UseRulesPage } from "pages/faq/useRules/UseRulesPage";
import { PoliticsPage } from "pages/faq/politics/PoliticsPage";
import { ProfilePage } from "features/user/profile/ProfilePage";
import { CustomerCompanyManagerSidebar } from "features/customerCompanyManager/sidebar/CustomerCompanyManagerSidebar";
import { CustomerCompanyManagerHeader } from "features/customerCompanyManager/header/CustomerCompanyManagerHeader";
import { OrdersPage } from "features/customerCompanyManager/orders/OrdersPage";
import { HomePage } from "features/customerCompanyManager/home/HomePage";
import { AppealPage } from "features/customerCompanyManager/appeal/AppealPage";
import { EmployeesPage } from "features/customerCompanyManager/employees/EmployeesPage";
import { CompaniesPage } from "features/customerCompanyManager/companies/CompaniesPage";
import { APPLICATIONS } from "common/utils/consts";
import { InvoicesPage } from "features/customerCompanyManager/invoices/InvoicesPage";
import { TransactionsPage } from "features/customerCompanyManager/transactions/TransactionsPage";
import { Map } from "common/components/Map/Map";
import { useAppSelector } from "app/hooks";

export const CustomerCompanyManagerPage: FC = () => {
  const route = useAppSelector(
    (state) => state.customerCompanyManager.map.route
  );

  return (
    <>
      <CustomerCompanyManagerSidebar />
      <div className="h-full w-full">
        <CustomerCompanyManagerHeader />
        <main className="relative h-full min-h-[calc(100vh-90px)]">
          <Routes>
            <Route path="" element={<HomePage />} />
            <Route path="/about" element={<AboutServicePage />} />
            <Route
              path="/faq"
              element={<FaqPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route
              path="/use-rules"
              element={<UseRulesPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route
              path="/privacy-policy"
              element={<PoliticsPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/invoices" element={<InvoicesPage />} />
            <Route path="/transactions" element={<TransactionsPage />} />
            <Route path="/support" element={<AppealPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
            <Route path="/companies" element={<CompaniesPage />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
          <Map route={route} />
        </main>
      </div>
    </>
  );
};
