import { FC, useState, useEffect } from "react";
import { Button } from "common/components/Button/Button";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { SvgSuccess } from "assets/icons/SvgSuccess";
import { Title } from "common/components/Title/Title";
import { SvgStar } from "assets/icons/SvgStar";
import { useUpdateOrderMutation } from "services/orderApi";

export const OrderFinished: FC<{
  price: number;
  orderId: number;
  rate?: number;
}> = ({ price, orderId, rate }) => {
  const [rating, setRating] = useState<number>(rate || 0);

  const [updateOrder, { isLoading: isOrderUpdateLoading }] =
    useUpdateOrderMutation();

  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    if (rate) {
      setRating(rate);
    }
  }, [rate]);

  const showDisputeModal = () => {
    showModal(MODAL_TYPES.DISPUTE_MODAL, {
      orderId,
    });
  };

  return (
    <div className="flex flex-col items-center">
      <SvgSuccess className="mb-[25px] h-[50px] w-[50px]" />
      <div className="mb-[15px] text-lg">Заказ завершен</div>
      <span className="mb-[15px] text-xs font-semibold text-lightGray">
        Сумма заказа
      </span>
      <Title className="mb-[15px]">{`${price} руб.`}</Title>
      <span className="mb-[20px] text-xs font-semibold text-lightGray">
        {rate ? "Ваша оценка" : "Оценить поездку"}
      </span>
      <div className="mb-[20px] grid grid-cols-5 gap-[20px]">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            disabled={!!rate}
            className={`transition ${
              star <= rating
                ? "fill-primary text-primary"
                : "fill-transparent text-gray"
            }  ${
              !rate
                ? "hover:fill-primary hover:text-primary focus:fill-primary focus:text-primary"
                : ""
            }`}
            onClick={() => setRating(star)}
          >
            <SvgStar key={star} className="h-[30px] w-[30px] fill-inherit" />
          </button>
        ))}
      </div>
      <div className="grid grid-cols-2 gap-[10px]">
        <Button
          className={rate ? "col-span-2" : ""}
          mode="darkBorder"
          onClick={showDisputeModal}
        >
          Оспорить
        </Button>
        {!rate && (
          <Button
            disabled={isOrderUpdateLoading || !rating}
            loader={isOrderUpdateLoading}
            onClick={() => updateOrder({ orderId, rate: rating })}
          >
            Оценить заказ
          </Button>
        )}
      </div>
    </div>
  );
};
