import { FC, useState, useMemo } from "react";
import { SvgCreditCard } from "../../../assets/icons/SvgCreditCard";
import { SvgMapPin } from "../../../assets/icons/SvgMapPin";
import { OrderItemDetails } from "./OrderItemDetails";
import { timeFormatter } from "../../utils/helpers";
import { Button } from "../Button/Button";
import { IOrderItemProps } from "common/models/components/order/IOrderItemProps";

import "swiper/css";

export const OrderItemCard: FC<IOrderItemProps> = ({
  order,
  withTracking,
  withDetails,
  withAddresses,
  onVehicleAssign,
  isActive,
  isOrderList
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);

  const statusBg = {
    1: "bg-blue",
    2: "bg-blue",
    3: "bg-blue",
    4: "bg-red",
    5: "bg-blue",
    6: "bg-blue",
    7: "bg-blue",
    8: !order.documents?.length ? "bg-primary" : "bg-green",
    9: "bg-red"
  }

  const currentOrderDate = useMemo(
    () =>
      timeFormatter({
        time: new Date(order.datetime || order.created_at),
        mode: "dd-Mth-yyyy",
      }),
    [order.datetime, order.created_at]
  );
  const formattedCurrentTime = useMemo(
    () =>
      timeFormatter({
        time: new Date(order.datetime || order.created_at),
        mode: "hh:mm",
      }),
    [order.datetime, order.created_at]
  );
  return (
    <div
      className={`relative ${
        isDetailsOpen || !withDetails
          ? "h-auto p-[16px]"
          : "h-fit px-[30px] pt-[30px] pb-[10px]"
      } 
        ${
          isActive ? "border-green" : "border-whiteGray"
        } rounded border bg-white 
        transition focus-within:border-primary hover:border-primary`}
      onClick={() => {
        if (isOrderList && !isDetailsOpen) {
          setIsDetailsOpen((prev) => !prev)
        }
      }}
    >
      <div className={`flex ${withAddresses && "mb-[10px]"}`}>
        <div className="mr-[15px]">
          <img
            className="h-[30px] object-cover"
            src={order.vehicle_category.icon.path}
            alt={order.vehicle_category.name}
          />
        </div>
        <div className="grid items-center gap-[1px] text-left">
          <div className="text-base font-medium leading-4">
            Заказ №{order.id}
          </div>
          <div className="text-sm font-medium leading-4 text-gray">
            {order.vehicle_type?.name || "Ещё не назначено"}
          </div>
          <div className={`text-xs text-center max-w-max leading-4 font-semibold text-white py-[1.5px] px-[4px] rounded ${statusBg[order.status.id as keyof typeof statusBg] ? statusBg[order.status.id as keyof typeof statusBg]: `bg-primary`}`}>
            {(order.status.id === 8 && !order.documents?.length) ? "Путевой лист" :order.status.name}
          </div>
        </div>
        <div className="flex flex-1 flex-col items-end">
          {withAddresses ? (
            <>
              <time className="mb-[5px] text-xs text-lightGray">
                {currentOrderDate}
              </time>
              <time className="mb-[5px] text-xs text-lightGray">
                {formattedCurrentTime}
              </time>
              <div className="flex items-center text-sm font-semibold leading-4 lg:text-sm xl:text-lg">
                <SvgCreditCard className="mr-[7px] h-[15px] w-[15px] lg:h-[20px] xl:w-[20px]" />
                {`${Math.round(order.price)} руб.`}
              </div>
            </>
          ) : (
            <>
              <div>
                <time className="mb-[5px] text-xs text-lightGray">
                  {currentOrderDate}
                </time>
              </div>
              <ul className="mb-[10px]">
                <li key={order.addresses[0].id} className="overflow-hidden">
                  <div className="flex items-center text-left text-black ">
                    <div>
                      <SvgMapPin className="mr-[5px] h-[12px] w-[10px] text-black" />
                    </div>
                    <address className="text-xs not-italic">
                      {order.addresses[0].name}
                    </address>
                  </div>
                </li>
              </ul>
              <div className="flex items-center font-semibold leading-4 md:text-sm lg:text-lg">
                <SvgCreditCard className="mr-[7px] h-[20px] w-[20px]" />
                {`${Math.round(order.price)} руб.`}
              </div>
            </>
          )}
        </div>
      </div>
      {isDetailsOpen ? (
        <OrderItemDetails
          orderId={order.id}
          withTracking={withTracking}
          onVehicleAssign={onVehicleAssign}
        />
      ) : (
        withAddresses && (
          <ul className="mb-[10px] grid grid-cols-2 gap-[5px]">
            {order.addresses.map((address) => (
              <li key={address.id} className="overflow-hidden">
                <div className="flex items-center text-left text-gray">
                  <div>
                    <SvgMapPin className="mr-[5px] h-[12px] w-[10px] text-black" />
                  </div>
                  <address className="text-xs not-italic">
                    {address.name}
                  </address>
                </div>
              </li>
            ))}
          </ul>
        )
      )}
      {withDetails && (
          !isOrderList ?
            <div>
              <button
                className="ml-auto block rounded bg-transparent py-[10px] pl-[23px] text-[13px] font-semibold text-primary outline-none transition hover:text-black focus:text-black active:text-primary"
                onClick={() => setIsDetailsOpen((prev) => !prev)}
              >
                {isDetailsOpen ?
                    "- Свернуть детали" :
                    "Детали заказа"}
              </button>
            </div> :
            <div>
              {isDetailsOpen ?
                  <Button className="mt-[15px]"
                  onClick={() => setIsDetailsOpen((prev) => !prev)}>Свернуть детали</Button>
                  :
                  <button
                      className="ml-auto block rounded bg-transparent py-[10px] pl-[23px] text-[13px] font-semibold text-primary outline-none transition hover:text-black focus:text-black active:text-primary"
                  >
                    Детали заказа
                  </button>
              }
            </div>
      )}
    </div>
  );
};
