import { FC, useMemo } from "react";
import { timeFormatter } from "../../utils/helpers";
import { Slider } from "../Slider/Slider";
import { useGetInvoiceByIdQuery } from "services/invoicesApi";
import { QueryLayout } from "../Layout/QueryLayout";

export const InvoiceDetails: FC<{ invoiceId: number }> = ({ invoiceId }) => {
  const {
    data: invoice,
    isLoading: isInvoiceLoading,
    error: invoiceError,
  } = useGetInvoiceByIdQuery({ id: invoiceId });

  const invoiceDate = useMemo(
    () =>
      timeFormatter({
        time: new Date(invoice?.created_at || ""),
        mode: "dd-Mth-yyyy hh:mm",
      }),
    [invoice?.created_at]
  );

  return (
    <QueryLayout isLoading={isInvoiceLoading} error={invoiceError}>
      {invoice && (
        <div className={`grid gap-[10px]`}>
          <div className="grid gap-[8px] rounded border border-whiteGray p-[15px]">
            <div
              className={`flex items-center justify-between text-base font-medium ${
                invoice.amount > 0 && "text-primary"
              }`}
            >
              <div className="mr-[10px]">
                {`${invoice.amount > 0 && "+"} ${invoice.amount} руб.`}
              </div>
              <time className="text-xs text-lightGray">{invoiceDate}</time>
            </div>
            <div className="flex items-center justify-between text-sm">
              <div className="font-medium text-lightGray">Статус:</div>
              <div className="text-primary">{invoice.status.name}</div>
            </div>
            <div className="flex items-center justify-between text-sm">
              <div className="font-medium text-lightGray">Email:</div>
              <a href="mailto:test@gmail.com" className="text-primary">
                {invoice.email}
              </a>
            </div>
            <p className="text-left text-xs text-lightGray">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus
              voluptatibus hic deleniti, dolore ad possimus porro ea, iste dolor
              nobis voluptates earum libero a necessitatibus praesentium sunt.
              Sapiente, adipisci sunt?
            </p>
          </div>

          <div className="overflow-hidden rounded">
            <h4 className="mb-[10px] text-sm font-semibold text-gray">
              Документы
            </h4>
            <object
              data={invoice.file.path}
              type="application/pdf"
              width="100%"
              height="250px"
            >
              <p>
                <a href={invoice.file.path}>to the PDF!</a>
              </p>
            </object>
            {/* <Slider expandable images={[]} /> */}
          </div>
        </div>
      )}
    </QueryLayout>
  );
};
