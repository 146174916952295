import { FC, useMemo } from "react";
import { timeFormatter } from "../../utils/helpers";
import { IInvoiceCardProps } from "common/models/components/IInvoiceCardProps";

export const InvoiceCard: FC<IInvoiceCardProps> = ({
  transactionId,
  time,
  status,
  amount,
  desc,
  orderId,
  statusId,
  ...props
}) => {
  const userClasses = props.className || "";

  const isPositiveAmount = amount > 0;

  const statusBg = {
    1: "bg-blue",
    2: "bg-green",
    3: "bg-red"
  }

  const transactionTime = useMemo(() => timeFormatter({ time }), [time]);

  return (
    <div
      {...props}
      className={`flex flex-col rounded border border-whiteGray p-[15px] ${userClasses}`}
    >
      <div className="mb-[3px] flex items-center justify-between">
        <h4 className="text-md font-medium">Счёт № {transactionId}</h4>
        <time className="mb-[5px] text-xs text-lightGray">
          {transactionTime}
        </time>
      </div>
      <div className="mb-[3px] flex items-center justify-between">
        <div className={`text-xs leading-4 font-semibold text-white py-[1.5px] px-[4px] rounded ${statusBg[statusId as keyof typeof statusBg] ? statusBg[statusId as keyof typeof statusBg]: `bg-primary`}`}>
          {status}
        </div>
        <div
          className={`flex text-base font-medium ${
            isPositiveAmount && "text-primary"
          }`}
        >
          <div>{`${isPositiveAmount && "+"} ${amount} руб.`}</div>
          {/* <button className="text-gray transition hover:text-primary focus:text-primary">
            <SvgDownload className="h-[15px] w-[15px]" />
          </button> */}
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-[5px] text-base text-lightGray">{desc}</div>
      </div>
    </div>
  );
};
