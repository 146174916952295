import { FC } from "react";
import { SvgPlus } from "../../../assets/icons/SvgPlus";
import { WideIconButton } from "../../../common/components/Button/WideIconButton";
import { Input } from "../../../common/components/Input/Input";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setVehicleSearchText,
  setIsAddingVehicle,
  setActiveVehicle,
} from "./vehiclesSlice";
import { Container } from "../../../common/components/Container/Container";
import { VehicleInfo } from "./VehicleInfo";
import { useGetCompanyVehiclesQuery } from "../../../services/vehicle/vehiclesApi";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { NotFoundTitle } from "../../../common/components/Title/NotFoundTitle";
import { VehicleList } from "common/components/Vehicle/VehicleList";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const VehiclesPage: FC = withErrorBoundary(() => {
  const currentCompanyId = useAppSelector(
    (state) => state.user.current?.account?.company?.id
  );
  const isAddingVehicle = useAppSelector(
    (state) => state.implementerCompanyManager.vehicles.isAddingVehicle
  );
  const activeVehicle = useAppSelector(
    (state) => state.implementerCompanyManager.vehicles.activeVehicle
  );
  const searchText = useAppSelector(
    (state) => state.implementerCompanyManager.vehicles.searchText
  );

  const {
    data: vehicles,
    isLoading,
    isFetching,
    error,
  } = useGetCompanyVehiclesQuery(
    {
      companyId: currentCompanyId || -1,
      query: searchText || undefined,
    },
    {
      skip: !currentCompanyId,
    }
  );

  const dispatch = useAppDispatch();

  const handleSearchText = (text: string) => {
    dispatch(setVehicleSearchText(text));
  };

  const handlerSetIsAddingVehicle = (isAdding: boolean) => {
    dispatch(setIsAddingVehicle(isAdding));
  };

  const handleSetActiveVehicle = ({
    id,
    categoryId,
  }: {
    id: number;
    categoryId: number;
  }) => {
    dispatch(setActiveVehicle({ id, categoryId }));
  };

  const haveVehicles = !!vehicles?.length;

  if (!currentCompanyId) return null;

  return (
    <Container>
      <div className="overflow-y-scroll">
        <div className="flex items-center justify-between">
          <h4 className="mb-[12px] text-md font-semibold">Моя техника</h4>
          {haveVehicles && (
            <button
              className="text-primary outline-none transition hover:text-black focus:text-black active:text-primary"
              onClick={() => handlerSetIsAddingVehicle(!isAddingVehicle)}
            >
              <SvgPlus className="h-[20px] w-[20px]" />
            </button>
          )}
        </div>
        <div className="sticky top-0">
          <Input
            className="mb-[15px] mt-[5px] w-full"
            placeholder="Поиск (минимум 3 символа)"
            value={searchText}
            onChange={(e) => handleSearchText(e.target.value)}
          />
        </div>
        <QueryLayout
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
        >
          {!haveVehicles && searchText.length >= 3 ? (
            <NotFoundTitle />
          ) : (
            !haveVehicles && (
              <WideIconButton
                className="w-full text-sm font-medium text-gray"
                icon={<SvgPlus className="h-[16px] w-[16px] text-primary" />}
                onClick={() => handlerSetIsAddingVehicle(!isAddingVehicle)}
              >
                У вас ещё ни одной единицы техники
              </WideIconButton>
            )
          )}
          {haveVehicles && (
            <VehicleList
              activeVehicleId={activeVehicle.id}
              onItemClick={handleSetActiveVehicle}
              vehicles={vehicles}
            />
          )}
        </QueryLayout>
      </div>
      <div className="overflow-y-scroll max-w-[800px]">
        <VehicleInfo
          vehicleId={activeVehicle.id}
          companyId={currentCompanyId}
        />
      </div>
    </Container>
  );
});
