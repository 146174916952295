import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { IndividualHeader } from "features/individual/header/IndividualHeader";
import { AboutServicePage } from "pages/faq/aboutService/AboutServicePage";
import { FaqPage } from "pages/faq/faq/FaqPage";
import { UseRulesPage } from "pages/faq/useRules/UseRulesPage";
import { PoliticsPage } from "pages/faq/politics/PoliticsPage";
import { ProfilePage } from "features/user/profile/ProfilePage";
import { APPLICATIONS } from "common/utils/consts";
import { DriverSidebar } from "features/driver/sidebar/DriverSidebar";

export const DriverPage: FC = () => {
  return (
    <>
      <DriverSidebar />
      <div className="h-full w-full">
        <IndividualHeader />
        <main className="relative h-full min-h-[calc(100vh-90px)]">
          <Routes>
            <Route path="/" element={<ProfilePage />} />
            <Route path="/about" element={<AboutServicePage />} />
            <Route
              path="/faq"
              element={<FaqPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route
              path="/use-rules"
              element={<UseRulesPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route
              path="/privacy-policy"
              element={<PoliticsPage applicationId={APPLICATIONS.CUSTOMER} />}
            />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      </div>
    </>
  );
};
