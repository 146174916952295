import { FC } from "react";
import { AppealCard } from "./AppealCard";
import { timeFormatter } from "../../utils/helpers";
import { IAppeal } from "../../models/appeals/IAppeal";
import { Title } from "../Title/Title";
import { IAppealListGeneratorProps } from "common/models/components/appeal/IAppealListGeneratorProps";

const appealListItemGenerator = (
  appeals: IAppeal[],
  currentAppeal: IAppeal,
  index: number,
  activeAppealId?: number,
  onItemClick?: (id: number) => void
) => {
  const currentOrderDate = new Date(currentAppeal.created_at);
  const currentOrderTime = timeFormatter({
    time: currentOrderDate,
    mode: "dd-Mth-yyyy",
  });

  const currentOrderElement = onItemClick ? (
    <button
      className="w-full"
      key={currentAppeal.id}
      onClick={() => onItemClick(currentAppeal.id)}
    >
      <AppealCard
        messageCount={currentAppeal.messages_count}
        isActive={activeAppealId === currentAppeal.id}
        appealId={currentAppeal.id}
        status={currentAppeal.status.name}
        time={new Date(currentAppeal.created_at)}
      />
    </button>
  ) : (
    <AppealCard
      key={currentAppeal.id}
      messageCount={currentAppeal.messages_count}
      isActive={activeAppealId === currentAppeal.id}
      appealId={currentAppeal.id}
      status={currentAppeal.status.name}
      time={new Date(currentAppeal.created_at)}
    />
  );

  const isCurrentDateDay = new Date().getDate() === currentOrderDate.getDate();

  if (index > 0) {
    const previousOrderDate = new Date(appeals[index - 1].created_at);
    if (currentOrderDate.getDate() !== previousOrderDate.getDate()) {
      return (
        <div className="grid w-full gap-[10px]" key={currentAppeal.id}>
          <Title>
            <time>{currentOrderTime}</time>
          </Title>
          {currentOrderElement}
        </div>
      );
    } else {
      return currentOrderElement;
    }
  }
  return (
    <div className="grid w-full gap-[10px]" key={currentAppeal.id}>
      <Title>{isCurrentDateDay ? "Здесь и сейчас" : currentOrderTime}</Title>
      {currentOrderElement}
    </div>
  );
};

export const AppealListGenerator: FC<IAppealListGeneratorProps> = ({
  appeals,
  onItemClick,
  activeAppealId,
  ...props
}) => {
  return (
    <div className="mb-[15px] grid h-fit gap-[12px]" {...props}>
      {appeals.map((appeal: IAppeal, i) =>
        appealListItemGenerator(appeals, appeal, i, activeAppealId, onItemClick)
      )}
    </div>
  );
};
