import { FC, memo, useEffect } from "react";
import { SvgMapPin } from "../../../assets/icons/SvgMapPin";
import { Input } from "../../../common/components/Input/Input";
import { TextArea } from "../../../common/components/TextArea/TextArea";
import { Button } from "../../../common/components/Button/Button";
import { SvgTrash } from "../../../assets/icons/SvgTrash";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { setReservedOrder } from "../../../features/implementerCompanyManager/orders/ordersSlice";
import { resetAssignment } from "../../../features/implementerCompanyManager/requests/requestsSlice";
import {
  useAttachOrderToCompanyMutation,
  useDetachOrderCompanyMutation,
  useDetachOrderVehicleMutation,
  useGetOrderByIdQuery,
} from "../../../services/orderApi";
import { Error } from "../Message/Error/Error";
import { QueryLayout } from "../Layout/QueryLayout";
import {
  COMPANY_CATEGORY,
  FILE_TYPE,
  MODAL_TYPES,
  ORDER_STATUS,
  USER_ROLE,
} from "../../utils/consts";
import { getErrorMessage } from "../../utils/helpers";
import { SvgRatingStar } from "../../../assets/icons/SvgRatingStar";
import { IOrderItemDetailsProps } from "common/models/components/order/IOrderItemDetailsProps";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { Slider } from "../Slider/Slider";
import { OrderMessage } from "common/components/OrderMessage/OrderMessage";
import { MaskedInput } from "../Input/MaskedInput";
import { useNavigate } from "react-router-dom";
import {IOrder} from "../../models/order/IOrder";

import "swiper/css";

export const OrderItemDetails: FC<IOrderItemDetailsProps> = memo(
  ({ orderId, onVehicleAssign, readOnly, withTracking, reserve }) => {
    const companyRole = useAppSelector(
      (state) => state.user.current?.account.company?.category.id
    );
    const currentCompanyId = useAppSelector(
      (state) => state.user.current?.account.company?.id
    );
    const userRoleId = useAppSelector(
      (state) => state.user.current?.account.role.id
    );
    const userCompanyCategoryId = useAppSelector(
      (state) => state.user.current?.account.company?.category.id
    );

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [
      detachVehicle,
      {
        isSuccess: isDetachingSuccess,
        error: detachVehicleError,
        isLoading: isDetachingVehicleLoading,
      },
    ] = useDetachOrderVehicleMutation();

    const [detachOrder, { isLoading: isDetaching, error: detachOrderError }] =
      useDetachOrderCompanyMutation();

    const [
      attachOrderToCompany,
      {
          error: attachError,
          isLoading: isAttachLoading
      },
    ] = useAttachOrderToCompanyMutation();

    const {
      data: order,
      isLoading,
      error,
    } = useGetOrderByIdQuery({ id: orderId });

    const { showModal } = useGlobalModalContext();

    const openConfirmationModal = (props: {
      subTitle?: string;
      confirmText?: string;
      onConfirm?: () => void;
    }) => {
      showModal(MODAL_TYPES.CONFIRM_MODAL, props);
    };

    const openOrderCancellationModal = () => {
      showModal(MODAL_TYPES.ORDER_CANCELATION_MODAL, {
        orderId,
      });
    };

    const openSuccessModal = () => {
      showModal(MODAL_TYPES.SUCCESS_MODAL, {
        title: "Техника успешно снята с заказа",
      });
    };

    const showDetachConfirmationModal = () => {
      showModal(MODAL_TYPES.CONFIRM_MODAL, {
        title: "Открепление заказа от компании",
        subTitle: "Вы уверены что хотите открепить данный заказ от компании?",
        onConfirm: () => {
            detachOrder(orderId)
            if (reserve) {
                dispatch(resetAssignment())
                dispatch(setReservedOrder(undefined))
                navigate("/");
            }
        },
      });
    };

    const openErrorModal = (message?: string) => {
      showModal(MODAL_TYPES.ERROR_MODAL, {
        title: "Ошибка",
        subTitle:
          message ||
          "При отправке запроса произошла ошибка, повторите попытку позже",
      });
    };

  const handleSetReservedOrder = (order: IOrder) => {
      dispatch(setReservedOrder(order))
  }
    useEffect(() => {
      if (isDetachingSuccess) {
        openSuccessModal();
      }
    }, [isDetachingSuccess]);

    useEffect(() => {
      const errorMessage = getErrorMessage([
        detachVehicleError,
        attachError,
        detachOrderError,
      ])?.data.message;

      if (errorMessage) {
        openErrorModal(errorMessage);
      }
    }, [detachVehicleError, attachError, detachOrderError]);

    const handleRemoveVehicleFromOrder = async () => {
      await detachVehicle({ orderId });
    };

    const isCompanySelecting =
      [ORDER_STATUS.IMPLEMENTING_COMPANY_SELECTION].includes(
        order?.status?.id || -1
      ) && companyRole === COMPANY_CATEGORY.IMPLEMENTER;

    const isOrderNotFinished = ![
      ORDER_STATUS.REJECTED,
      ORDER_STATUS.FINISHED,
    ].includes(order?.status?.id || -1);

    const isOrderCantBeDeleted =
      [ORDER_STATUS.FINISHED, ORDER_STATUS.REJECTED].includes(
        order?.status?.id || -1
      ) ||
      [COMPANY_CATEGORY.IMPLEMENTER].includes(companyRole || -1) ||
      userRoleId === USER_ROLE.DRIVER;

    const isOrderVehicleCanBeChanged =
      [COMPANY_CATEGORY.IMPLEMENTER, COMPANY_CATEGORY.PLATFORM].includes(
        companyRole || -1
      ) || userRoleId === USER_ROLE.ADMINISTRATOR;

    const isDriverSelecting = [ORDER_STATUS.DRIVER_CHOOSING].includes(
      order?.status?.id || -1
    );

    const orderPhotos = order?.photos?.filter(
      (photo) => photo.type.id === FILE_TYPE.CARGO_PHOTO
    );

    const canDetachOrder =
      [COMPANY_CATEGORY.IMPLEMENTER, COMPANY_CATEGORY.PLATFORM].includes(
        userCompanyCategoryId || -1
      ) && order?.executor_company?.id === currentCompanyId;

    return (
      <QueryLayout isLoading={isLoading} error={error}>
        {!order ? (
          <Error>Не удаётся найти заказ</Error>
        ) : (
          <div className="overflow-y-auto">
            {withTracking && (
              <div className="mb-[15px]">
                <OrderMessage order={order} />
              </div>
            )}
            {order.vehicle && (
              <>
                <div className="mb-[10px]">
                  <h4 className="mb-[10px] text-sm font-semibold text-gray">
                    Назаченная техника
                  </h4>
                  <div
                    className={`cursor-pointerborder-lightWhiteGray mb-[15px] grid grid-cols-[40px,1fr,30px] items-center rounded border-[1px] border-whiteGray p-[15px] outline-none transition focus-within:border-primary `}
                  >
                    <img
                      className="mr-[15px] h-[22px] w-[25px]"
                      src={order.vehicle_category.icon.path}
                      alt={order.vehicle_category.name}
                    />
                    <div>
                      <div className="mb-[2px] flex text-left">
                        <div className="mr-[10px] w-fit rounded border border-black py-[4px] px-[7px] text-xs font-medium uppercase leading-[12px] text-black">
                          {order.vehicle.vehicle_number}
                        </div>
                        <div className="text-base font-semibold text-graySecond">
                          {order.vehicle_category.name}
                        </div>
                      </div>
                      <div className="text-sm font-medium text-gray">
                        {order.vehicle_type.name}
                      </div>
                      <div className="text-xs text-primary">
                        {order.confirmed_by_driver_at
                          ? "Водитель принял заказ"
                          : "Водитель ещё не принял заказ"}
                      </div>
                    </div>
                    {isOrderNotFinished && isOrderVehicleCanBeChanged && (
                      <button
                        className="h-full text-black outline-none transition hover:text-red focus:text-red active:text-black"
                        onClick={() =>
                          openConfirmationModal({
                            subTitle:
                              "Вы уверены что хотите снять технику с заказа?",
                            onConfirm: () => handleRemoveVehicleFromOrder(),
                          })
                        }
                      >
                        <SvgTrash className="ml-auto h-[20px] w-[20px]" />
                      </button>
                    )}
                  </div>
                  {order?.vehicle &&
                    !readOnly &&
                    isOrderNotFinished &&
                    isOrderVehicleCanBeChanged && (
                      <Button
                        className="w-full text-base tracking-wider"
                        onClick={() =>
                          onVehicleAssign &&
                          onVehicleAssign(order.id, order.vehicle_type.id)
                        }
                      >
                        Назначить другую технику
                      </Button>
                    )}
                </div>
                {order.driver && (
                  <div className="mb-[10px]">
                    <h4 className="mb-[10px] text-sm font-semibold text-gray">
                      Водитель
                    </h4>
                    <div
                      className={`cursor-pointerborder-lightWhiteGray mb-[15px] grid grid-cols-[45px,1fr,30px] items-center rounded border-[1px] border-whiteGray p-[15px] outline-none transition focus-within:border-primary `}
                    >
                      <div className="relative mr-[12px] flex items-center">
                        <SvgRatingStar
                          className="absolute top-[0px] right-[2px] h-[11px] w-[11px]"
                          alt="raiting star"
                        />
                        <div>
                          <img
                            className="h-[31px] w-[31px] rounded-[50%] object-cover text-graySecond"
                            src={`${
                              order.driver.avatar
                                ? order.driver.avatar.path
                                : "https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg"
                            }`}
                            alt="user"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="flex text-left">
                          <div className="text-base font-semibold text-gray">
                            {order?.driver?.last_name
                              ? `${order.driver.last_name} ${order.driver.first_name}`
                              : order.driver.phone}
                          </div>
                        </div>
                        <div className="text-sm font-semibold text-primary">
                          Водитель
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="mb-[10px]">
              <h4 className="mb-[10px] text-sm font-semibold text-gray">
                Адреса
              </h4>
              <ul className="mb-[15px] grid gap-[7px]">
                {order.addresses.map((address) => (
                  <li key={"address_" + address.id}>
                    <button className="flex items-center text-gray outline-none transition hover:text-primary focus:text-primary active:text-gray">
                      <SvgMapPin className="mr-[5px] h-[12px] w-[10px]" />
                      <address className="text-left text-sm not-italic">
                        {address.name}
                      </address>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-[10px]">
              <h4 className="mb-[10px] text-sm font-semibold text-gray">
                Дополнительное оборудование
              </h4>
              {order.options.length ? (
                <ul className="mb-[15px] grid items-center gap-[7px]">
                  {order.options.map((equipment, i) => (
                    <li
                      key={"equipment_" + equipment.id + `_${i}`}
                      className="flex items-center"
                    >
                      <i className="mr-[12px] inline-block h-[14px] w-[14px] rounded-[50%] border-[2px] border-primary" />
                      <span className="text-sm">{equipment.name}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <h5 className="text-sm">Нет</h5>
              )}
            </div>
            <div className="mb-[10px] grid gap-[15px]">
              <div>
                <TextArea
                  label="Описание заказа"
                  readOnly
                  className="w-full resize-none tracking-normal text-black"
                  value={order.description || "Нет"}
                />
              </div>
              <div>
                <Input
                  label="Личный данные"
                  readOnly
                  className="w-full tracking-normal text-black"
                  value={
                    order.last_name
                      ? `${order.last_name} ${order.first_name} ${
                          order.middle_name || ""
                        }`
                      : "Нет"
                  }
                />
              </div>
              <div>
                <MaskedInput
                  label="Телефон"
                  readOnly
                  className="w-full tracking-normal text-black"
                  defaultValue={order.phone}
                  type="tel"
                  mask="+7 (999) 999 99-99"
                />
              </div>
            </div>
            <div className="mb-[10px]">
              <h4 className="mb-[10px] text-sm font-semibold text-gray">
                Фото
              </h4>
              {orderPhotos?.length ? (
                <Slider images={orderPhotos} expandable />
              ) : (
                <h5 className="text-sm">Нет</h5>
              )}
            </div>
            <div className="grid gap-[15px]">
              {isDriverSelecting && !readOnly && isOrderVehicleCanBeChanged && !reserve && (
                <Button
                  onClick={() =>
                    onVehicleAssign &&
                    onVehicleAssign(orderId, order.vehicle_type.id)
                  }
                  className="w-full"
                >
                  Назначить технику
                </Button>
              )}
              {isCompanySelecting && (
                <Button
                  className="bg-black text-white w-full rounded px-[23px] py-[10px] text-base outline-none transition font-semibold tracking-wider max-w-[492px] hover:bg-blackHover text-center"
                  onClick={() => {
                      attachOrderToCompany(orderId)
                      handleSetReservedOrder(order)
                      dispatch(resetAssignment())
                      navigate("/orders");
                  }}
                >
                  Закрепить заказ за компанией
                </Button>
              )}
              {!isOrderCantBeDeleted && (
                <Button
                  disabled={isDetachingVehicleLoading}
                  onClick={openOrderCancellationModal}
                  className="w-full"
                  mode="red"
                >
                  Отменить заказ
                </Button>
              )}
              {canDetachOrder && isOrderNotFinished && (
                <Button
                  className="tracking-wider"
                  mode="darkBorder"
                  onClick={showDetachConfirmationModal}
                  disabled={isDetaching}
                >
                  Снять заказ с компании
                </Button>
              )}
            </div>
          </div>
        )}
      </QueryLayout>
    );
  }
);
