import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { OrderList } from "../../../common/components/Order/OrderList";
import {setActiveOrderId, setAssignedVehicleCategoryId, setAssignmentVehicleTypeId} from "./chooseVehicleSlice";
import { ORDER_STATUS } from "common/utils/consts";
import { IOrder } from "common/models/order/IOrder";

export const VehiclesTabs: FC = () => {
  const dispatch = useAppDispatch();
  const activeOrderId = useAppSelector(
    (state) => state.platformManager.chooseVehicle.activeOrderId
  );

  const handleSetActiveOrder = (order: IOrder) => {
    dispatch(setActiveOrderId(order.id));
    dispatch(setAssignedVehicleCategoryId(undefined));
    dispatch(setAssignmentVehicleTypeId(undefined));
  };

  return (
    <OrderList
      filter={{ statusId: [ORDER_STATUS.PROCESSING] }}
      activeOrderId={activeOrderId}
      onItemClick={handleSetActiveOrder}
      withAddresses
    />
  );
};
