import { FC, useEffect, useState } from "react";
import { SvgArrowInCircle } from "../../../assets/icons/SvgArrowInCircle";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { CustomInput } from "../Input/CustomInput";
import { EmployeeListItem } from "../Employee/EmployeeListItem";
import { useGlobalModalContext } from "../Modal/GlobalModal";
import { EMPLOYEE_ROLE, MODAL_TYPES } from "../../utils/consts";
import { useGetCompanyEmployeesQuery } from "../../../services/employeeApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { useGetVehicleDriversQuery } from "../../../services/vehicle/vehiclesApi";
import { NotFoundTitle } from "../Title/NotFoundTitle";
import { List } from "../List/List";
import { useAttachOrderVehicleMutation } from "../../../services/orderApi";
import { getErrorMessage } from "../../utils/helpers";
import { IChooseDriverProps } from "common/models/components/IChooseDriverProps";
import {setReservedOrder} from "../../../features/implementerCompanyManager/orders/ordersSlice";
import { useAppDispatch } from "../../../app/hooks";

export const ChooseDriver: FC<IChooseDriverProps> = ({
  driverChoosingClose,
  resetAssignment,
  setAssignedDriver,
  assignedDriverId,
  companyId,
  assignedVehicleId,
  orderId,
  reserve
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useAppDispatch();

  const [
    assignVehicle,
    {
      isLoading: isAssigningLoading,
      isSuccess: isAssigningSuccess,
      error: assignError,
    },
  ] = useAttachOrderVehicleMutation();
  const {
    data: drivers,
    isLoading: isDriversLoading,
    isFetching: isDriversFetching,
    error: driversError,
  } = useGetCompanyEmployeesQuery({ companyId, roleId: EMPLOYEE_ROLE.DRIVER });
  const {
    data: currentDrivers,
    isLoading: isCurrentDriversLoading,
    isFetching: isCurrentDriversFetching,
    error: currentDriversError,
  } = useGetVehicleDriversQuery(
    {
      companyId,
      vehicleId: assignedVehicleId || -1,
      query: searchText.length > 3 ? searchText : undefined,
    },
    { skip: !assignedVehicleId }
  );

  const { showModal } = useGlobalModalContext();

  const handleSetAssignedDriver = (id: Undefinable<number>) => {
    setAssignedDriver(id);
  };

  const handleSetSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const openSuccessModal = () => {
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: "Ваш запрос отправлен водителю",
    });
    dispatch(setReservedOrder(undefined))
    resetAssignment();
  };

  const openErrorModal = (message?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Не удалось назначить технику",
      subTitle:
        message ||
        "При отправке запроса произошла ошибка, повторите попытку позже",
    });
    dispatch(setReservedOrder(undefined))
    resetAssignment();
  };

  useEffect(() => {
    if (isAssigningSuccess) {
      openSuccessModal();
    }
  }, [isAssigningSuccess]);

  useEffect(() => {
    if (assignError) {
      openErrorModal(getErrorMessage(assignError)?.data.message);
    }
  }, [assignError]);

  const handleAssignVehicle = () => {
    if (assignedVehicleId && assignedDriverId && orderId) {
      assignVehicle({
        order_id: orderId,
        vehicle_id: assignedVehicleId,
        driver_id: assignedDriverId,
      });
    } else {
      openErrorModal("Не все поля заполнены!");
    }
  };

  const notSettedDrivers = drivers?.filter(
    (driver) =>
      !currentDrivers?.some(
        (currentDriver) => currentDriver.id === driver.id
      ) && driver.invite?.confirmed_at
  );

  const settedDrivers = currentDrivers?.filter((driver) => driver.confirmed_at);

  const handleBack = () => {
    handleSetAssignedDriver(undefined);
    driverChoosingClose();
  };

  return (
    <>
      <div>
        <div className="mb-[20px] flex items-center">
          {!reserve &&
              <button
                  className="mr-[16px] h-[32px] w-[32px] text-black
        outline-none transition hover:text-primary focus:text-primary active:text-black"
                  onClick={resetAssignment}
              >
                <SvgArrowInCircle className="rotate-180 text-inherit" />
              </button>
          }
          <h3 className="text-center text-md font-semibold">
            Назначить водителя
          </h3>
        </div>
        <div className="mb-[25px]">
          <Input
            className="w-full"
            placeholder="Поиск по водителям"
            value={searchText}
            onChange={handleSetSearchText}
          />
        </div>
      </div>
      <div className="grid gap-[25px] overflow-y-scroll">
        <div>
          <h4 className="mb-[15px] text-base font-semibold">
            Рекомендуемые водители
          </h4>
          <QueryLayout
            isLoading={isCurrentDriversLoading || isDriversLoading}
            isFetching={isCurrentDriversFetching || isDriversFetching}
            error={currentDriversError}
          >
            {settedDrivers?.length ? (
              <List>
                {settedDrivers?.map((driver) => (
                  <li key={driver.id}>
                    <CustomInput
                      className="py-[10px]"
                      checked={driver.id === assignedDriverId}
                      onClick={() => handleSetAssignedDriver(driver.id)}
                      innerelement={
                        <EmployeeListItem
                          name={`${
                            driver.last_name
                              ? `${driver.last_name} ${driver.first_name}`
                              : driver.invite_phone
                          }`}
                          rating={driver.rate}
                          role="Водитель"
                        />
                      }
                    />
                  </li>
                ))}
              </List>
            ) : (
              <NotFoundTitle />
            )}
          </QueryLayout>
        </div>
        <div>
          <h4 className="mb-[15px] text-base font-semibold">Водители</h4>
          <QueryLayout
            isLoading={isCurrentDriversLoading || isDriversLoading}
            isFetching={isCurrentDriversFetching || isDriversFetching}
            error={driversError}
          >
            {notSettedDrivers?.length ? (
              <List>
                {notSettedDrivers.map((driver) => (
                  <li key={driver.id}>
                    <CustomInput
                      className="py-[10px]"
                      checked={driver.id === assignedDriverId}
                      onClick={() => handleSetAssignedDriver(driver.id)}
                      innerelement={
                        <EmployeeListItem
                          name={`${
                            driver.user
                              ? `${driver.user.last_name} ${driver.user.first_name}`
                              : driver.invite?.phone
                          }`}
                          rating={driver.user?.rate}
                          role={driver.role.name}
                        />
                      }
                    />
                  </li>
                ))}
              </List>
            ) : (
              <NotFoundTitle />
            )}
          </QueryLayout>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[15px] pt-[30px]">
        <Button
          className="h-[45px] text-base tracking-wider"
          mode="darkBorder"
          onClick={handleBack}
        >
          Назад
        </Button>
        <Button
          className="h-[45px] text-base tracking-wider"
          disabled={!assignedDriverId || isAssigningLoading}
          loader={isAssigningLoading}
          onClick={handleAssignVehicle}
        >
          Назначить
        </Button>
      </div>
    </>
  );
};
