import { FC } from "react";
import { List } from "common/components/List/List";
import { CustomInput } from "common/components/Input/CustomInput";
import { useGetUserBankCardsQuery } from "services/userApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { BankCardItem } from "./BankCardItem";
import { NotFoundTitle } from "../Title/NotFoundTitle";

export const BankCardsList: FC<{
  activeCardId?: number;
  handleSetActiveCardId: (id: number) => void;
  userId: number;
}> = ({ activeCardId, handleSetActiveCardId, userId }) => {
  const { data, isLoading, error } = useGetUserBankCardsQuery(userId);

  return (
    <QueryLayout isLoading={isLoading} error={error}>
      <List className="mb-[10px]">
        {data?.length ? (
          data?.map((card) => (
            <li key={card.id}>
              <CustomInput
                type="radio"
                className="py-[10px]"
                checked={card.id === activeCardId}
                onClick={() => handleSetActiveCardId(card.id)}
                innerelement={<BankCardItem card={card} />}
              />
            </li>
          ))
        ) : (
          <NotFoundTitle />
        )}
      </List>
    </QueryLayout>
  );
};
