import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AppealListGenerator } from "../../../common/components/Appeal/AppealListGenerator";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { List } from "../../../common/components/List/List";
import { Tabs } from "../../../common/components/Tabs/Tabs";
import {
  setActiveAppealId,
  setAppealsDateFrom,
  setAppealsDateTo,
  setAppealStatus,
} from "./desktopSlice";
import {
  useGetAllAppealsQuery,
  useGetAppealStatusesQuery,
} from "../../../services/appealApi";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { NotFoundTitle } from "../../../common/components/Title/NotFoundTitle";
import { getQueryDateParam } from "common/utils/helpers";

export const AppealsTabs: FC = () => {
  const appealDateFrom = useAppSelector(
    (state) => state.platformManager.desktop.appealsDateFrom
  );
  const appealsDateTo = useAppSelector(
    (state) => state.platformManager.desktop.appealsDateTo
  );
  const appealsStatusId = useAppSelector(
    (state) => state.platformManager.desktop.appealStatus
  );
  const activeAppealId = useAppSelector(
    (state) => state.platformManager.desktop.activeAppealId
  );

  const {
    data: appeals,
    isLoading: isAppealsLoading,
    isFetching: isAppealsFetching,
    error: appealsError,
  } = useGetAllAppealsQuery({
    dateFrom: getQueryDateParam(appealDateFrom),
    dateTo: getQueryDateParam(appealsDateTo),
    statusId: appealsStatusId,
  });

  const { data: appealStatuses } = useGetAppealStatusesQuery();

  const dispatch = useAppDispatch();
  const appealStatus = useAppSelector(
    (state) => state.platformManager.desktop.appealStatus
  );

  const handleSetActiveAppeal = (id: number) => {
    dispatch(setActiveAppealId(id));
  };

  const handleSetAppealsStatus = (status: number) => {
    dispatch(setAppealStatus(status));
  };

  const handleAppealsDateFromChange = (date: Nullable<Date>) => {
    dispatch(setAppealsDateFrom(date?.toLocaleDateString("sv")));
  };

  const handleAppealsDateToChange = (date: Nullable<Date>) => {
    dispatch(setAppealsDateTo(date?.toLocaleDateString("sv")));
  };

  return (
    <>
      {appealStatuses && (
        <Tabs
          tabs={appealStatuses.map((status) => ({
            value: status.id,
            label: status.name,
          }))}
          value={appealStatus}
          onTabClick={handleSetAppealsStatus}
        />
      )}
      <div>
        <DatePicker
          onStartDateChange={handleAppealsDateFromChange}
          onEndDateChange={handleAppealsDateToChange}
          placeholderText="Введите промежуток дат"
          isClearable={true}
        />
      </div>

      <QueryLayout
        isLoading={isAppealsLoading}
        isFetching={isAppealsFetching}
        error={appealsError}
      >
        {appeals?.length ? (
          <List className="mt-[10px] overflow-y-scroll">
            <AppealListGenerator
              appeals={appeals}
              activeAppealId={activeAppealId}
              onItemClick={handleSetActiveAppeal}
            />
          </List>
        ) : (
          <NotFoundTitle />
        )}
      </QueryLayout>
    </>
  );
};
