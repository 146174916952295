import { FC, memo } from "react";
import { CompanyEdit } from "../Company/CompanyEdit";
import { ShadowContainer } from "../Container/ShadowContainer";
import { Dropdown } from "../Dropdown/Dropdown";
import { List } from "../List/List";
import { DetailedUserProfileCard } from "../Profile/DetailedUserProfileCard";
import { UserEdit } from "./UserEdit";
import { UserOrders } from "./UserOrders";
import { UserTransactions } from "./UserTransactions";
import { QueryLayout } from "../Layout/QueryLayout";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { useGetUserByIdQuery } from "../../../services/userApi";
import { UserAppeals } from "./UserAppeals";
import { IUserInformationProps } from "common/models/components/user/IUserInformationProps";

export const UserInformation: FC<IUserInformationProps> = memo(
  ({ userId, extended }) => {
    const {
      data: user,
      isLoading,
      isFetching,
      error,
    } = useGetUserByIdQuery(userId);

    return (
      <QueryLayout isLoading={isLoading} isFetching={isFetching} error={error}>
        {!user ? (
          <InformationBlock>Пользователь не найден!</InformationBlock>
        ) : (
          <>
            <div className="rounded border border-whiteGray p-[8px]">
              <DetailedUserProfileCard
                userId={user.id}
                status={user.account.role.name}
                name={`${user.last_name} ${user.first_name}`}
                bonuses={user.bonuses}
                completeOrdersNumber={6}
                registrationDate={new Date()}
              />
            </div>
            <div className="overflow-y-scroll">
              <List>
                <UserEdit user={user} readOnly />
                {user?.account?.company?.id && (
                  <CompanyEdit companyId={user.account.company.id} readOnly />
                )}
                <ShadowContainer className="overflow-hidden">
                  <Dropdown title="Заказы">
                    <UserOrders accountId={user.account.id} />
                  </Dropdown>
                </ShadowContainer>
                <ShadowContainer className="overflow-hidden">
                  <Dropdown title="Транзакции">
                    <UserTransactions userId={userId} />
                  </Dropdown>
                </ShadowContainer>
                {extended && (
                  <>
                    <ShadowContainer className="overflow-hidden">
                      <Dropdown title="Споры">
                        <UserOrders accountId={user.account.id} />
                      </Dropdown>
                    </ShadowContainer>
                    <ShadowContainer className="overflow-hidden">
                      <Dropdown title="Обращения">
                        <UserAppeals userId={userId} />
                      </Dropdown>
                    </ShadowContainer>
                  </>
                )}
              </List>
            </div>
          </>
        )}
      </QueryLayout>
    );
  }
);
