import { useState } from "react";
import { MODAL_TYPES } from "../../utils/consts";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { useGlobalModalContext } from "./GlobalModal";

export const ConfirmModal = () => {
  const { showModal, hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { subTitle, onConfirm, confirmText, withComment, title } = modalProps;

  const [commentText, setCommentText] = useState<string>("");
  const [isOnConfirmLoading, setIsOnConfirmLoading] = useState<boolean>(false);

  const handleModalToggle = () => {
    hideModal();
    setIsOnConfirmLoading(false);
  };

  const handleOnConfirm = async () => {
    if (onConfirm) {
      setIsOnConfirmLoading(true);
      await onConfirm();
      handleModalToggle();
    } else {
      showModal(MODAL_TYPES.SUCCESS_MODAL, { title: confirmText });
      handleModalToggle();
    }
  };

  const handleOnCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCommentText(event.target.value);
  };

  return (
    <div className="min-w-[325px] rounded bg-white p-[25px] text-center">
      <div className="mb-[25px] text-lg font-medium">
        {title || "Вы уверены?"}
      </div>
      <p className="m-auto mb-[25px] w-[90%] text-base font-medium">
        {subTitle}
      </p>
      {withComment && (
        <div className="mb-[25px] text-left">
          <Input
            value={commentText}
            onChange={handleOnCommentChange}
            className="w-full"
            label="Комментарий *"
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-[15px]">
        <Button
          className="text-base tracking-wider"
          mode="darkBorder"
          onClick={handleModalToggle}
        >
          Отмена
        </Button>
        <Button
          className="text-base tracking-wider"
          onClick={handleOnConfirm}
          disabled={(withComment && !commentText) || isOnConfirmLoading}
        >
          Ок
        </Button>
      </div>
    </div>
  );
};
