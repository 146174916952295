import { FC, useEffect } from "react";
import { Button } from "common/components/Button/Button";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { COMPANY_CATEGORY, MODAL_TYPES } from "common/utils/consts";
import { Title } from "common/components/Title/Title";
import { usePayOrderMutation } from "services/orderApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { useAppSelector } from "app/hooks";

export const PaymentError: FC<{ orderId: number; price: number }> = ({
  orderId,
  price,
}) => {
  const currentCompanyCategory = useAppSelector(
    (state) => state.user.current?.account.company?.category.id
  );
  const currentCompanyBalance = useAppSelector(
    (state) => state.user.current?.account.company?.balance
  );
  const currentCompanyCreditLine = useAppSelector(
    (state) => state.user.current?.account.company?.credit_line
  );

  const [makePayment, { isLoading, error, data }] = usePayOrderMutation();

  const { showModal } = useGlobalModalContext();

  const showOrderCancelationPaymentModal = () => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "При оплате заказ произошла ошибка",
    });
  };

  const showOrderSuccessPaymentModal = () => {
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: "Оплата успешно проведена",
    });
  };

  useEffect(() => {
    if (data) {
      if (data.success) {
        showOrderSuccessPaymentModal();
      } else {
        showOrderCancelationPaymentModal();
      }
    }
  }, [data]);

  const isNotEnoughMoney =
    currentCompanyCategory === COMPANY_CATEGORY.CUSTOMER &&
    (currentCompanyBalance || 0) + (currentCompanyCreditLine || 0) < price;

  return (
    <>
      <QueryLayout isLoading={isLoading} error={error}>
        <Title className="mb-[15px] text-center text-red">
          {isNotEnoughMoney
            ? "Недостаточно средств"
            : "При оплате заказа произошла ошибка"}
        </Title>
      </QueryLayout>
      <Button
        className="w-full"
        mode="darkBorder"
        disabled={isLoading || isNotEnoughMoney}
        loader={isLoading}
        onClick={() => makePayment(orderId)}
      >
        Оплатить повторно
      </Button>
    </>
  );
};
