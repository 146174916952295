import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { VehicleFormValues } from "../../models/forms/VehicleFormValues";
import { VehicleMainCharacteristics } from "./VehicleMainCharacteristics";
import { VehicleSpecificationCharacteristics } from "./VehicleSpecificationCharacteristics";
import { IVehicleCharacteristicsProps } from "common/models/components/vehicle/IVehicleCharacteristicsProps";

export const VehicleCharacteristics: FC<IVehicleCharacteristicsProps> = ({
  moderation,
  vehicle,
}) => {
  const methods = useFormContext<VehicleFormValues>();

  const { reset, register } = methods;

  useEffect(() => {
    if (vehicle) {
      reset({
        category:
          vehicle?.vehicle_type?.category?.id || vehicle.vehicle_category.id,
        main: {
          chassisMark: {
            label: vehicle.chassis_model.chassis_mark.name,
            value: vehicle.chassis_model.chassis_mark.id,
          },
          chassisModel: {
            label: vehicle.chassis_model?.name,
            value: vehicle.chassis_model?.id,
          },
          kmuModel: {
            label: `${vehicle.kmu_model.kmu_brand.name} ${vehicle.kmu_model.name}`,
            value: vehicle.kmu_model?.id,
          },
          governmentNumber: vehicle.vehicle_number,
          vrcNumber: vehicle.sts_number,
          vrcPhoto: vehicle.sts_photo?.path,
          location: vehicle.location_address,
          coverageRadius: vehicle.search_radius.id,
        },
        specifications: {
          boomCapacity: vehicle.board_carrying_capacity,
          sideLoadCapacity: vehicle.arrow_carrying_capacity,
          tariff: vehicle?.vehicle_type?.id,
          boomLength: vehicle.arrow_length,
          dimensions: {
            length: vehicle.vehicle_length,
            width: vehicle.vehicle_width,
            height: vehicle.vehicle_height,
          },
          optionalEquipment: vehicle.options.map((option) => option.id),
        },
      });
    }
  }, [vehicle]);

  useEffect(() => {
    if (moderation) {
      register("specifications.tariff", {
        required: { value: true, message: "Данное поле обязательно" },
      });
    }
    register("main.chassisMark.value", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("main.chassisMark", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("specifications.dimensions.length", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("specifications.dimensions.width", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("specifications.dimensions.height", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("specifications.optionalEquipment");
    register("specifications.boomLength", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("specifications.sideLoadCapacity", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("specifications.boomCapacity", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("main.coverageRadius", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("main.vrcPhoto", {
      required: true,
    });
    register("main.location", {
      required: { value: true, message: "Данное поле обязательно" },
      minLength: {
        value: 10,
        message: "Минимум 10 символов",
      },
      maxLength: {
        value: 1024,
        message: "Максимум 1024 символов",
      },
    });
    register("main.vrcNumber", {
      required: { value: true, message: "Данное поле обязательно" },
      maxLength: {
        value: 6,
        message: "Данное поле должно состоять из 6 цифр",
      },
      minLength: {
        value: 6,
        message: "Данное поле должно состоять из 6 цифр",
      },
    });
    register("main.chassisModel", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("main.chassisModel.value", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("main.kmuModel", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("main.kmuModel.value", {
      required: { value: true, message: "Данное поле обязательно" },
    });
    register("main.governmentNumber", {
      required: { value: true, message: "Данное поле обязательно" },
      minLength: {
        value: 8,
        message: "Минимум 8 символов",
      },
      maxLength: {
        value: 9,
        message: "Максимум 9 символов",
      },
    });
  }, []);

  return (
    <>
      <VehicleMainCharacteristics />
      <VehicleSpecificationCharacteristics moderation={moderation} />
    </>
  );
};
