import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgTelegram: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4428 15.1273L6.32845 3.30327C6.15082 3.2038 5.94706 3.16092 5.7444 3.18035C5.54174 3.19978 5.34985 3.2806 5.19435 3.41201C5.03886 3.54343 4.92718 3.71917 4.87424 3.91575C4.82131 4.11233 4.82963 4.32039 4.8981 4.51212L8.88073 15.6635C8.95841 15.881 8.95841 16.1187 8.88073 16.3362L4.8981 27.4875C4.82963 27.6793 4.8213 27.8873 4.87424 28.0839C4.92718 28.2805 5.03886 28.4562 5.19435 28.5876C5.34985 28.719 5.54174 28.7999 5.7444 28.8193C5.94706 28.8387 6.15082 28.7958 6.32845 28.6964L27.4428 16.8723C27.5979 16.7855 27.727 16.6588 27.8169 16.5055C27.9068 16.3521 27.9542 16.1776 27.9542 15.9998C27.9542 15.8221 27.9068 15.6475 27.8169 15.4942C27.727 15.3408 27.5979 15.2142 27.4428 15.1273V15.1273Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 16H17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
