import { IColorPalette } from "common/models/commonModels/IColorPalette";
import { IButtonProps } from "common/models/components/ui/IButtonProps";
import { FC, memo } from "react";
import { SvgSpinner } from "../../../assets/icons/SvgSpinner";

export const Button: FC<IButtonProps> = memo((props) => {
  const { mode } = props;
  const { loader } = props;
  const userClasses = props.className || "";

  const colorPalette = setColorPalette(mode);

  return (
    <button
      {...props}
      className={`w-full rounded px-[23px] py-[10px] text-base outline-none transition font-semibold tracking-wider max-w-[492px]
      ${colorPalette.normal} ${colorPalette.hover} ${colorPalette.active} ${colorPalette.focus} ${loader ? "" : colorPalette.disabled} ${userClasses}`}
    >
      {loader ?
        <SvgSpinner
          className={`mx-auto h-[21px] w-[21px] animate-spin ${mode === "darkBorder" ? "text-black" : ""}`}
        /> : props.children
      }
    </button>
  );
});

function setColorPalette(mode?: string) {
  switch (mode) {
    case "black":
      return defaultPalette;
    case "darkBorder":
      return darkBorderPalette;
    case "red":
      return redColorPalette;
    case "lightBorder":
      return lightBorderPalette;
    case "yellow":
      return yellowColorPalette;
    case "yellowBorder":
      return yellowBorderPalette;
    case "yellowBlackDisable":
      return yellowBlackDisablePalette;
    case "small":
      return smallPalette;
    default:
      return defaultPalette;
  }
}

const defaultPalette: IColorPalette = {
  normal: "bg-black text-white",
  hover: "hover:bg-blackHover",
  active: "active:bg-black active:text-white",
  focus: "focus:bg-blackHover",
  disabled: "disabled:bg-blackHover disabled:opacity-30",
};

const blackColorPalette: IColorPalette = {
  normal: "bg-black text-white border border-black",
  hover: "hover:bg-white hover:text-black",
  active: "active:bg-black active:text-white",
  focus: "focus:bg-white focus:text-black",
  disabled:
    "disabled:bg-lightGray disabled:text-white disabled:border-lightGray",
};

const darkBorderPalette: IColorPalette = {
  normal: "bg-white text-black border border-black",
  hover: "hover:border-blackHover hover:text-blackHover",
  active: "active:border-blackHover active:text-blackHover",
  focus: "focus:border-blackHover focus:text-blackHover",
  disabled:
    "disabled:opacity-40",
};

const redColorPalette: IColorPalette = {
  normal: "bg-red text-white",
  hover: "hover:bg-redHover",
  active: "active:bg-redHover",
  focus: "focus:bg-redHover",
  disabled:
    "disabled:opacity-60",
};

const lightBorderPalette: IColorPalette = {
  normal: "bg-white text-black border border-[#D9D9D9]",
  hover: "hover:bg-[#F9F9F9] hover:border-[#F9F9F9]",
  active: "active:bg-white active:text-black",
  focus: "focus:bg-[#F9F9F9] focus:border-[#F9F9F9]",
  disabled:
    "disabled:bg-lightGray disabled:text-white disabled:border-lightGray",
};

const yellowColorPalette: IColorPalette = {
  normal: "bg-primary text-white border border-primary",
  hover: "hover:bg-[#F9F9F9] hover:text-black hover:border-[#F9F9F9]",
  active: "active:bg-primary active:text-white active:border-primary",
  focus: "focus:bg-[#F9F9F9] focus:text-black focus:border-[#F9F9F9]",
  disabled:
    "disabled:bg-lightGray disabled:text-white disabled:border-lightGray",
};

const yellowBorderPalette: IColorPalette = {
  normal: "bg-primary text-white border border-primary",
  hover: "hover:bg-[#F9F9F9] hover:text-black hover:border-[primary]",
  active: "active:bg-primary active:text-white active:border-primary",
  focus: "focus:bg-[#F9F9F9] focus:text-black focus:border-primary",
  disabled:
    "disabled:bg-lightGray disabled:text-white disabled:border-lightGray",
};

const yellowBlackDisablePalette: IColorPalette = {
  normal: "bg-primary text-white border border-primary",
  hover: "hover:bg-[#F9F9F9] hover:text-black hover:border-primary",
  active: "active:bg-primary active:text-white active:border-primary",
  focus: "focus:bg-[#F9F9F9] focus:text-black focus:border-primary",
  disabled: "disabled:bg-black disabled:text-white disabled:border-black",
};

const smallPalette: IColorPalette = {
  normal:
    "px-[6px] py-[6px] text-xxs bg-[#F9F9F9] text-primary border border-primary",
  hover: "hover:border-[#F9F9F9]",
  active: "active:bg-[#F9F9F9] active:text-primary active:border-primary",
  focus: "focus:border-[#F9F9F9]",
  disabled: "disabled:bg-black disabled:text-white disabled:border-black",
};
