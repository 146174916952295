import { FC } from "react";
import { useAppSelector } from "../../../app/hooks";
import { VehicleAdd } from "./VehicleAdd";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { Title } from "../../../common/components/Title/Title";
import { VehicleEdit } from "./VehicleEdit";
import { IVehicleInfoProps } from "common/models/components/vehicle/IVehicleInfoProps";

export const VehicleInfo: FC<IVehicleInfoProps> = ({
  vehicleId,
  companyId,
}) => {
  const isAddingVehicle = useAppSelector(
    (state) => state.implementerCompanyManager.vehicles.isAddingVehicle
  );

  return (
    <>
      {isAddingVehicle && (
        <ShadowContainer className="mt-[37px]">
          <Title className="mb-[25px]">Добавить технику</Title>
          <VehicleAdd companyId={companyId} />
        </ShadowContainer>
      )}
      {vehicleId && <VehicleEdit vehicleId={vehicleId} companyId={companyId} />}
      {!isAddingVehicle && !vehicleId && <InformationBlock />}
    </>
  );
};
