import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomSelect } from "../../../../../common/components/Select/CustomSelect";
import { Input } from "../../../../../common/components/Input/Input";
import { useGetKmuMarksQuery } from "services/vehicle/kmuApi";
import { ICreateVehicleKmuModel } from "common/models/vehicles/IVehicleKmuModel";
import { VEHICLE_CATEGORY } from "common/utils/consts";

export const KmuModelFormInputs: FC = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<ICreateVehicleKmuModel>();

  const { data: marks } = useGetKmuMarksQuery();

  const currentCategoryId = watch("vehicle_category_id");

  const options = marks?.map((mark) => ({
    value: mark.id,
    label: mark.name,
  }));

  return (
    <>
      <Input
        {...register(`name`, { required: true })}
        className="w-full"
        label="Название модели"
        invalid={!!errors.name}
        placeholder="Название"
      />
      <Controller
        control={control}
        name="kmu_brand_id"
        rules={{ required: true }}
        render={(props) => {
          return (
            <CustomSelect
              className="w-full"
              label="Марка КМУ *"
              reference={props.field.ref}
              options={options}
              placeholder="Выберите марку КМУ..."
              invalid={!!errors.kmu_brand_id}
              onChange={(val) => props.field.onChange(val?.value)}
              isSearchable={false}
              value={options?.find((c) => c.value === props.field.value)}
            />
          );
        }}
      />
      <Input
        {...register("arrow_length")}
        className="w-full"
        type="number"
        step={0.01}
        label="Длина стрелы *"
        placeholder="Длина"
        invalid={!!errors.arrow_length}
      />
      <Input
        {...register("arrow_carrying_capacity")}
        className="w-full"
        type="number"
        step={0.01}
        label="Грузоподъёмность стрелы *"
        placeholder="Грузоподъёмность"
        invalid={!!errors.arrow_carrying_capacity}
      />
      {currentCategoryId === VEHICLE_CATEGORY.CRAN && (
        <Input
          {...register("lifting_height")}
          className="w-full"
          type="number"
          step={0.01}
          label="Высота подъёма *"
          placeholder="Высота"
          invalid={!!errors.lifting_height}
        />
      )}
    </>
  );
};
