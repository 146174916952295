import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../Button/Button";
import { ShadowContainer } from "../Container/ShadowContainer";
import { Dropdown } from "../Dropdown/Dropdown";
import { Input } from "../Input/Input";
import { PhotoInput } from "../Input/PhotoInput";
import { UserEditFormValues } from "../../models/forms/UserEditFormValues";
import { getErrorMessage } from "../../utils/helpers";
import { useUploadSignleImageMutation } from "../../../services/fileApi";
import { useUpdateUserMutation } from "../../../services/userApi";
import { IUserEditProps } from "common/models/components/user/IUserEditProps";

export const UserEdit: FC<IUserEditProps> = ({
  user,
  isOpen,
  readOnly = false,
}) => {
  const [
    uploadAvatar,
    {
      isSuccess: isUploadingImageSuccess,
      isLoading: isUploadingImageLoading,
      error: isUploadingImageError,
      data: imageData,
    },
  ] = useUploadSignleImageMutation();
  const [
    updateUser,
    {
      isSuccess: isUserUpdateSuccess,
      isLoading: isUserUpdateLoading,
      error: isUserUpdateError,
    },
  ] = useUpdateUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    reset,
  } = useForm<UserEditFormValues>({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      middle_name: user.middle_name,
      email: user.email,
    },
  });

  useEffect(() => {
    if (isUploadingImageSuccess && imageData?.id) {
      updateUser({
        user_id: user?.id,
        avatar_id: imageData?.id,
        ...getValues(),
      });
    }
  }, [isUploadingImageSuccess]);

  useEffect(() => {
    if (isUserUpdateSuccess) {
      reset({
        avatar: user.avatar?.path,
        first_name: user.first_name,
        last_name: user.last_name,
        middle_name: user.middle_name,
        email: user.email,
      });
    }
  }, [isUserUpdateSuccess]);

  useEffect(() => {
    reset({
      avatar: user.avatar?.path,
      first_name: user.first_name,
      last_name: user.last_name,
      middle_name: user.middle_name,
      email: user.email,
    });
  }, [user]);

  const onSubmit = handleSubmit((data) => {
    if (data.avatar[0]) {
      const formData = new FormData();
      formData.append("file_type_id", "1");
      formData.append("file", data.avatar[0]);
      uploadAvatar(formData);
    } else {
      updateUser({ user_id: user?.id, ...data });
    }
  });

  return (
    <ShadowContainer>
      <Dropdown title="Основные данные профиля" isOpen={isOpen}>
        <form onSubmit={onSubmit}>
          <div className="mb-[30px] grid gap-[15px]">
            <div className="mb-[25px]">
              <PhotoInput
                readOnly={readOnly}
                {...register("avatar")}
                label="Фото"
                defaultImages={
                  user.avatar && !isUploadingImageSuccess
                    ? [user.avatar]
                    : undefined
                }
              />
            </div>
            <Input
              readOnly={readOnly}
              {...register("first_name", {
                required: { value: true, message: "Данное поле обязательно" },
              })}
              type="text"
              invalid={!!errors.first_name}
              invalidMessage={errors.first_name?.message}
              className="w-full"
              label="Имя *"
            />
            <Input
              readOnly={readOnly}
              {...register("last_name", {
                required: { value: true, message: "Данное поле обязательно" },
              })}
              type="text"
              invalid={!!errors.last_name}
              invalidMessage={errors.last_name?.message}
              className="w-full"
              label="Фамилия *"
            />
            <Input
              readOnly={readOnly}
              {...register("middle_name", {
                required: { value: true, message: "Данное поле обязательно" },
              })}
              type="text"
              invalid={!!errors.middle_name}
              invalidMessage={errors.middle_name?.message}
              className="w-full"
              label="Отчество *"
            />
            <Input
              readOnly={readOnly}
              {...register("email", {
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Неправильный формат почты",
                },
              })}
              invalid={!!errors.email}
              invalidMessage={errors.email?.message}
              type="email"
              className="w-full"
              label="Email"
            />
          </div>
          {!readOnly && (
            <div>
              <Button
                className="w-full"
                disabled={
                  !isDirty || isUploadingImageLoading || isUserUpdateLoading
                }
              >
                Сохранить
              </Button>
              <div>
                <div className="mt-[15px] text-center">
                  {isUploadingImageError && (
                    <span
                      className={`m-auto mb-[12px] block text-sm font-semibold text-red`}
                    >
                      Ошибка при загрузке аватара, повторите попытку позже
                    </span>
                  )}
                  {isUserUpdateError && (
                    <span
                      className={`m-auto mb-[12px] block text-sm font-semibold text-red`}
                    >
                      {getErrorMessage(isUserUpdateError)?.data?.message}
                    </span>
                  )}
                  {isUserUpdateSuccess && (
                    <span
                      className={`m-auto mb-[12px] block text-sm font-semibold text-primary`}
                    >
                      Успешно изменено
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </form>
      </Dropdown>
    </ShadowContainer>
  );
};
