import { FC } from "react";
import { Button } from "../../../../../common/components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { KmuModelFormInputs } from "./KmuModelFormInputs";
import { ICreateVehicleKmuModel } from "common/models/vehicles/IVehicleKmuModel";
import { useCreateKmuModelMutation } from "services/vehicle/kmuApi";
import { Error } from "common/components/Message/Error/Error";
import { Success } from "common/components/Message/Success/Success";
import { getErrorMessage } from "common/utils/helpers";

export const KmuModelAdd: FC<{ vehicleCategoryId: number }> = ({
  vehicleCategoryId,
}) => {
  const methods = useForm<ICreateVehicleKmuModel>({
    defaultValues: { vehicle_category_id: vehicleCategoryId },
  });

  const [create, { isLoading, error, isSuccess }] = useCreateKmuModelMutation();

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {
    create(data);
  });

  if (error) {
    return <Error>{getErrorMessage(error)?.data.message}</Error>;
  }

  if (isSuccess) {
    return <Success>Модель успешно создана!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <KmuModelFormInputs />
      </FormProvider>
      <Button className="w-fit" disabled={isLoading} loader={isLoading}>
        Сохранить
      </Button>
    </form>
  );
};
