import { ICompany } from "common/models/companies/ICompany";
import { api } from "../api";

const adminCompaniesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotVerifiedCompanies: build.query<ICompany[], void>({
      query() {
        return {
          url: `/admin/companies`,
        };
      },
      transformResponse: (result: { companies: ICompany[] }) =>
        result.companies,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Company", id } as const)),
              { type: "Company", id: "LIST" },
            ]
          : [{ type: "Company", id: "LIST" }],
    }),
    getCompanyById: build.query<ICompany, number>({
      query(id) {
        return {
          url: `/admin/companies/${id}`,
        };
      },
      transformResponse: (result: { company: ICompany }) => result.company,
      providesTags: (result) =>
        result ? [{ type: "Company", id: result?.id } as const] : [],
    }),
    verifyCompany: build.mutation<ICompany, { id: number }>({
      query({ id }) {
        return {
          url: `/admin/companies/${id}/verify`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, arg) =>
        !error?.status ? [{ type: "Company", id: arg.id } as const] : [],
    }),
    rejectCompany: build.mutation<ICompany, { id: number }>({
      query({ id }) {
        return {
          url: `/admin/companies/${id}/reject`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, arg) =>
        !error?.status ? [{ type: "Company", id: arg.id } as const] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompanyByIdQuery,
  useGetNotVerifiedCompaniesQuery,
  useVerifyCompanyMutation,
  useRejectCompanyMutation,
} = adminCompaniesApi;
