import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "../../../common/components/Button/Button";
import { Input } from "../../../common/components/Input/Input";
import { RecoveryFormValues } from "../../../common/models/forms/RecoveryFormValues";

export const PasswordRecover: FC = () => {
  const [isRecoverSuccess, setIsRecoverSuccess] = useState(false);
  const [isVerificaionCodeSended, setIsVerificaionCodeSended] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RecoveryFormValues>();

  const onSubmit = handleSubmit((data) => {
    if (!isVerificaionCodeSended) {
      setIsVerificaionCodeSended(true);
    } else {
      setIsRecoverSuccess(true);
    }
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mb-[25px] w-full">
          {isVerificaionCodeSended ? (
            <>
              <label>
                <span
                  className={`mb-[12px] block ${
                    !!errors.recoveryPassword ? "text-red" : "text-gray"
                  } text-sm font-semibold`}
                >
                  Новый пароль
                </span>
                <Input
                  key="recoveryPassword"
                  className="w-full"
                  type="password"
                  {...register("recoveryPassword", { required: true })}
                  invalid={!!errors.recoveryPassword}
                  placeholder="Введите почту или телефон"
                />
              </label>
              {!!errors.recoveryPassword && (
                <span className="mt-[5px] text-xs text-red">
                  Введите новый пароль
                </span>
              )}
            </>
          ) : (
            <>
              <label>
                <span
                  className={`mb-[12px] block ${
                    !!errors.recoveryVariant ? "text-red" : "text-gray"
                  } text-sm font-semibold`}
                >
                  Телефон или почта
                </span>
                <Input
                  key="recoveryVariant"
                  className="w-full"
                  {...register("recoveryVariant", { required: true })}
                  invalid={!!errors.recoveryVariant}
                  placeholder="Введите почту или телефон"
                />
              </label>
              {!!errors.recoveryVariant && (
                <span className="mt-[5px] text-xs text-red">
                  Указанный телефон отсутствует в системе
                </span>
              )}
            </>
          )}
        </div>
        <div className="mb-[25px] flex flex-col">
          {isRecoverSuccess ? (
            <div className="text-center text-md font-semibold text-primary">
              Ваш пароль успешно сохранен
            </div>
          ) : (
            <>
              <Button
                className="mb-[15px] h-[45px] w-full text-base leading-[15px] tracking-normal"
                mode="darkBorder"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Назад
              </Button>
              <Button
                className="h-[45px] w-full px-0 text-base leading-[15px]"
                type="submit"
              >
                {isVerificaionCodeSended
                  ? "Сохранить новый пароль"
                  : "Сбросить текущий пароль"}
              </Button>
            </>
          )}
        </div>
      </form>
      <div className="flex items-center justify-between">
        <NavLink to="/auth" className="text-base font-semibold">
          Войти
        </NavLink>
        <NavLink
          to="/auth/registration"
          className="text-[13px] font-semibold text-gray"
        >
          Зарегистрироваться
        </NavLink>
      </div>
    </>
  );
};
