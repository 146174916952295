import { FC, memo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { Dropdown } from "../../../common/components/Dropdown/Dropdown";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { OrderItemDetails } from "../../../common/components/Order/OrderItemDetails";
import { DetailedUserProfileCard } from "../../../common/components/Profile/DetailedUserProfileCard";
import { Title } from "../../../common/components/Title/Title";
import { FinishedOrdersList } from "./FinishedOrdersList";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const FinishedOrderModerationPage: FC = withErrorBoundary(
  memo(() => {
    const activeOrderId = useAppSelector(
      (state) => state.platformManager.finishedOrderModeration.activeOrderId
    );

    const { showModal } = useGlobalModalContext();

    const showReportModal = () => {
      showModal(MODAL_TYPES.REPORT_MODAL);
    };

    return (
      <>
        <div className="grid grid-rows-[40px,1fr] gap-[10px] overflow-y-hidden">
          <FinishedOrdersList />
        </div>
        {activeOrderId ? (
          <>
            <div className="mb-[15px] grid grid-rows-[1fr,155px] overflow-hidden">
              <ShadowContainer className="mb-[30px] overflow-y-scroll">
                <Dropdown title="Детали заказа" isOpen>
                  <OrderItemDetails readOnly orderId={activeOrderId} />
                </Dropdown>
              </ShadowContainer>

              <div className="grid gap-[10px]">
                <Button>Позвонить</Button>
                <Button>Одобрить</Button>
                <Button mode="darkBorder" onClick={showReportModal}>
                  Отправить разработчику
                </Button>
              </div>
            </div>

            <div className="mb-[15px] grid grid-rows-[30px,1fr] gap-[15px] overflow-y-hidden">
              <Title>Вид карточки пользователя</Title>
              <div className="h-fit rounded border border-whiteGray p-[8px]">
                <DetailedUserProfileCard
                  className="w-full"
                  status="Компания"
                  name="Иван Иванов"
                  userId={28}
                  completeOrdersNumber={6}
                  bonuses={234}
                  registrationDate={new Date()}
                />
              </div>
            </div>
          </>
        ) : (
          <InformationBlock className="col-span-2 w-full" />
        )}
      </>
    );
  })
);
