import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setActiveEmployee,
  setEmployeeSearchText,
  setIsAddingEmployee,
} from "./employeesSlice";
import { EmployeeAdd } from "../../../common/components/Employee/EmployeeAdd";
import { EmployeeEdit } from "common/components/Employee/EmployeeEdit";
import { Container } from "../../../common/components/Container/Container";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { EmployeeList } from "common/components/Employee/EmployeeList";
import { APPLICATIONS } from "common/utils/consts";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const EmployeesPage: FC = withErrorBoundary(() => {
  const activeEmployeeId = useAppSelector(
    (state) => state.customerCompanyManager.employees.activeEmployee
  );
  const isAddingEmployee = useAppSelector(
    (state) => state.customerCompanyManager.employees.isAddingEmployee
  );
  const searchText = useAppSelector(
    (state) => state.customerCompanyManager.employees.searchText
  );
  const currentCompanyId = useAppSelector(
    (state) => state.user.current?.account?.company?.id
  );
  const companyCategoryId = useAppSelector(
    (state) => state.user.current?.account?.company?.category.id
  );

  const dispatch = useAppDispatch();

  const handlerSetActiveEmployee = (id: number) => {
    dispatch(setActiveEmployee(id));
  };

  const handleSearchText = (text: string) => {
    dispatch(setEmployeeSearchText(text));
  };

  const handlerSetIsAddingEmployee = () => {
    dispatch(setIsAddingEmployee(!isAddingEmployee));
  };

  if (!currentCompanyId) return null;

  return (
    <Container>
      <div className="overflow-y-scroll">
        <EmployeeList
          onAdd={handlerSetIsAddingEmployee}
          onItemClick={handlerSetActiveEmployee}
          onSearch={handleSearchText}
          companyId={currentCompanyId}
          searchText={searchText}
          activeItemId={activeEmployeeId}
          applicationId={APPLICATIONS.CUSTOMER}
        />
      </div>
      <div className="overflow-y-scroll max-w-[800px]">
        {isAddingEmployee && companyCategoryId && (
          <EmployeeAdd
            applicationId={APPLICATIONS.CUSTOMER}
            companyId={currentCompanyId}
            companyCategoryId={companyCategoryId}
          />
        )}
        {activeEmployeeId && companyCategoryId && (
          <EmployeeEdit
            employeeId={activeEmployeeId}
            companyId={currentCompanyId}
            companyCategoryId={companyCategoryId}
            handleClose={() => handlerSetActiveEmployee(activeEmployeeId)}
          />
        )}
        {!isAddingEmployee && !activeEmployeeId && <InformationBlock />}
      </div>
    </Container>
  );
});
