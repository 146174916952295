import {FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Container } from "../../../common/components/Container/Container";
import { OrdersFilter } from "../../../common/components/Filter/OrdersFilter";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { OrderList } from "../../../common/components/Order/OrderList";
import { OrderItemDetails } from "../../../common/components/Order/OrderItemDetails";
import { Title } from "../../../common/components/Title/Title";
import {useDetachOrderCompanyMutation, useGetOrderByIdQuery} from "../../../services/orderApi";
import {
  orderFiltersSelector,
  resetFilter,
  setActiveOrderId,
  setFilterPeriod,
  setFilterVehicles,
  setFilterTimeAt,
  setFilterTimeTo,
  setIsActiveOrders,
} from "./ordersSlice";
import { OrderInfo } from "./OrderInfo";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";
import { Tabs } from "common/components/Tabs/Tabs";
import { FilterButton } from "common/components/Button/FilterButton";
import { IOrder } from "common/models/order/IOrder";
import {AssignmentVehicle} from "../../../common/components/VehicleAssignment/AssignmentVehicle";
import {
  requestVehicleAssignmentSelector,
  resetAssignment,
  setAssigningDriverId,
  setAssigningVehicleId, setAssigningVehicleTypeId, setAssignmentRequestId
} from "../requests/requestsSlice";
import {SvgCreditCard} from "../../../assets/icons/SvgCreditCard";
import {timeFormatter} from "../../../common/utils/helpers";

export const OrdersPage: FC = withErrorBoundary(() => {
  const activeOrderId = useAppSelector(
    (state) => state.implementerCompanyManager.orders.activeOrderId
  );
  const reservedOrder = useAppSelector(
      (state) => state.implementerCompanyManager.orders.reservedOrder)

  const currentCompanyId = useAppSelector(
      (state) => state.user.current?.account?.company?.id
  );

  const assignedVehicleTypeId = useAppSelector(
      (state) =>
          state.implementerCompanyManager.requests.vehicleAssignment
              .assignedVehicleTypeId
  );

  const isActiveOrders = useAppSelector(
    (state) => state.implementerCompanyManager.orders.isActiveOrders
  );
  const orderFilter = useAppSelector(
    (state) => state.implementerCompanyManager.orders.filters
  );

  const companyId = useAppSelector(
    (state) => state.user.current?.account.company?.id
  );

  const {
    data: order,
    isLoading: isOrderLoading,
    isFetching: isOrderFetching,
    error: orderError,
  } = useGetOrderByIdQuery(
    { id: activeOrderId || -1 },
    {
      skip: !activeOrderId,
    }
  );

  const dispatch = useAppDispatch();

  const handleOrderStatusChange = (type: boolean) => {
    dispatch(setIsActiveOrders(type));
  };

  const handleSetActiveOrder = (order: IOrder) => {
    dispatch(setActiveOrderId(order.id));
  };

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const handleSetAssignmentVehicleType = (id: number) => {
    dispatch(setAssigningVehicleTypeId(id));
  };

  const handleSetAssignmentRequestId = (id: number) => {
    dispatch(setAssignmentRequestId(id));
  };

  const handleVehicleAssign = (orderId: number, vehicleTypeId: number) => {
    handleSetAssignmentRequestId(orderId);
    handleSetAssignmentVehicleType(vehicleTypeId);
  };

  const currentOrderDate = useMemo(
      () =>
          timeFormatter({
            time: reservedOrder ? new Date(reservedOrder.datetime || reservedOrder.created_at) : new Date(),
            mode: "dd-Mth-yyyy",
          }),
      [reservedOrder?.datetime, reservedOrder?.created_at]
  );

  const statusBg = {
    1: "bg-blue",
    2: "bg-blue",
    3: "bg-blue",
    4: "bg-red",
    5: "bg-blue",
    6: "bg-blue",
    7: "bg-blue",
    8: !reservedOrder?.documents?.length ? "bg-primary" : "bg-green",
    9: "bg-red"
  }

  const [detachOrder] =
      useDetachOrderCompanyMutation();

  useEffect(() => {
    if (reservedOrder) {
      handleVehicleAssign(reservedOrder.id, reservedOrder.vehicle_type.id)

      const handleWindowClosing = () => {
        detachOrder(reservedOrder.id)
      };

      window.addEventListener('beforeunload', handleWindowClosing);

      return () => {
        window.removeEventListener('beforeunload', handleWindowClosing);
      }
    }
  }, [reservedOrder])

  return (
    <Container>
    {!reservedOrder ?
        <>
          {isFilterOpen && (
            <OrdersFilter
              isWide
              setIsFilterOpen={setIsFilterOpen}
              handleFilterVehiclesChange={setFilterVehicles}
              handleFilterReset={resetFilter}
              handlePeriodSet={setFilterPeriod}
              handleTimeAtSet={setFilterTimeAt}
              handleTimeToSet={setFilterTimeTo}
              stateSelector={orderFiltersSelector}
            />
          )}
          <div className="mr-[16px] grid grid-rows-[45px,auto] overflow-hidden">
            <Title className="mb-[12px]">Заказы</Title>

            <OrderList
              filter={{
                isActive: +isActiveOrders,
                statusId: orderFilter.period,
                vehicleCategoryId: orderFilter.vehicles,
                dateFrom: orderFilter.timeAt,
                dateTo: orderFilter.timeTo,
                executorCompanyId: companyId,
              }}
              withAddresses
              activeOrderId={activeOrderId}
              onItemClick={handleSetActiveOrder}
            />
          </div>
          <div className="grid grid-rows-[30px,auto] gap-[10px] overflow-hidden max-w-[800px]">
            <div className="flex justify-between">
              <Tabs
                className="mr-[10px] h-full w-[340px]"
                tabs={[
                  { value: true, label: "Активные" },
                  { value: false, label: "Завершённые" },
                ]}
                value={isActiveOrders}
                onTabClick={(value) => handleOrderStatusChange(!!value)}
              />
              <FilterButton onClick={() => setIsFilterOpen((prev) => !prev)} />
            </div>
            <QueryLayout
              isLoading={isOrderLoading}
              isFetching={isOrderFetching}
              error={orderError}
            >
              {order && activeOrderId ? (
                <OrderInfo order={order} />
              ) : (
                <InformationBlock />
              )}
            </QueryLayout>
          </div>
        </>
        :
        <>

          <div className="overflow-y-auto">
            <div className="flex mb-[10px]">
              <div className="mr-[15px]">
                <img
                    className="h-[30px] object-cover"
                    src={reservedOrder.vehicle_category.icon.path}
                    alt={reservedOrder.vehicle_category.name}
                />
              </div>
              <div className="grid items-center gap-[1px] text-left">
                <div className="text-base font-medium leading-4">
                  Заказ №{reservedOrder.id}
                </div>
                <div className="text-sm font-medium leading-4 text-gray">
                  {reservedOrder.vehicle_type?.name || "Ещё не назначено"}
                </div>
                <div className={`text-xs text-center max-w-max leading-4 font-semibold text-white py-[1.5px] px-[4px] rounded ${statusBg[reservedOrder.status.id as keyof typeof statusBg] ? statusBg[reservedOrder.status.id as keyof typeof statusBg]: `bg-primary`}`}>
                  {(reservedOrder.status.id === 8 && !reservedOrder.documents?.length) ? "Путевой лист" :reservedOrder.status.name}
                </div>
              </div>
              <div className="flex flex-1 flex-col items-end">
                  <>
                    <div>
                      <time className="mb-[5px] text-xs text-lightGray">
                        {currentOrderDate}
                      </time>
                    </div>
                    <div className="flex items-center font-semibold leading-4 md:text-sm lg:text-lg">
                      <SvgCreditCard className="mr-[7px] h-[20px] w-[20px]" />
                      {`${Math.round(reservedOrder.price)} руб.`}
                    </div>
                  </>
              </div>
            </div>
            <OrderItemDetails orderId={reservedOrder.id} reserve />
          </div>
          {assignedVehicleTypeId && currentCompanyId && (
          <div className="pointer-events-auto h-full w-[515px] overflow-y-hidden">
            <div className="h-full overflow-y-scroll rounded bg-white px-[15px]">
              <AssignmentVehicle
                  assignmentStateSelector={requestVehicleAssignmentSelector}
                  companyId={currentCompanyId}
                  handleAssignDriver={setAssigningDriverId}
                  handleAssignVehicles={setAssigningVehicleId}
                  handleAssignReset={resetAssignment}
                  reserve
              />
            </div>
          </div>
          )}
        </>
    }
    </Container>
  );
});
