import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgListDashes } from "../../../assets/icons/SvgListDashes";
import { CustomLineChart } from "../../../common/components/Chart/LineChart";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { Tabs } from "../../../common/components/Tabs/Tabs";
import {
  orderFiltersSelector,
  resetFilter,
  setDateFrom,
  setDateTo,
  setFilterPeriod,
  setFilterVehicleCategory,
  setFilterVehicleType,
} from "./ordersSlice";
import { CompletedOrderTable } from "../tables/orders/CompletedOrderTable";
import { Route, Routes, useLocation } from "react-router-dom";
import { CanceledOrderTable } from "../tables/orders/CanceledOrderTable";
import { OrderVisorTable } from "../tables/orders/OrderViewTable";
import { SvgLoupe } from "../../../assets/icons/SvgLoupe";
import { IconInput } from "../../../common/components/Input/IconInput";
import { AdminOrdersFilter } from "common/components/Filter/AdminOrdersFilter";
import { PERIOD } from "common/utils/consts";

const tabOptions = [
  { label: "Сегодня", value: PERIOD.TODAY },
  { label: "Неделя", value: PERIOD.WEEK },
  { label: "Месяц", value: PERIOD.MONTH },
  { label: "Квартал", value: PERIOD.QUARTER },
  { label: "Год", value: PERIOD.YEAR },
];

export const OrdersPage: FC = () => {
  const location = useLocation();

  const period = useAppSelector(
    (state) => state.administrator.orders.filters.period
  );

  const dateFrom = useAppSelector(
    (state) => state.administrator.orders.filters.dateFrom
  );
  const dateTo = useAppSelector(
    (state) => state.administrator.orders.filters.dateTo
  );

  const chartData = useAppSelector(
    (state) => state.administrator.orders.chartData
  );

  const dispatch = useAppDispatch();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const handleSetActiveTab = (period: number) => {
    dispatch(setFilterPeriod(period));
  };

  const handleFilterVehiclesChange = (id: number) => {
    dispatch(setFilterVehicleCategory(id));
  };

  const handleFilterVehicleTypeChange = (id: number) => {
    dispatch(setFilterVehicleType(id));
  };

  const handleDisputesDateFromChange = (date: Nullable<Date>) => {
    dispatch(setDateFrom(date?.toISOString() || undefined));
  };

  const handleDisputesDateToChange = (date: Nullable<Date>) => {
    dispatch(setDateTo(date?.toISOString() || undefined));
  };

  const handleResetFilter = () => {
    dispatch(resetFilter());
  };

  const isOnOrdersViewPage = location.pathname === "/orders/visor";

  const showChart = Math.abs(period || 0) !== PERIOD.TODAY;

  return (
    <>
      <div className="px-[5px]">
        {isFilterOpen && (
          <AdminOrdersFilter
            setIsFilterOpen={setIsFilterOpen}
            handleFilterVehicleCategoryChange={handleFilterVehiclesChange}
            handleFilterVehicleTypeChange={handleFilterVehicleTypeChange}
            handleFilterReset={handleResetFilter}
            stateSelector={orderFiltersSelector}
          />
        )}
        {isOnOrdersViewPage ? (
          <div className="grid w-full grid-cols-[1fr,245px,100px] gap-[10px]">
            <IconInput
              className="h-[35px] w-full"
              placeholder="Поиск по заказам"
              icon={<SvgLoupe className="h-[15px] w-[15px] text-gray" />}
            />
            <div>
              <DatePicker
                className="h-[35px] w-[240px]"
                onStartDateChange={handleDisputesDateFromChange}
                onEndDateChange={handleDisputesDateToChange}
                placeholderText="Введите промежуток дат"
                isClearable={true}
              />
            </div>
            <button
              className="flex h-[35px] items-center justify-center rounded border border-transparent bg-lightWhiteGray px-[8px] text-sm font-medium
        outline-none transition hover:border-primary hover:text-primary focus:border-green focus:text-green focus:color-green active:text-black"
              // onClick={() => setIsFilterOpen((prev) => !prev)}
            >
              <SvgListDashes className="mr-[5px] h-[16px] w-[16px]" />
              Фильтр
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-between relative z-10">
            <div className="flex h-[35px]">
              <div className="mr-[15px]">
                <Tabs
                  className="h-full"
                  tabs={tabOptions}
                  value={period}
                  onTabClick={handleSetActiveTab}
                />
              </div>
              <div>
                <DatePicker
                  className="h-[35px] w-[240px]"
                  onStartDateChange={handleDisputesDateFromChange}
                  onEndDateChange={handleDisputesDateToChange}
                  placeholderText="Введите промежуток дат"
                  isClearable={true}
                />
              </div>
            </div>
            <button
              className="flex h-[35px] items-center justify-center rounded border border-transparent bg-lightWhiteGray px-[8px] text-sm font-medium
        outline-none transition hover:border-primary hover:text-primary focus:border-green focus:color-green focus:text-green active:text-black"
              // onClick={() => setIsFilterOpen((prev) => !prev)}
            >
              <SvgListDashes className="mr-[5px] h-[16px] w-[16px]" />
              Фильтр
            </button>
          </div>
        )}
      </div>
      <div>
        {!isOnOrdersViewPage && showChart && chartData && (
          <>
            {chartData.length > 0 && (
              <div className="mb-[20px]">
                <CustomLineChart chartData={chartData} />
              </div>
            )}
          </>
        )}
        <Routes>
          <Route
            path="/completed"
            element={
              <CompletedOrderTable dateFrom={dateFrom} dateTo={dateTo} />
            }
          />
          <Route
            path="/canceled"
            element={<CanceledOrderTable dateFrom={dateFrom} dateTo={dateTo} />}
          />
          <Route
            path="/visor"
            element={<OrderVisorTable dateFrom={dateFrom} dateTo={dateTo} />}
          />
        </Routes>
      </div>
    </>
  );
};
