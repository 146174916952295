import { FC, useState, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { SvgCaretUp } from "../../../assets/icons/SvgCaretUp";
import { timeFormatter } from "common/utils/helpers";
import { useAppSelector } from "app/hooks";
import { ProfileButton } from "common/components/Button/ProfileButton";
import { UserProfileDropdown } from "common/components/Dropdown/UserProfileDropdown";

export const CustomerCompanyManagerHeader: FC = () => {
  const currentAccountId = useAppSelector(
    (state) => state.user.current?.account?.id
  );
  const firstName = useAppSelector((state) => state.user.current?.first_name);
  const lastName = useAppSelector((state) => state.user.current?.last_name);
  const currentCompanyBalance = useAppSelector(
    (state) => state.user.current?.account?.company?.balance
  );
  const companyLogotype = useAppSelector(
    (state) => state.user.current?.account.company?.logotype?.path
  );
  const userAvatar = useAppSelector((state) => state.user.current?.avatar);
  const userId = useAppSelector((state) => state.user.current?.id);
  const companyName = useAppSelector(
    (state) => state.user.current?.account?.company?.name
  );
  const currentRaiting = useAppSelector((state) => state.user.current?.rate);

  const fullName = lastName + " " + firstName;

  const [faqDropdownOpen, setFaqDropdownOpen] = useState(false);
  const [userProfileDropdownOpen, setUserProfileDropdownOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (userProfileDropdownOpen) {
      window.addEventListener("click", handleClose, { once: true });
    }
  }, [faqDropdownOpen, userProfileDropdownOpen]);

  const handleClose = () => {
    setFaqDropdownOpen(false);
    setUserProfileDropdownOpen(false);
  };

  const handleFaqDropdownOpen = () => {
    setFaqDropdownOpen(true);
  };

  const currentTimeString = useMemo(
    () => timeFormatter({ time: currentTime, mode: "dd-Mth hh:mm:ss" }),
    [currentTime]
  );

  return (
    <header className="flex h-[90px] items-center justify-between px-[15px] shadow-lg">
      <div className="max-w-[300px]">
        <h1 className="overflow-hidden text-md font-semibold">
          {companyName || "Выберите компанию"}
        </h1>
        <time className="block w-[120px] text-base text-graySecond">
          {currentTimeString}
        </time>
      </div>
      <nav className="grid w-full grid-cols-[1fr,auto]">
        <div className="ml-auto mr-auto h-full w-fit">
          <div className="grid h-full grid-cols-3 items-center gap-[25px]">
            <div className="relative" onMouseLeave={handleClose}>
              <NavLink
                to="/about"
                className="flex h-full items-center text-center font-bold text-black outline-none transition hover:text-primary focus:text-primary active:text-black"
                onMouseEnter={handleFaqDropdownOpen}
                onClick={handleFaqDropdownOpen}
                onFocus={handleFaqDropdownOpen}
              >
                <span className="mr-[7px]">О сервисе</span>
                <SvgCaretUp className="h-[6px] w-[10px] rotate-180" />
              </NavLink>
              <ul
                className={`absolute right-0 z-20 min-w-[170px] rounded bg-white p-[10px] text-sm shadow-lg ${
                  faqDropdownOpen ? "block" : "hidden"
                }`}
              >
                <li>
                  <NavLink
                    to="/privacy-policy"
                    className="block py-[5px] text-left outline-none transition hover:text-primary focus:text-primary active:text-black"
                  >
                    Политика конфиденциальности
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/faq"
                    className="block py-[5px] text-left outline-none transition hover:text-primary focus:text-primary active:text-black"
                  >
                    Часто задаваемые вопросы
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/use-rules"
                    className="block py-[5px] text-left outline-none transition hover:text-primary focus:text-primary active:text-black"
                  >
                    Правила пользования
                  </NavLink>
                </li>
              </ul>
            </div>
            <NavLink
              to="/"
              className="flex h-full items-center justify-center text-center font-bold text-black outline-none transition hover:text-primary focus:text-primary active:text-black"
            >
              Клиенту
            </NavLink>
            <NavLink
              to="/"
              className="flex h-full items-center justify-center text-center font-bold text-black outline-none transition hover:text-primary focus:text-primary active:text-black"
            >
              Водителю
            </NavLink>
          </div>
        </div>

        <div className="relative">
          <ProfileButton
            onClick={(e) => {
              e.stopPropagation();
              setUserProfileDropdownOpen((prev) => !prev);
            }}
            name={companyName || fullName}
            rating={currentRaiting}
            balance={currentCompanyBalance}
            avatarurl={companyLogotype}
          />
          {userProfileDropdownOpen && userId && (
            <UserProfileDropdown
              userAvatar={userAvatar?.path}
              userId={userId}
              currentAccountId={currentAccountId}
              fullName={fullName}
              handleClose={handleClose}
            />
          )}
        </div>
      </nav>
    </header>
  );
};
