import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgCaretUp } from "../../../assets/icons/SvgCaretUp";
import { Container } from "../../../common/components/Container/Container";
import { List } from "../../../common/components/List/List";
import { ProfileCard } from "common/components/Profile/ProfileCard";
import { Title } from "../../../common/components/Title/Title";
import { getErrorMessage } from "../../../common/utils/helpers";
import { logout, removeCurrentCompany } from "../userSlice";
import { ProfileInfo } from "./ProfileInfo";
import { useDeleteCompanyMutation } from "../../../services/companiesApi";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import {
  COMPANY_CATEGORY,
  MODAL_TYPES,
  USER_ROLE,
} from "../../../common/utils/consts";
import { Invites } from "./Invites";
import { useRejectInviteMutation } from "services/userApi";
import { CompanyProfileCard } from "common/components/Profile/CompanyProfileCard";

const ProfileNavButton: FC<{ text: string }> = ({ text }) => {
  return (
    <div className="fomt-medium flex w-full items-center rounded bg-lightWhiteGray px-[20px] py-[15px] text-left text-base text-gray">
      <span className="w-full">{text}</span>
      <SvgCaretUp className="h-[10px] w-[15px] rotate-90 text-black" />
    </div>
  );
};

export const ProfilePage: FC = () => {
  const [
    deleteCompany,
    {
      isLoading: isCompanyDeletingLoading,
      error: deleteCompanyError,
      isSuccess: isDeleteCompanySuccess,
    },
  ] = useDeleteCompanyMutation();
  const [
    deleteAccount,
    { isLoading: isAccountDeletingLoading, error: deleteAccountError },
  ] = useRejectInviteMutation();

  const user = useAppSelector((state) => state.user.current);
  const currentCompany = useAppSelector(
    (state) => state.user.current?.account?.company
  );

  const dispatch = useAppDispatch();

  const { showModal } = useGlobalModalContext();

  const showCompanyDeletingConfirmationModal = () => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      subTitle: "Вы уверены что хотите удалить текущую компанию?",
      confirmText: "Компания успешно удалена",
      onConfirm: () => handleCompanyDelete(),
    });
  };

  const showAccountDeletingConfirmationModal = () => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      subTitle: "Вы уверены что хотите удалить текущий аккаунт?",
      confirmText: "Компания успешно удалена",
      onConfirm: () => user && deleteAccount(user.account.id),
    });
  };

  const openErrorModal = (message?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Не удалось удалить компанию",
      subTitle:
        message ||
        "При удалении компании произошла ошибка, повторите попытку позже",
    });
  };

  const handleCompanyDelete = async () => {
    if (currentCompany?.id) {
      await deleteCompany(currentCompany.id);
    }
  };

  useEffect(() => {
    if (deleteCompanyError || deleteAccountError) {
      openErrorModal(
        getErrorMessage(deleteAccountError || deleteCompanyError)?.data.message
      );
    }
  }, [deleteCompanyError, deleteAccountError]);

  useEffect(() => {
    if (isDeleteCompanySuccess) {
      dispatch(removeCurrentCompany());
    }
  }, [isDeleteCompanySuccess]);

  const isIndividual = user?.account.role.id === USER_ROLE.INDIVIDUAL;
  const isCustomerCompany =
    (user?.account.role.id === USER_ROLE.MANAGER ||
      user?.account.role.id === USER_ROLE.ADMINISTRATOR) &&
    currentCompany?.category.id === COMPANY_CATEGORY.CUSTOMER;
  const isImplementerCompany =
    (user?.account.role.id === USER_ROLE.MANAGER ||
      user?.account.role.id === USER_ROLE.ADMINISTRATOR) &&
    currentCompany?.category.id === COMPANY_CATEGORY.IMPLEMENTER;
  const isAdmin = user?.account.role.id === USER_ROLE.ADMINISTRATOR;

  return (
    <Container>
      <div className="grid h-full items-baseline gap-[12px] overflow-hidden">
        <div className="h-full overflow-y-scroll">
          <Title className="mb-[15px]">Профиль</Title>
          {currentCompany && <CompanyProfileCard company={currentCompany} />}
          {!currentCompany && user && (
            <ProfileCard
              className="flex min-h-[57px] rounded border border-whiteGray p-[10px]"
              avatarurl={user.avatar?.path}
              primary={true}
              name={`${user.last_name} ${user.first_name}`}
              subname={user.account.role.name}
              rating={user.rate}
            />
          )}
          <div className="mb-[15px]">
            <Invites />
          </div>
          <List>
            {isImplementerCompany &&
              implementerCompanyLinks.map((link) => (
                <li key={link.label}>
                  <Link
                    className="block rounded border border-transparent outline-none transition hover:border-primary focus:border-primary active:border-transparent"
                    to={link.to}
                  >
                    <ProfileNavButton text={link.label} />
                  </Link>
                </li>
              ))}
            {isIndividual &&
              individualLinks.map((link) => (
                <li key={link.label}>
                  <Link
                    className="block rounded border border-transparent outline-none transition hover:border-primary focus:border-primary active:border-transparent"
                    to={link.to}
                  >
                    <ProfileNavButton text={link.label} />
                  </Link>
                </li>
              ))}
            {isCustomerCompany &&
              customerCompanyLinks.map((link) => (
                <li key={link.label}>
                  <Link
                    className="block rounded border border-transparent outline-none transition hover:border-primary focus:border-primary active:border-transparent"
                    to={link.to}
                  >
                    <ProfileNavButton text={link.label} />
                  </Link>
                </li>
              ))}

            <li>
              <button
                className="block w-full rounded border border-transparent outline-none transition hover:border-primary focus:border-primary active:border-transparent"
                onClick={() => dispatch(logout())}
              >
                <ProfileNavButton text="Выйти" />
              </button>
            </li>
            {currentCompany && isAdmin && (
              <li>
                <button
                  className="block w-full rounded border border-transparent outline-none transition hover:border-primary focus:border-primary active:border-transparent"
                  onClick={showCompanyDeletingConfirmationModal}
                  disabled={isCompanyDeletingLoading}
                >
                  <ProfileNavButton text="Удалить компанию" />
                </button>
              </li>
            )}

            {currentCompany && !isAdmin && (
              <li>
                <button
                  className="block w-full rounded border border-transparent outline-none transition hover:border-primary focus:border-primary active:border-transparent"
                  onClick={showAccountDeletingConfirmationModal}
                  disabled={isAccountDeletingLoading}
                >
                  <ProfileNavButton text="Удалить аккаунт" />
                </button>
              </li>
            )}
          </List>
        </div>
      </div>

      <div className="mt-[35px] overflow-hidden max-w-[800px]">
        <ProfileInfo />
      </div>
    </Container>
  );
};

const customerCompanyLinks = [
  { label: "Мои аккаунты", to: "/companies" },
  { label: "История платежей", to: "/transactions" },
  { label: "История заказов", to: "/" },
  { label: "Выставленные счета", to: "/invoices" },
  { label: "Служба поддержки", to: "/support" },
  { label: "О сервисе", to: "/about" },
];
const individualLinks = [
  { label: "Мои аккаунты", to: "/companies" },
  { label: "История платежей", to: "/transactions" },
  { label: "Мои карты", to: "/bank-cards" },
  { label: "Служба поддержки", to: "/support" },
  { label: "О сервисе", to: "/about" },
];
const implementerCompanyLinks = [
  { label: "Мои аккаунты", to: "/companies" },
  { label: "Мои сотрудники", to: "/employees" },
  { label: "Моя техника", to: "/vehicles" },
  { label: "Транзакции", to: "/transactions" },
];
