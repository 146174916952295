import { FC } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Switch } from "../Checkbox/Switch";
import { List } from "../List/List";
import { CustomSelect } from "../Select/CustomSelect";
import { Title } from "../Title/Title";
import { VehicleFormValues } from "../../models/forms/VehicleFormValues";
import { useGetVehicleOptionsQuery } from "../../../services/vehicle/optionsApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { Input } from "../Input/Input";
import { useGetVehicleTypesQuery } from "services/vehicle/vehicleTypesApi";

export const VehicleSpecificationCharacteristics: FC<{
  moderation?: boolean;
}> = ({ moderation }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<VehicleFormValues>();

  const currentOptions = useWatch({
    control,
    name: "specifications.optionalEquipment",
  });

  const currentCategory = useWatch({
    control,
    name: "category",
  });

  const {
    data: vehicleTypes,
    isLoading: isVehicleTypesLoading,
    isFetching: isVehicleTypesFetching,
    error: vehicleTypesError,
  } = useGetVehicleTypesQuery([currentCategory || -1], {
    skip: !currentCategory,
  });

  const {
    data: options,
    isLoading,
    isFetching,
    error,
  } = useGetVehicleOptionsQuery(currentCategory || -1, {
    skip: !currentCategory,
  });

  const vehicleTypesOptions = vehicleTypes?.map((type) => ({
    value: type.id,
    label: type.name,
  }));

  return (
    <div>
      <div className="mb-[30px] grid grid-cols-2 gap-[15px]">
        <div className="grid gap-[25px]">
          <Input
            {...register("specifications.boomCapacity")}
            className="w-full"
            type="number"
            step={0.01}
            label="Грузоподъёмность стрелы *"
            placeholder="Грузоподъёмность стрелы"
            invalid={!!errors.specifications?.boomCapacity}
            invalidMessage={errors.specifications?.boomCapacity?.message}
          />
          <Input
            {...register("specifications.boomLength")}
            className="w-full"
            type="number"
            step={0.01}
            label="Длина стрелы *"
            placeholder="Длина"
            invalid={!!errors.specifications?.boomLength}
            invalidMessage={errors.specifications?.boomLength?.message}
          />
        </div>
        <div className="grid grid-rows-2 gap-[25px]">
          <Input
            {...register("specifications.sideLoadCapacity")}
            className="w-full"
            type="number"
            step={0.01}
            label="Грузоподъёмность борта *"
            placeholder="Грузоподъёмность борта"
            invalid={!!errors.specifications?.sideLoadCapacity}
            invalidMessage={errors.specifications?.sideLoadCapacity?.message}
          />
        </div>
      </div>
      <div className="mb-[30px]">
        <Title className="mb-[25px]">Габариты</Title>
        <div className="grid grid-cols-3 gap-[15px]">
          <Input
            {...register("specifications.dimensions.length")}
            className="w-full"
            type="number"
            step={0.1}
            label="Длина *"
            placeholder="Длина"
            invalid={!!errors.specifications?.dimensions?.length}
            invalidMessage={errors.specifications?.dimensions?.length?.message}
          />
          <Input
            {...register("specifications.dimensions.width")}
            className="w-full"
            type="number"
            step={0.1}
            label="Ширина *"
            placeholder="Ширина"
            invalid={!!errors.specifications?.dimensions?.width}
            invalidMessage={errors.specifications?.dimensions?.width?.message}
          />
          <Input
            {...register("specifications.dimensions.height")}
            className="w-full"
            type="number"
            step={0.1}
            label="Высота *"
            placeholder="Высота"
            invalid={!!errors.specifications?.dimensions?.height}
            invalidMessage={errors.specifications?.dimensions?.height?.message}
          />
        </div>
      </div>
      <div className="mb-[30px]">
        <Title className="mb-[25px]">Дополнительное оборудование</Title>
        <QueryLayout
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
        >
          <List className="mb-[30px] gap-[15px]">
            {options &&
              options.map((option, i) => {
                return (
                  <li key={option.id}>
                    <label className="flex items-center justify-between text-sm font-semibold">
                      {option.name}
                      <Switch
                        value={+option.id}
                        {...register(`specifications.optionalEquipment.${i}`, {
                          valueAsNumber: true,
                        })}
                        checked={
                          !!currentOptions?.find(
                            (currentOption) => +currentOption === +option.id
                          )
                        }
                        className={
                          errors?.specifications?.optionalEquipment?.[i]
                            ? "error"
                            : ""
                        }
                      />
                    </label>
                  </li>
                );
              })}
          </List>
        </QueryLayout>
      </div>
      {moderation && (
        <div className="mb-[30px]">
          <Title className="mb-[25px]">Тариф транспортного средства</Title>
          <QueryLayout
            isLoading={isVehicleTypesLoading}
            isFetching={isVehicleTypesFetching}
            error={vehicleTypesError}
          >
            <Controller
              control={control}
              name="specifications.tariff"
              render={(props) => {
                return (
                  <CustomSelect
                    className="w-full"
                    label="Тариф *"
                    menuPlacement="top"
                    reference={props.field.ref}
                    defaultValue={null}
                    options={vehicleTypesOptions}
                    placeholder="Выберите тариф"
                    invalid={!!errors.specifications?.tariff}
                    value={vehicleTypesOptions?.find(
                      (c) => c.value === props.field.value
                    )}
                    onChange={(val) => props.field.onChange(val?.value)}
                  />
                );
              }}
            />
          </QueryLayout>
        </div>
      )}
    </div>
  );
};
